import { Controller } from '@hotwired/stimulus';

import { render, cancel, register } from 'timeago.js';

import enUS from 'timeago.js/esm/lang/en_US';
import enShort from 'timeago.js/esm/lang/en_short';

register('en_US', enUS);
register('en_short', enShort);
register('human', (diff, idx, totalSec) => {
  const unit = Math.floor(idx / 2);
  if (idx === 0) {
    return ['just now', 'right now'];
  }

  // seconds
  if (idx === 1) {
    return ['seconds ago', 'in a few seconds'];
  }

  // minutes
  if (unit === 1) {
    if (diff <= 1) {
      return ['about 1 minute ago', 'in about a minute'];
    }

    // 2 through 45 minutes
    if (diff < 45) {
      return [`${diff} minutes ago`, `in ${diff} minutes`];
    }

    // 45 minutes to 90 minutes
    return [`about 1 hour ago`, 'in about an hour'];
  }

  // hours
  if (unit === 2) {
    // 45 minutes to 90 minutes
    if (diff <= 2 && (!totalSec || totalSec < 90 * 60)) {
      return [`about 1 hour ago`, 'in about an hour'];
    }

    // up to 24 hours
    return [`about ${diff} hours ago`, `in about ${diff} hours`];
  }

  const totalMinutes = totalSec ? totalSec / 60 : diff * 24 * 60;

  // 24 hours up to 42 hours
  if (totalMinutes < 2520) {
    return ['1 day ago', 'in 1 day'];
  }

  // 42 hours up to 30 days
  if (totalMinutes < 43200) {
    const count = Math.round(totalMinutes / 1440);
    return [
      `${count} day${count > 1 ? 's' : ''} ago`,
      `in ${count} day${count > 1 ? 's' : ''}`,
    ];
  }

  // 30 days up to 60 days
  if (totalMinutes < 86400) {
    const count = Math.round(totalMinutes / 43200);
    return [
      `about ${count} month${count > 1 ? 's' : ''} ago`,
      `in about ${count} month${count > 1 ? 's' : ''}`,
    ];
  }

  // 60 days up to 365 days
  if (totalMinutes < 525600) {
    const count = Math.round(totalMinutes / 43200);
    return [
      `${count} month${count > 1 ? 's' : ''} ago`,
      `in ${count} month${count > 1 ? 's' : ''}`,
    ];
  }

  /**
   *
   * from_year = from_time.year
   * from_year += 1 if from_time.month >= 3
   * to_year = to_time.year
   * to_year -= 1 if to_time.month < 3
   * leap_years = (from_year > to_year) ? 0 : (from_year..to_year).count { |x| Date.leap?(x) }
   * minute_offset_for_leap_year = leap_years * 1440
   *
   * // Discount the leap year days when calculating year distance.
   * // e.g. if there are 20 leap year days between 2 dates having the same day
   * // and month then the based on 365 days calculation
   * // the distance in years will come out to over 80 years when in written
   * // English it would read better as about 80 years.
   */
  // minutes_with_offset = distance_in_minutes - minute_offset_for_leap_year
  // remainder                   = (minutes_with_offset % MINUTES_IN_YEAR)
  // distance_in_years           = (minutes_with_offset.div MINUTES_IN_YEAR)

  const totalYears: number = totalSec ? totalSec / 60 / 60 / 24 / 365 : diff;
  const fullYears = totalYears | 1;
  const remainder = totalYears - fullYears;

  if (remainder < 0.25) {
    return [
      `about ${fullYears} year${fullYears > 1 ? 's' : ''} ago`,
      `in about ${fullYears} year${fullYears > 1 ? 's' : ''}`,
    ];
  }

  if (remainder < 0.75) {
    return [
      `over ${fullYears} year${fullYears > 1 ? 's' : ''} ago`,
      `in over ${fullYears} year${fullYears > 1 ? 's' : ''}`,
    ];
  }

  const nextYears = fullYears + 1;
  return [
    `almost ${nextYears} year${nextYears > 1 ? 's' : ''} ago`,
    `in almost ${nextYears} year${nextYears > 1 ? 's' : ''}`,
  ];
});

/**

 */
export default class TimeController extends Controller {
  public static targets = [];
  public static values = {
    format: String,
    zero: String,
  };

  private declare formatValue: string;
  private declare zeroValue: string;

  private declare hasZeroValue: boolean;

  private get datetime(): Date {
    return new Date(this.element.getAttribute('datetime')!);
  }

  public connect(): void {
    if (this.datetime.getTime() === 0) {
      if (this.hasZeroValue) {
        this.element.textContent = this.zeroValue;
        return;
      }
      return;
    }

    switch (this.formatValue) {
      case 'relative': {
        // in / ago
        render(this.element as HTMLElement, 'human');
        return;
      }

      case 'relative-short': {
        // in / ago
        render(this.element as HTMLElement, 'en_short');
        return;
      }

      case 'datetime': {
        this.element.textContent = this.datetime.toLocaleString(undefined, {
          dateStyle: 'medium',
          timeStyle: 'medium',
        });
        return;
      }

      case 'date': {
        this.element.textContent = this.datetime.toLocaleDateString(undefined, {
          dateStyle: 'medium',
        });
        return;
      }
    }
  }

  public disconnect(): void {
    if (this.datetime.getTime() === 0) {
      return;
    }

    switch (this.formatValue) {
      case 'relative': {
        // in / ago
        cancel(this.element as HTMLElement);
        return;
      }

      case 'relative-short': {
        // in / ago
        cancel(this.element as HTMLElement);
        return;
      }
    }
  }
}
