import { Context, Controller } from '@hotwired/stimulus';

export default class LinkedFormFilterController extends Controller {
  public static targets = ['input'];

  public declare inputTargets: NodeListOf<HTMLInputElement>;

  public constructor(context: Context) {
    super(context);

    this.onChange = this.onChange.bind(this);
  }

  public connect(): void {
    super.connect();

    this.inputTargets.forEach((input) => {
      input.addEventListener('change', this.onChange);
    });
  }

  public disconnect(): void {
    super.disconnect();
    this.inputTargets.forEach((input) =>
      input.removeEventListener('change', this.onChange),
    );
  }

  public onChange(e: Event) {
    if (!(e.target instanceof HTMLInputElement)) {
      return;
    }
    const inputTarget = e.target;
    const isChecked = inputTarget.checked;
    const applicableValue = inputTarget.value;

    const linkGroup = inputTarget.getAttribute('data-link-group');
    const valuesAttribute = `data-link-${linkGroup}-values`;

    // Check all that match
    if (isChecked) {
      // https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors#attrvalue_2
      const filteredInputs = this.element.querySelectorAll<HTMLInputElement>(
        `input[${valuesAttribute}~="${applicableValue}"]`,
      );

      filteredInputs.forEach((element) => (element.checked = true));

      // Uncheck all that no longer match anything
    } else {
      const checkedInGroup: string[] = [];

      this.element
        .querySelectorAll<HTMLInputElement>(`[data-link-group="${linkGroup}"]`)
        .forEach((option) => {
          if (option.checked) {
            checkedInGroup.push(option.value);
          }
        });

      const queryString = checkedInGroup
        .map((value) => `:not(input[${valuesAttribute}~="${value}"])`)
        .join('');

      const filteredInputs = this.element.querySelectorAll<HTMLInputElement>(
        `input[${valuesAttribute}~="${applicableValue}"]${queryString}`,
      );

      filteredInputs.forEach((element) => {
        element.checked = false;
      });
    }
  }
}
