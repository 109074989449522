import { Controller } from '@hotwired/stimulus';

export default class TreeFilterController extends Controller {
  public static targets = ['input', 'tree'];

  public declare inputTarget: HTMLInputElement;
  public declare treeTarget:
    | HTMLUListElement
    | HTMLOListElement
    | HTMLDListElement;
  private timeout: any | undefined;

  private get debounceValue(): number {
    return 200;
  }

  public connect(): void {}

  public disconnect(): void {}

  public onChange(e: Event) {
    clearTimeout(this.timeout);

    const value = this.inputTarget.value
      .split(' ')
      .filter(Boolean)
      .map(
        (q) =>
          `[data-tree-filter-value*="${q
            .replace("/'/g", '&#39;')
            .replace(/"/g, '&#34;')
            .replace(/\[/g, '&#91;')
            .replace(/\]/g, '&#93;')}" i]`,
      )
      .join('');

    this.timeout = setTimeout(() => {
      this.treeTarget
        .querySelectorAll('[data-tree-filter-value]')
        .forEach((element) => element.setAttribute('hidden', ''));

      this.treeTarget
        .querySelectorAll('[data-tree-filter-value]' + value)
        .forEach((element) => {
          element.removeAttribute('hidden');

          let parent: undefined | null | Element =
            element.parentElement?.closest('[data-tree-filter-value]');
          while (parent) {
            parent.removeAttribute('hidden');

            parent = parent.parentElement?.closest('[data-tree-filter-value]');
          }
        });
    }, this.debounceValue);
  }

  public onSubmit(e: Event) {
    e.preventDefault();
    return this.onChange(e);
  }
}
