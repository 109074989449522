/* eslint-disable no-undef */
import { DirectUpload } from '@rails/activestorage';
import Uppy, { BasePlugin, DefaultPluginOptions } from '@uppy/core';
import EventManager from '@uppy/core/lib/EventManager';
import ProgressTimeout from '@uppy/utils/lib/ProgressTimeout';
import {
  filterFilesToEmitUploadStarted,
  filterNonFailedFiles,
} from '@uppy/utils/lib/fileFilters';
import type { Body, Meta, UppyFile } from '@uppy/utils/src/UppyFile';
import { nanoid } from 'nanoid/non-secure';

type RailsDirectUploadOptions = DefaultPluginOptions & {
  limit?: number;
  timeout?: number;
  directUploadUrl: string;
  blobUrlTemplate: string;
  representationUrlTemplate: string;
};

type M = Meta;
type B = Body;

export default class RailsDirectUpload extends BasePlugin<RailsDirectUploadOptions> {
  private opts: RailsDirectUploadOptions;
  private uploaderEvents: Record<string, EventManager<M, B> | null>;

  constructor(uppy: Uppy, opts?: RailsDirectUploadOptions) {
    super(uppy, opts);

    this.id = opts?.id || 'RailsDirectUpload';
    this.type = 'uploader';

    const defaultOptions = {
      limit: 0,
      timeout: 30 * 1000,
      directUploadUrl: null,
      blobUrlTemplate: null,
      representationUrlTemplate: null,
    };

    this.opts = Object.assign({}, defaultOptions, opts);
    this.uploaderEvents = {};

    this.handleUpload = this.handleUpload.bind(this);
  }

  install() {
    this.uppy.addUploader(this.handleUpload);
  }

  uninstall() {
    this.uppy.removeUploader(this.handleUpload);
  }

  handleUpload(fileIDs: string[]): Promise<void> {
    if (fileIDs.length === 0) {
      this.uppy.log('[RailsDirectUpload] No files to upload!');
      return Promise.resolve();
    }

    this.uppy.log('[RailsDirectUpload] Uploading...');
    const files = fileIDs.map(
      (fileID) => this.uppy.getFile(fileID) as UppyFile<M, B>,
    );

    const filesFiltered = filterNonFailedFiles(files);
    const filesToEmit = filterFilesToEmitUploadStarted(filesFiltered);
    this.uppy.emit('upload-start', filesToEmit);

    return this.uploadFiles(filesFiltered).then(() => {});
  }

  upload(file: UppyFile<M, B>, current: number, total: number) {
    // const opts = this.getOptions(file);
    const uploadStarted = Date.now();

    this.uppy.log(`[RailsDirectUpload] uploading ${current} of ${total}`);

    return new Promise((resolve, reject) => {
      const eventManager = new EventManager(this.uppy as any);
      this.uploaderEvents[file.id] = eventManager;

      const timeout = this.opts.timeout ?? 30 * 1000;
      const timer = new ProgressTimeout(timeout, () => {
        const error = new Error(
          `timed out after ${Math.ceil(timeout / 1000)}s`,
        );
        this.uppy.emit('upload-stalled', error, [file]);
      });

      const id = nanoid();

      const directHandlers = {
        directUploadWillStoreFileWithXHR: (request: XMLHttpRequest) => {
          request.upload.addEventListener('progress', (event) =>
            directHandlers.directUploadDidProgress(event),
          );
        },

        directUploadDidProgress: (
          ev: ProgressEvent<XMLHttpRequestEventTarget>,
        ) => {
          this.uppy.log(
            `[RailsDirectUpload] ${id} progress: ${ev.loaded} / ${ev.total}`,
          );

          // Begin checking for timeouts when progress starts, instead of loading,
          // to avoid timing out requests on browser concurrency queue
          timer.progress();

          if (ev.lengthComputable) {
            this.uppy.emit('upload-progress', this.uppy.getFile(file.id), {
              uploader: this,
              uploadStarted,
              bytesUploaded: ev.loaded,
              bytesTotal: ev.total,
            });
          }
        },
      };

      const { data, meta } = file;

      if (!data.name && meta.name) {
        (data as any).name = meta.name;
      }

      if (!data.name) {
        (data as any).name = `unnamed-${id}`;
      }

      const upload = new DirectUpload(
        data as File,
        this.opts.directUploadUrl,
        directHandlers,
      );

      upload.create((error, attributes) => {
        this.uppy.log(`[RailsDirectUpload] ${id} finished`);
        timer.done();

        if (this.uploaderEvents[file.id]) {
          this.uploaderEvents[file.id]!.remove();
          this.uploaderEvents[file.id] = null;
        }

        if (error) {
          const response = {
            status: 'error',
          };

          this.uppy.setFileState(file.id, { response });
          this.uppy.emit('upload-error', file, error);

          return reject(error);
        } else {
          console.warn({ attributes }, file);

          const response = {
            status: 201,
            uploadURL: this.createBlobUrl(
              attributes.signed_id,
              attributes.filename,
            ),
            bytesUploaded: file.size,

            sgid: attributes.signed_id,
            caption: attributes.filename,
          };

          this.uppy.setFileState(file.id, { response });
          this.uppy.emit(
            'upload-success',
            this.uppy.getFile(file.id),
            response,
          );

          return resolve(file);
        }
      });

      this.uppy.log(`[RailsDirectUpload] ${id} started`);

      eventManager.onFileRemove(file.id, () => {
        upload.abort && upload.abort();
        reject(new Error('File removed'));
      });

      eventManager.onCancelAll(file.id, ({ reason }) => {
        if (reason === 'user') {
          upload.abort && upload.abort();
        }

        reject(new Error('Upload cancelled'));
      });
    });
  }

  async uploadFiles(files: UppyFile<M, B>[]) {
    const actions = files.map((file, i) => {
      const current = i + 1;
      const total = files.length;

      if (file.error) {
        return () => Promise.reject(new Error(file.error ?? undefined));
      } else {
        this.uppy.emit('upload-started', file);
        return this.upload.bind(this, file, current, total);
      }
    });

    return Promise.allSettled(actions.map((action) => action()));
  }

  private createBlobUrl(signedId: string, filename: string) {
    return (this.opts.representationUrlTemplate || this.opts.blobUrlTemplate)
      .replace(':signed_id', signedId)
      .replace(/:filename|%3Afilename/, encodeURIComponent(filename));
  }
}
