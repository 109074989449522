/**
 * Toggles, sets, or removes any attribute on an element
 *
 * @param attribute the attribute to modify
 * @param element the element to target
 * @param force undefined to toggle, true to set, false to unset
 * @param values [whenTrue, whenFalse], use undefined to remove completely
 */
export function toggleAttribute(
  attribute: string,
  element: Element | null | undefined,
  force?: boolean,
  values: [string | undefined, string | undefined] | [string] | [] = [
    '',
    undefined,
  ]
): void {
  if (!element) {
    return;
  }

  if (force === undefined) {
    const wasHidden = element.hasAttribute(attribute);
    return toggleAttribute(attribute, element, !wasHidden, values);
  }

  const [whenOn, whenOff] = values;
  const next = force ? whenOn : whenOff;

  if (next === undefined) {
    element.removeAttribute(attribute);
  } else {
    element.setAttribute(attribute, next);
  }
}

/**
 * Toggles, sets, or removes the [hidden] attribute on an element
 *
 * @param element the element to mutate
 * @param force if given, adds the hidden attribute when true, removes otherwise
 */
export function toggleHidden(
  element: Element | null | undefined,
  force?: boolean
): void {
  toggleAttribute('hidden', element, force);
}

export function toggleHiddenUntilFound(
  element: Element | null | undefined,
  force?: boolean
): void {
  toggleAttribute('hidden', element, force, ['until-found', undefined]);
}

/**
 * Toggles or updates the [aria-expanded] attribute on an element
 *
 * @param element the element to mutate
 * @param force if given, forces the value to match
 */
export function toggleExpanded(
  element: Element | null | undefined,
  force?: boolean
): void {
  toggleAttribute('aria-expanded', element, force, ['true', 'false']);
}
