import { toggleExpanded, toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';
import { emitter } from 'src/emitter';

export default class MeasurementArchivalController extends Controller {
  public static targets = ['restore', 'archive'];

  private declare restoreTarget: HTMLTemplateElement;
  private declare archiveTarget: HTMLTemplateElement;

  public constructor(context: Context) {
    super(context);

    this.onMeasurementArchival = this.onMeasurementArchival.bind(this);
  }

  public onMeasurementArchival(params: unknown) {
    if (!params || typeof params !== 'object') {
      return;
    }

    if (!('tag' in params) || typeof params.tag !== 'string') {
      return;
    }

    if (!('archived' in params)) {
      return;
    }

    const measurementElement = this.element.querySelector<HTMLFormElement>(
      `form[data-checklists--measurement-archival-tag-value="${params.tag}"]`,
    );
    if (!measurementElement) {
      return;
    }

    const nextState = Boolean(params.archived);
    const url = measurementElement.getAttribute('action') || '';
    const now = new Date().toISOString();

    const template = nextState ? this.restoreTarget : this.archiveTarget;
    const copy = (template.content.cloneNode(true) as DocumentFragment)
      .firstElementChild as HTMLFormElement;
    copy.setAttribute('action', url);
    copy.setAttribute(
      'data-checklists--measurement-archival-target',
      'measurement',
    );
    copy.setAttribute(
      'data-checklists--measurement-archival-tag-value',
      params.tag,
    );

    const input = copy.querySelector<HTMLInputElement>(
      'input[name="measurement[archived_at]"]',
    );
    if (input) {
      input.setAttribute(
        'value',
        (input.getAttribute('value') || '').replace(
          /{iso8601}|%7Biso8601%7D/,
          now,
        ),
      );
    }

    measurementElement.replaceWith(copy);

    const row = copy.closest('.transition, [data-collapse-target="content"]');
    if (row) {
      row.classList.add('transition');

      ['opacity-50', 'hover:opacity-90', 'focus-within:opacity-90'].forEach(
        (token) => {
          row.classList.toggle(token, nextState);
        },
      );
    }
  }

  public connect(): void {
    emitter.on('measurement:archival', this.onMeasurementArchival);

    this.element
      .querySelectorAll(
        'form[data-checklists--measurement-archival-target="measurement"]',
      )
      .forEach((form) => form.setAttribute('data-remote', 'true'));
  }
  public disconnect(): void {
    emitter.off('measurement:archival', this.onMeasurementArchival);

    this.element
      .querySelectorAll(
        'form[data-checklists--measurement-archival-target="measurement"]',
      )
      .forEach((form) => form.removeAttribute('data-remote'));
  }
}
