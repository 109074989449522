export function requestSubmit(
  form: HTMLFormElement | null | undefined,
  button?: HTMLInputElement | HTMLButtonElement | null | undefined
) {
  if (!form) {
    return false;
  }

  button =
    button ||
    form.querySelector<HTMLButtonElement | HTMLInputElement>(
      'button[type="submit"]:not([formaction]):not([disabled]):not([hidden]), input[type="submit"]:not([formaction]):not([disabled]):not([hidden])'
    );

  const canRequestSubmit =
    'requestSubmit' in form && typeof form.requestSubmit === 'function';

  if (canRequestSubmit) {
    form.requestSubmit();
    return true;
  }

  // NOT equivalent, does not run the same validation checks, and does not
  // generate an event
  //
  // this.input.form?.submit();

  if (form.checkValidity()) {
    // TODO: the following is a much better idea but we need to test it first.
    // const formTrigger = button;
    // const submitEvent = new SubmitEvent("submit", { submitter: formTrigger });
    // form.dispatchEvent(submitEvent);

    setTimeout(() => button?.click(), 1);
    return true;
  }

  form.reportValidity();
  return false;
}
