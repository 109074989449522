import { Context, Controller } from '@hotwired/stimulus';

export default class AlertGroupToggleController extends Controller {
  public constructor(context: Context) {
    super(context);

    this.onToggleGroup = this.onToggleGroup.bind(this);
  }

  public onToggleGroup(event: Event & { params: { depth: number } }) {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }

    const group = event.currentTarget.closest('li');
    const depth = event.params.depth + 1;

    const selectables = group?.querySelectorAll<HTMLInputElement>(
      `input[type="checkbox"][data-depth="${depth}"]`,
    );

    selectables?.forEach((input) => (input.checked = !input.checked));
  }
}
