import { FocusKeys, focusTrap, focusZone } from '@primer/behaviors';
import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';
import { onTransitionEnd } from 'src/utils/dom-transition';

/**
 * Enables the history modal on an individual todo
 */
export default class MessageHistoryController extends Controller {
  public static targets = ['historyButton', 'historyModal'];
  public static values = {};

  private declare readonly historyModalTarget: HTMLElement;
  private declare readonly historyButtonTarget: HTMLButtonElement;
  private declare hasHistoryButtonTarget: boolean;

  private inflated: boolean = false;
  private focusController: AbortController | undefined;

  constructor(context: Context) {
    super(context);

    this.onEscape = this.onEscape.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);
  }

  public connect(): void {
    this.inflate();
  }

  public disconnect(): void {
    this.inflated = false;
  }

  get opened(): boolean {
    if (!this.hasHistoryButtonTarget) {
      return false;
    }

    return this.historyButtonTarget.getAttribute('aria-expanded') === 'true';
  }

  get disabled(): boolean {
    return this.historyButtonTarget.disabled;
  }

  set opened(next: boolean) {
    if (!this.hasHistoryButtonTarget) {
      return;
    }

    this.historyButtonTarget.setAttribute('aria-expanded', String(next));

    // this.inflate();

    if (this.inflated) {
      if (next) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  public onHistory(): void {
    if (this.disabled) {
      return;
    }

    this.opened = !this.opened;
  }

  private onEscape(e: KeyboardEvent): void {
    if (e.key !== 'Escape') {
      return;
    }

    e.preventDefault();
    this.opened = false;
  }

  private onOutsideClick(e: MouseEvent): void {
    if (e.target instanceof HTMLElement) {
      if (
        this.historyModalTarget == e.target ||
        this.historyModalTarget.contains(e.target)
      ) {
        return;
      }
    }

    this.opened = false;
  }

  private inflate(): void {
    if (this.inflated) {
      return;
    }

    this.inflated = true;
    if (this.opened) {
      this.historyModalTarget.getBoundingClientRect();
      this.show();
    }
  }

  private show(): void {
    /**
     * Entering: "transition ease-out duration-100"
     *     From: "transform opacity-0 scale-95"
     *       To: "transform opacity-100 scale-100"
     *  Leaving: "transition ease-in duration-75"
     *     From: "transform opacity-100 scale-100"
     *       To: "transform opacity-0 scale-95"
     */
    // Remove animation (and force render)
    this.historyModalTarget.classList.remove(
      'transition',
      'ease-in',
      'duration-75',
    );
    this.historyModalTarget.getBoundingClientRect();

    // Remove "leaving" and "leaving.to"
    toggleHidden(this.historyModalTarget, false);
    this.historyModalTarget.classList.remove(
      'transform',
      'opacity-0',
      'scale-95',
    );

    // Position correctly
    // this.recalculatePosition();

    // Add "entering" and "entering.from" (and force render)
    this.historyModalTarget.classList.add('transform', 'opacity-0', 'scale-95');
    this.historyModalTarget.getBoundingClientRect();

    // Add back animation
    this.historyModalTarget.classList.add(
      'transition',
      'ease-out',
      'duration-100',
    );

    requestAnimationFrame(() => {
      if (!this.opened) {
        return;
      }

      // Remove "entering.from" and add "entering.to"
      this.historyModalTarget.classList.remove('opacity-0', 'scale-95');
      this.historyModalTarget.classList.add('opacity-100', 'scale-100');

      this.trapFocus();

      document.addEventListener('keydown', this.onEscape);
      document.addEventListener('click', this.onOutsideClick);
    });

    // Allow using keyboard arrows
    // focusZone(this.historyButtonTarget, {
    //   abortSignal: this.focusController?.signal,
    //   bindKeys: FocusKeys.ArrowVertical | FocusKeys.HomeAndEnd,
    // });
  }

  private hide(): void {
    this.releaseFocus();

    document.removeEventListener('keydown', this.onEscape);
    document.removeEventListener('click', this.onOutsideClick);

    /**
     * Entering: "transition ease-out duration-100"
     *     From: "transform opacity-0 scale-95"
     *       To: "transform opacity-100 scale-100"
     *  Leaving: "transition ease-in duration-75"
     *     From: "transform opacity-100 scale-100"
     *       To: "transform opacity-0 scale-95"
     */

    // Enable animation (and force render)
    this.historyModalTarget.classList.remove('ease-out', 'duration-100');
    this.historyModalTarget.classList.add(
      'transition',
      'ease-in',
      'duration-75',
    );
    this.historyModalTarget.getBoundingClientRect();

    // Remove "entering" and "entering.to"
    // Add "leaving" and "leaving.from"
    this.historyModalTarget.classList.add('opacity-100', 'scale-100');

    requestAnimationFrame(() => {
      if (this.opened) {
        return;
      }

      // Remove "leaving.from" and add "leaving.to"
      this.historyModalTarget.classList.remove('opacity-100', 'scale-100');
      this.historyModalTarget.classList.add('opacity-0', 'scale-95');

      onTransitionEnd(this.historyModalTarget, 100, () => {
        if (this.opened) {
          return;
        }

        toggleHidden(this.historyModalTarget, true);
      });
    });
  }

  private trapFocus(): void {
    this.focusController = focusTrap(this.historyModalTarget);
    focusZone(this.historyModalTarget, {
      abortSignal: this.focusController?.signal,
      bindKeys: FocusKeys.ArrowVertical | FocusKeys.HomeAndEnd | FocusKeys.Tab,
      focusOutBehavior: 'wrap',
    });

    this.historyModalTarget.focus();
  }

  private releaseFocus(): void {
    if (this.focusController) {
      this.focusController.abort('Message History Modal released focus');
      this.focusController = undefined;
    }
  }
}
