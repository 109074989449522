import { Context, Controller } from '@hotwired/stimulus';

export default class AnchorTabsController extends Controller {
  public static targets = ['nav', 'select'];

  private declare navTarget: HTMLElement;
  private declare selectTarget: HTMLSelectElement;
  private observer: IntersectionObserver | undefined;
  private mapping: Record<string, HTMLAnchorElement> = {};

  constructor(context: Context) {
    super(context);

    this.onSelect = this.onSelect.bind(this);
  }

  public get activeAnchor(): HTMLAnchorElement | null {
    return this.navTarget.querySelector('a[aria-current]')!;
  }

  public set activeAnchor(next: HTMLAnchorElement | null) {
    // Remove old active styling
    if (this.activeAnchor) {
      const bar = this.activeAnchor.querySelector(
        '[aria-hidden="true"].absolute',
      )!;
      bar.classList.remove('bg-theme-primary-dark-500');
      bar.classList.add('bg-transparent');
      this.activeAnchor.classList.remove('text-gray-900', 'dark:text-white');
      this.activeAnchor.classList.add('text-gray-500', 'dark:text-gray-200');
      this.activeAnchor.removeAttribute('aria-current');
    }

    // Add new active styling
    if (next) {
      const nextBar = next.querySelector('[aria-hidden="true"].absolute')!;
      nextBar.classList.remove('bg-transparent');
      nextBar.classList.add('bg-theme-primary-dark-500');
      next.classList.remove('text-gray-500', 'dark:text-gray-200');
      next.classList.add('text-gray-900', 'dark:text-white');
      next.setAttribute('aria-current', 'true');

      const length = this.selectTarget.options.length;
      for (let i = 0; i < length; i++) {
        const option = this.selectTarget.options.item(i);

        if (option?.value.split('#').pop() == next.href.split('#').pop()) {
          option!.selected = true;
        }
      }
    }
  }

  public get enabled(): boolean {
    return (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    );
  }

  public get anchors(): NodeListOf<HTMLAnchorElement> {
    return this.navTarget.querySelectorAll('a[href]');
  }

  public connect(): void {
    if (!this.enabled) {
      return;
    }

    this.mapping = {};

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const currentY = entry.boundingClientRect.y;
          const id = entry.target.id;

          // Create a decision object
          const decision = {
            id,
            isIntersecting: entry.isIntersecting,
            currentRatio: entry.intersectionRatio,
          };

          if (decision.isIntersecting && decision.currentRatio === 1) {
            // Header at 30% from the top, update to current header
            // Notify the ToC Component
            this.activeAnchor = this.mapping[id] || null;
          } else if (
            !decision.isIntersecting &&
            decision.currentRatio < 1 &&
            decision.currentRatio > 0 // &&
            // decision.belowToc
          ) {
            // Previous Section Content is now visible, update to previous header
            const previous = Object.keys(this.mapping).indexOf(id) - 1;
            if (previous >= 0) {
              this.activeAnchor =
                this.mapping[Object.keys(this.mapping)[previous]];
            }
          }
        });
      },
      {
        threshold: [0, 1],
        rootMargin: '150px 0px -70% 0px',
      },
    );

    this.anchors.forEach((anchor) => {
      const key = anchor.href.split('#').pop()!;
      this.mapping[key] = anchor;
      this.observer!.observe(document.getElementById(key)!);
    });

    this.selectTarget.removeAttribute('disabled');
    this.selectTarget.addEventListener('change', this.onSelect);
  }

  public disconnect(): void {
    if (!this.enabled) {
      return;
    }

    this.observer!.disconnect();

    this.selectTarget.setAttribute('disabled', '');
    this.selectTarget.removeEventListener('change', this.onSelect);
  }

  public onSelect(_: Event) {
    const option = this.selectTarget.selectedOptions.item(0);
    if (option) {
      const key = option.value.split('#').pop()!;
      this.activeAnchor = this.mapping[key];
      this.activeAnchor.click();
    }
  }
}
