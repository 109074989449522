import { Context, Controller } from '@hotwired/stimulus';

let _supportsTemplates: null | boolean = null;
function supportsTemplates() {
  if (_supportsTemplates !== null) {
    return _supportsTemplates;
  }

  return (_supportsTemplates = 'content' in document.createElement('template'));
}

/**
 * Enables attaching a comment / message to checklist questions.
 */
export default class AttachmentController extends Controller {
  static targets = ['template', 'hidden', 'button'];

  private declare templateTarget: HTMLTemplateElement;
  private declare hiddenTarget: HTMLInputElement;
  private declare buttonTarget: HTMLButtonElement;

  private declare hasTemplateTarget: boolean;
  private declare hasHiddenTarget: boolean;
  private declare hasButtonTarget: boolean;

  constructor(context: Context) {
    super(context);

    this.onAddMessage = this.onAddMessage.bind(this);
  }

  connect(): void {
    super.connect();

    if (this.hasButtonTarget && supportsTemplates()) {
      this.buttonTarget.removeAttribute('disabled');
    }
  }

  private onAddMessage(event: Event) {
    if (!this.hasTemplateTarget) {
      return;
    }

    if (!('content' in document.createElement('template'))) {
      alert('Your browser does not support this feature.');
      return;
    }

    if (this.hasHiddenTarget) {
      this.hiddenTarget.remove();
    }

    const inflated =
      this.templateTarget.content.firstElementChild!.cloneNode(true);

    this.templateTarget.replaceWith(...Array.from(inflated.childNodes));

    if (event.currentTarget instanceof HTMLElement) {
      event.currentTarget.remove();
    }
  }
}
