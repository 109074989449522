import { Context, Controller } from '@hotwired/stimulus';

export default class ValueNumericConstraintController extends Controller {
  public static targets = ['left', 'right'];
  public static values = {
    operator: String,
  }

  private declare leftTarget: HTMLElement;
  private declare hasLeftTarget: boolean;
  private declare rightTarget: HTMLElement;
  private declare hasRightTarget: boolean;

  private declare operatorValue: String;
  private declare hasOperatorValue: boolean;

  private getLeft: (() => string | null) | null = null;
  private setLeft: ((v : string) => void) | null = null;
  private getRight: (() => string | null) | null = null;
  private setRight: ((v : string) => void) | null = null;
  
  private testFunc: ((left: number, right: number) => Boolean) | null = null;
  private clampLeft: ((right: number) => number) | null = null;
  private clampRight: ((left: number) => number) | null = null;

  private leftEventHandler: (e: Event) => void;
  private rightEventHandler: (e: Event) => void;

  constructor(props: Context) {
    super(props);

    this.leftEventHandler = this.onChangeLeft.bind(this);
    this.rightEventHandler = this.onChangeRight.bind(this);    
  }

  connect(): void {
    if (this.hasLeftTarget) {
      this.leftTarget.addEventListener('change', this.leftEventHandler);
      if (this.leftTarget instanceof HTMLSelectElement) {
        this.getLeft = () => (this.leftTarget as HTMLSelectElement).value;
        this.setLeft = (v) => (this.leftTarget as HTMLSelectElement).value = v;
      } else if (this.leftTarget instanceof HTMLInputElement) {
        this.getLeft = () => (this.leftTarget as HTMLInputElement).value;
        this.setLeft = (v) => (this.leftTarget as HTMLInputElement).value = v;
      } else {
        console.error('Not supported left type')
      }
    } else {
      console.error('No element with data-value-numeric-constraint-target="right" set')
    }

    if (this.hasRightTarget) {
      this.rightTarget.addEventListener('change', this.rightEventHandler);
      if (this.rightTarget instanceof HTMLSelectElement) {
        this.getRight = () => (this.rightTarget as HTMLSelectElement).value;
        this.setRight = (v) => (this.rightTarget as HTMLSelectElement).value = v;
      } else if (this.rightTarget instanceof HTMLInputElement) {
        this.getRight = () => (this.rightTarget as HTMLInputElement).value;
        this.setRight = (v) => (this.rightTarget as HTMLInputElement).value = v;
      } else {
        console.error('Not supported right type')
      }
    } else {
      console.error('No element with data-value-numeric-constraint-target="right" set')
    }
    
    if (this.hasOperatorValue) {
      switch(this.operatorValue.trim()) {
        case '=':
          this.testFunc = (left, right) => left == right;
          this.clampLeft = (right) => right;
          this.clampRight = (left) => left;
          break;
        case '<=':
          this.testFunc = (left, right) => left <= right;
          this.clampLeft = (right) => right;
          this.clampRight = (left) => left;
          break;
        case '>=':
          this.testFunc = (left, right) => left >= right;
          this.clampLeft = (right) => right;
          this.clampRight = (left) => left;
          break;
        case '<':
          this.testFunc = (left, right) => left < right;
          this.clampLeft = (right) => right - 1;
          this.clampRight = (left) => left + 1;
          break;
        case '>':
          this.testFunc = (left, right) => left > right;
          this.clampLeft = (right) => right + 1;
          this.clampRight = (left) => left - 1;
          break;
        default:
          console.error("Unknown operator: " + this.operatorValue);
      }
    } else {
      console.error("No data-value-numeric-constraint-operator-value attribute set");
    }
  }

  disconnect(): void {
    if (this.hasLeftTarget) {
      this.leftTarget.removeEventListener('change', this.leftEventHandler);
    }
    if (this.hasRightTarget) {
      this.rightTarget.removeEventListener('change', this.rightEventHandler);
    }
  }

  public onChangeLeft(e: Event) {
    if (this.getLeft == null || this.getRight == null || this.testFunc == null)
      return;

    let left = Number(this.getLeft());
    let right = Number(this.getRight());

    if (this.testFunc(left, right))
      return;

    if (this.setRight == null || this.clampRight == null)
      return;

    this.setRight(this.clampRight(left).toString());
  }

  public onChangeRight(e: Event) {
    if (this.getLeft == null || this.getRight == null || this.testFunc == null)
      return;

    let left = Number(this.getLeft());
    let right = Number(this.getRight());

    if (this.testFunc(left, right))
      return;

    if (this.setLeft == null || this.clampLeft == null)
      return;

    this.setLeft(this.clampLeft(right).toString());
  }
}
