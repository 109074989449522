import {
  FocusKeys,
  focusTrap,
  focusZone,
  getAnchoredPosition,
  PositionSettings,
} from '@primer/behaviors';
import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';
import { onTransitionEnd } from 'src/utils/dom-transition';

/**
 * Generic menu modal
 */
export default class MenuModal extends Controller {
  public static targets = ['menu', 'toggler', 'closeButton'];
  public static values = {
    side: String,
    align: String,
    alignmentOffset: Number,
    anchorOffset: Number,
    modal: String,
    mediaQuery: String,
  };

  private declare readonly menuTarget: HTMLElement;
  private declare readonly togglerTarget: HTMLButtonElement;
  private declare readonly closeButtonTarget: HTMLButtonElement;
  private declare sideValue: string;
  private declare alignValue: string;
  private declare alignmentOffsetValue: number;
  private declare anchorOffsetValue: number;
  private declare modalValue: string;
  private declare mediaQueryValue: string;

  private declare readonly hasSideValue: boolean;
  private declare readonly hasAlignValue: boolean;
  private declare readonly hasAlignmentOffsetValue: boolean;
  private declare readonly hasAnchorOffsetValue: boolean;
  private declare readonly hasCloseButtonTarget: boolean;
  private declare readonly hasModalValue: boolean;
  private declare readonly hasMediaQueryValue: boolean;

  private inflated: boolean = false;
  private focusController: AbortController | undefined;
  private menu!: HTMLElement;
  private resizeRaf: undefined | number;
  private mediaQuery: MediaQueryList | undefined;

  constructor(context: Context) {
    super(context);

    this.onEscape = this.onEscape.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRecalculatePosition = this.onRecalculatePosition.bind(this);
    this.onMediaQueryChange = this.onMediaQueryChange.bind(this);

    this.recalculatePosition = this.recalculatePosition.bind(this);
  }

  public get side(): string {
    if (this.hasSideValue) {
      return this.sideValue;
    }

    return 'outside-right';
  }

  public get align(): string {
    if (this.hasAlignValue) {
      return this.alignValue;
    }

    return 'start';
  }

  public get alignmentOffset(): number | undefined {
    if (this.hasAlignmentOffsetValue) {
      return this.alignmentOffsetValue;
    }

    return undefined;
  }

  public get anchorOffset(): number | undefined {
    if (this.hasAnchorOffsetValue) {
      return this.anchorOffsetValue;
    }

    return undefined;
  }

  public connect(): void {
    if (this.hasCloseButtonTarget) {
      this.closeButtonTarget.addEventListener('click', this.onClose);
    }

    this.inflate();
    this.togglerTarget.removeAttribute('disabled');

    if (this.hasMediaQueryValue) {
      this.mediaQuery = window.matchMedia(this.mediaQueryValue);
      this.mediaQuery.addEventListener('change', this.onMediaQueryChange);
    }
  }

  public disconnect(): void {
    if (this.hasCloseButtonTarget) {
      this.closeButtonTarget.removeEventListener('click', this.onClose);
    }

    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.onMediaQueryChange);
      this.mediaQuery = undefined;
    }

    this.hide(true);
    this.inflated = false;
    this.opened = false;

    if (this.menu !== this.menuTarget) {
      this.menu.remove();
    } else {
      this.menu
        .querySelectorAll('[role="menuitem"], a[href]')
        .forEach((item) => {
          item.removeEventListener('click', this.onClose);
        });
    }

    this.togglerTarget.setAttribute('disabled', '');
  }

  get opened(): boolean {
    return this.togglerTarget.getAttribute('aria-expanded') === 'true';
  }

  get disabled(): boolean {
    return this.togglerTarget.disabled;
  }

  set opened(next: boolean) {
    this.togglerTarget.setAttribute('aria-expanded', String(next));

    if (this.inflated) {
      if (next) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  public onToggle(e: Event): void {
    if (this.disabled) {
      return;
    }

    e.preventDefault();

    this.opened = !this.opened;
  }

  public onClose(e: Event): void {
    this.opened = false;
  }

  private onEscape(e: KeyboardEvent): void {
    if (e.key !== 'Escape') {
      return;
    }

    e.preventDefault();
    this.opened = false;
  }

  private onOutsideClick(e: MouseEvent): void {
    if (e.target instanceof HTMLElement) {
      if (this.menu == e.target || this.menu.contains(e.target)) {
        return;
      }
    }

    console.log(e);

    this.opened = false;
  }

  private onRecalculatePosition(e: Event): void {
    if (this.resizeRaf) {
      return;
    }

    this.resizeRaf = requestAnimationFrame(this.recalculatePosition);
  }

  private onMediaQueryChange(e: MediaQueryListEvent): void {
    if (this.disabled || !this.opened) {
      return;
    }

    if (e.matches) {
      return;
    }

    this.opened = false;
  }

  private inflate(): void {
    if (this.inflated) {
      return;
    }

    this.inflated = true;

    // Move to the modals container if possible
    const modalQuery =
      this.hasModalValue && this.modalValue !== 'false'
        ? this.modalValue
        : '#modals';
    const destination =
      this.element.closest(modalQuery) || document.querySelector(modalQuery);

    if (destination && (!this.hasModalValue || this.modalValue !== 'false')) {
      this.menu = this.menuTarget.cloneNode(true) as HTMLElement;
      destination.append(this.menu);
    } else {
      this.menu = this.menuTarget;
    }

    const clonedCloseButton = this.menu.querySelector(
      'button[data-menu-modal-target="closeButton"]',
    );
    if (clonedCloseButton) {
      clonedCloseButton.addEventListener('click', this.onClose);
    }

    this.menu.querySelectorAll('[role="menuitem"], a[href]').forEach((item) => {
      item.addEventListener('click', this.onClose);
    });

    if (this.opened) {
      this.menu.getBoundingClientRect();
      this.show();
    }
  }

  private trapFocus(): void {
    this.focusController = focusTrap(this.menu);
    focusZone(this.menu, {
      abortSignal: this.focusController?.signal,
      bindKeys: FocusKeys.ArrowVertical | FocusKeys.HomeAndEnd | FocusKeys.Tab,
      focusOutBehavior: 'wrap',
    });

    this.menu.focus();
  }

  private releaseFocus(): void {
    if (this.focusController) {
      this.focusController.abort('Menu modal released focus');
      this.focusController = undefined;
    }
  }

  private recalculatePosition(): void {
    if (!this.opened) {
      return;
    }

    this.resizeRaf = undefined;

    const settings = {
      side: this.side,
      align: this.align,
      alignmentOffset: this.alignmentOffset,
      anchorOffset: this.anchorOffset,
      allowOutOfBounds: false,
    } as Partial<PositionSettings>;

    const float = this.menu;
    const anchor = this.togglerTarget;
    const { top, left } = getAnchoredPosition(float, anchor, settings);
    float.style.top = `${top}px`;
    float.style.left = `${left}px`;
  }

  private show(): void {
    /**
     * Entering: "transition ease-out duration-100"
     *     From: "transform opacity-0 scale-95"
     *       To: "transform opacity-100 scale-100"
     *  Leaving: "transition ease-in duration-75"
     *     From: "transform opacity-100 scale-100"
     *       To: "transform opacity-0 scale-95"
     */

    // Remove animation (and force render)
    this.menu.classList.remove('transition', 'ease-in', 'duration-75');
    this.menu.getBoundingClientRect();

    // Remove "leaving" and "leaving.to"
    toggleHidden(this.menu, false);
    this.menu.classList.remove('transform', 'opacity-0', 'scale-95');

    // Position correctly
    this.recalculatePosition();

    // Add "entering" and "entering.from" (and force render)
    this.menu.classList.add('transform', 'opacity-0', 'scale-95');
    this.menu.getBoundingClientRect();

    // Add back animation
    this.menu.classList.add('transition', 'ease-out', 'duration-100');

    requestAnimationFrame(() => {
      if (!this.opened) {
        return;
      }

      // Animate "entering.from" to entering.to"
      this.menu.classList.remove('opacity-0', 'scale-95');
      this.menu.classList.add('opacity-100', 'scale-100');

      this.trapFocus();

      document.addEventListener('keydown', this.onEscape);
      document.addEventListener('click', this.onOutsideClick);
      window.addEventListener('resize', this.onRecalculatePosition);
    });
  }

  private hide(skipAnimation = false): void {
    this.releaseFocus();

    document.removeEventListener('keydown', this.onEscape);
    document.removeEventListener('click', this.onOutsideClick);
    window.removeEventListener('resize', this.onRecalculatePosition);

    /**
     * Entering: "transition ease-out duration-100"
     *     From: "transform opacity-0 scale-95"
     *       To: "transform opacity-100 scale-100"
     *  Leaving: "transition ease-in duration-75"
     *     From: "transform opacity-100 scale-100"
     *       To: "transform opacity-0 scale-95"
     */

    // Enable animation (and force render)
    this.menu.classList.remove('ease-out', 'duration-100');
    this.menu.classList.add('transition', 'ease-in', 'duration-75');
    this.menu.getBoundingClientRect();

    // Remove "entering" and "entering.to"
    // Add "leaving" and "leaving.from"
    this.menu.classList.add('opacity-100', 'scale-100');

    if (skipAnimation) {
      this.togglerTarget.focus();

      this.menu.classList.remove('opacity-100', 'scale-100');
      this.menu.classList.add('opacity-0', 'scale-95');

      toggleHidden(this.menu, true);
      return;
    }

    // Force render
    this.menu.getBoundingClientRect();

    requestAnimationFrame(() => {
      if (this.opened) {
        return;
      }

      this.togglerTarget.focus();

      // Remove "leaving.from" and add "leaving.to"
      this.menu.classList.remove('opacity-100', 'scale-100');
      this.menu.classList.add('opacity-0', 'scale-95');

      onTransitionEnd(this.menu, 100, () => {
        if (this.opened) {
          return;
        }

        toggleHidden(this.menu, true);
      });
    });
  }
}
