export function onTransitionEnd(
  element: HTMLElement,
  fallbackDuration: number,
  callback: () => void,
) {
  let timeout: number | NodeJS.Timeout | undefined = undefined;

  if (window && 'TransitionEvent' in window) {
    const onTransitionEnd = () => {
      element.removeEventListener('transitionend', onTransitionEnd);
      element.removeEventListener('transitioncancel', onTransitionCancel);

      clearTimeout(timeout);
      callback();
    };

    const onTransitionCancel = () => {
      element.removeEventListener('transitioncancel', onTransitionCancel);

      clearTimeout(timeout);
    };

    element.addEventListener('transitionend', onTransitionEnd);
    element.addEventListener('transitioncancel', onTransitionCancel);

    // Fallback if animation does not run or is not set up correctly.
    timeout = setTimeout(
      () => {
        console.warn(
          `[transition] expected transition to end within ${fallbackDuration}ms but it took more than 10x`,
        );
        onTransitionEnd();
      },
      fallbackDuration * 10 + 50,
    );
    return;
  }

  setTimeout(callback, fallbackDuration);
}
