import { Context, Controller } from '@hotwired/stimulus';
import { emitter } from 'src/emitter';

export default class CadenceGridFilterableController extends Controller {
  public static targets = [];
  public static values = {
    url: String,
    mediaType: String,
  };

  private declare readonly urlValue: string;
  private declare readonly mediaTypeValue: string;

  private pendingFilter: AbortController | undefined;

  constructor(props: Context) {
    super(props);

    this.onFilter = this.onFilter.bind(this);
  }

  connect(): void {
    emitter.on('cadence-grid:select', this.onFilter);
    emitter.on('cadence-grid:deselect', this.onFilter);
  }

  disconnect(): void {
    emitter.off('cadence-grid:select', this.onFilter);
    emitter.off('cadence-grid:deselect', this.onFilter);

    if (this.pendingFilter) {
      this.pendingFilter.abort('CadenceGridFilterableController#disconnect');
    }
  }

  private onFilter(params: unknown) {
    console.log('filter', params);

    if (!params || typeof params !== 'object') {
      return;
    }

    const iso8601 = 'iso8601' in params ? String(params.iso8601) : '';
    const nextUrl = this.urlValue
      .replace('{{iso8601}}', iso8601)
      .replace('%7B%7Biso8601%7D%7D', iso8601);

    let signal;

    if (this.pendingFilter) {
      this.pendingFilter.abort('Filter has changed');
    }

    if ('AbortController' in window) {
      this.pendingFilter = new AbortController();
      signal = this.pendingFilter.signal;
    }

    fetch(nextUrl, {
      credentials: 'same-origin',
      signal,
      headers: { accept: this.mediaTypeValue },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        const mediaType = (response.headers.get('Content-Type') || '')
          .split(';')
          .shift();

        if (mediaType === this.mediaTypeValue) {
          return response.text();
        }

        throw new Error(
          `Expected Content-Type ${this.mediaTypeValue}, actual: ${mediaType}`,
        );
      })
      .then((html) => {
        this.element.innerHTML = html;
      });
  }
}
