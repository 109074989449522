import { toggleExpanded, toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';

export default class SelectController extends Controller {
  public static values = {
    url: String,
  };

  private select!: HTMLSelectElement;
  private list!: HTMLUListElement;
  private trigger!: HTMLButtonElement;
  private currentPrimaryLabel!: HTMLSpanElement;
  private currentSecondaryLabel!: HTMLSpanElement;

  public constructor(context: Context) {
    super(context);

    this.onChanged = this.onChanged.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onListKey = this.onListKey.bind(this);
    this.onTriggerKey = this.onTriggerKey.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);
  }

  public get options(): NodeListOf<HTMLOptionElement> {
    return this.select.querySelectorAll('option');
  }

  public get expanded(): boolean {
    return this.trigger.getAttribute('aria-expanded') === 'true';
  }

  public set expanded(next: boolean) {
    toggleExpanded(this.trigger, next);
    toggleHidden(this.list, !next);

    if (next) {
      const focusTarget = this.selectedOption || this.firstOption || this.list;
      focusTarget?.focus();
    } else {
      this.trigger.focus();
    }
  }

  private get firstOption(): HTMLInputElement | undefined {
    const options = this.list.querySelectorAll('input');
    const first = options.item(0);
    return first;
  }

  private get lastOption(): HTMLInputElement | undefined {
    const options = this.list.querySelectorAll('input');
    const last = options.item(options.length - 1);
    return last;
  }

  private get selectedOption(): HTMLInputElement | undefined {
    return (
      this.list.querySelector<HTMLInputElement>('input:checked') ?? undefined
    );
  }

  private onToggle(): void {
    this.expanded = !this.expanded;
  }

  private onListKey(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab': {
        this.expanded = false;
        break;
      }

      case 'Escape': {
        this.expanded = false;
        break;
      }

      case 'Enter': {
        // Do not submit the form
        event.preventDefault();

        this.expanded = false;
        break;
      }

      default: {
        console.debug('[select] onListKey', event.key);
      }
    }
  }

  private onTriggerKey(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp': {
        // Prevent scrolling
        event.preventDefault();

        this.expanded = true;
        this.lastOption?.focus();
        break;
      }

      case 'ArrowDown': {
        // Prevent scrolling
        event.preventDefault();

        this.expanded = true;
        this.firstOption?.focus();
        break;
      }

      default: {
        console.debug('[select] onTriggerKey', event.key);
      }
    }
  }

  private onOutsideClick(e: MouseEvent): void {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    // Click was inside
    if (this.element.contains(e.target)) {
      return;
    }

    // Ignore if already closed
    if (!this.expanded) {
      return;
    }

    this.expanded = false;
  }

  public connect(): void {
    this.select = this.element.querySelector('select')!;
    if (this.select.tagName !== 'SELECT') {
      throw new Error('Expected <select> but got: ' + this.select.tagName);
    }

    const selectedOption = this.select.querySelector<HTMLOptionElement>(
      `option[value="${this.select.value}"]`,
    );

    // Build trigger
    this.trigger = document.createElement('button');
    this.trigger.type = 'button';
    this.select.classList.forEach((item) => this.trigger.classList.add(item));
    this.trigger.setAttribute('id', `${this.select.id}-trigger`);
    this.trigger.setAttribute('aria-haspopup', 'listbox');
    this.trigger.setAttribute(
      'aria-labelledby',
      this.select.getAttribute('aria-labelledby') || '',
    );
    this.trigger.addEventListener('click', this.onToggle);
    this.trigger.addEventListener('keydown', this.onTriggerKey);
    toggleExpanded(this.trigger, false);

    // Build trigger content
    const current = document.createElement('span');
    current.classList.add('w-full', 'inline-flex', 'truncate');
    this.currentPrimaryLabel = document.createElement('span');
    this.currentPrimaryLabel.classList.add('truncate');
    this.currentPrimaryLabel.textContent =
      selectedOption?.getAttribute('data-primary-label') ??
      selectedOption?.textContent ??
      'Select an option';
    this.currentSecondaryLabel = document.createElement('span');
    this.currentSecondaryLabel.classList.add(
      'ml-2',
      'truncate',
      'text-gray-500',
    );
    this.currentSecondaryLabel.textContent =
      selectedOption?.getAttribute('data-secondary-label') ?? '';
    current.append(this.currentPrimaryLabel, this.currentSecondaryLabel);

    const icon = document.createElement('span');
    icon.classList.add(
      'absolute',
      'inset-y-0',
      'right-0',
      'flex',
      'items-center',
      'pr-2',
      'pointer-events-none',
    );
    const iconSvg = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg',
    );
    iconSvg.classList.add('h-5', 'w-5');
    iconSvg.setAttribute('viewBox', '0 0 20 20');
    iconSvg.setAttribute('fill', 'currentColor');
    iconSvg.setAttribute('aria-hidden', 'true');
    const iconPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path',
    );
    iconPath.setAttribute('fill-rule', 'evenodd');
    iconPath.setAttribute('clip-rule', 'evenodd');
    iconPath.setAttribute(
      'd',
      'M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z',
    );

    iconSvg.append(iconPath);
    icon.append(iconSvg);

    this.trigger.append(current, icon);

    this.list = document.createElement('ul');
    this.list.classList.add(
      'absolute',
      'z-10',
      'mt-1',
      'w-full',
      'bg-white',
      'shadow-lg',
      'max-h-60',
      'rounded-sm',
      'py-1',
      'text-base',
      'border',
      'border-gray-300',
      'overflow-auto',
      'focus:outline-none',
      'sm:text-sm',
      'divide-y',
    );
    this.list.tabIndex = -1;
    this.list.id = `${this.element.id}-list`;
    // aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"

    this.options.forEach((option) => {
      const listItem = SelectController.buildItemFromOption(
        option,
        this.select.name,
        selectedOption?.value,
      );

      this.list.appendChild(listItem);
    });

    this.list.addEventListener('change', this.onChanged);
    this.list.addEventListener('keydown', this.onListKey);
    this.list.setAttribute('data-action', 'change->site-form#onParentChanged');

    toggleHidden(this.list, true);

    this.element.append(this.trigger, this.list);
    this.select.style.display = 'none';
    this.select.disabled = true;

    // Redirect label
    const label = document.querySelector<HTMLLabelElement>(
      `label[for="${this.select.id}"]`,
    );
    label?.setAttribute('for', this.trigger.id);

    document.addEventListener('click', this.onOutsideClick);
  }

  public disconnect(): void {
    document.removeEventListener('click', this.onOutsideClick);

    // Restore label
    const label = document.querySelector<HTMLLabelElement>(
      `label[for="${this.trigger.id}"]`,
    );
    label?.setAttribute('for', this.select.id);

    // Restore select
    this.select.style.display = 'none';
    this.select.disabled = false;
    this.trigger.remove();
    this.list.remove();
  }

  private onChanged(event: Event): void {
    if (!event.target || !(event.target instanceof HTMLElement)) {
      return;
    }

    const input = event.target.closest('input');
    if (!input) {
      return;
    }

    // Update current label
    this.currentPrimaryLabel.textContent =
      input.getAttribute('data-primary-label');
    this.currentSecondaryLabel.textContent = input.getAttribute(
      'data-secondary-label',
    );

    // Update the select
    this.select.disabled = false;
    this.select.selectedIndex = Array.from(this.options).findIndex(
      (option) => option.value === input.value,
    );
    this.select.disabled = true;
  }

  private static buildItemFromOption(
    option: HTMLOptionElement,
    name: string,
    selected?: string,
  ): HTMLLIElement {
    const listItem = document.createElement('li');
    listItem.classList.add(
      'text-gray-900',
      'cursor-default',
      'select-none',
      'relative',
      'hover:text-white',
      'hover:bg-blue-600',
      'focus-within:text-white',
      'focus-within:bg-blue-600',
      'group',
    );

    listItem.id = option.id;
    listItem.setAttribute('role', 'option');

    // Buid content
    const content = document.createElement('label');
    content.classList.add('flex', 'flex-col', 'py-2', 'pl-3', 'pr-9');
    content.htmlFor = `${option.id}-input`;

    const primaryLabel = document.createElement('span');
    primaryLabel.classList.add('font-normal', 'truncate');
    primaryLabel.textContent = option.hasAttribute('data-primary-label')
      ? option.getAttribute('data-primary-label')
      : option.textContent;

    const secondaryLabel = document.createElement('span');
    secondaryLabel.classList.add(
      'text-gray-500',
      'mt-1',
      'truncate',
      'group-hover:text-gray-300',
      'group-focus-within:text-gray-300',
    );
    secondaryLabel.textContent = option.getAttribute('data-secondary-label');

    content.append(primaryLabel, secondaryLabel);

    // Build checkmark
    const input = document.createElement('input');
    input.setAttribute('name', name);
    input.setAttribute('type', 'radio');
    input.setAttribute('id', `${option.id}-input`);
    input.setAttribute('value', option.value);
    input.setAttribute('data-primary-label', primaryLabel.textContent || '');
    input.setAttribute(
      'data-secondary-label',
      secondaryLabel.textContent || '',
    );
    input.classList.add('sr-only', 'peer');
    input.checked = option.value === selected;

    const checkmark = document.createElement('span');
    checkmark.classList.add(
      'text-blue-600',
      'absolute',
      'inset-y-0',
      'right-0',
      'flex',
      'items-center',
      'pr-4',
      'invisible',
      'peer-checked:visible',
      'group-hover:text-white',
      'group-focus-within:text-white',
    );

    const checkmarkSvg = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg',
    );
    checkmarkSvg.setAttribute('viewBox', '0 0 20 20');
    checkmarkSvg.setAttribute('fill', 'currentColor');
    checkmarkSvg.setAttribute('aria-hidden', 'true');
    checkmarkSvg.classList.add('h-5', 'w-5');

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('fill-rule', 'evenodd');
    path.setAttribute('clip-rule', 'evenodd');
    path.setAttribute(
      'd',
      'M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z',
    );
    checkmarkSvg.append(path);
    checkmark.append(checkmarkSvg);

    // Combine into HTML
    listItem.append(content, input, checkmark);
    return listItem;
  }
}
