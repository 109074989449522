import { PositionSettings } from '@primer/behaviors';
import { toggleHidden } from 'src/utils/dom-toggle';
import { Context } from '@hotwired/stimulus';
import PopoverController from './popover_controller';

export default class RemotePopoverController extends PopoverController {
  protected remotePopoverWrapper!: HTMLElement;

  public static values = {
    side: String,
    align: String,
    alignmentOffset: Number,
    anchorOffset: Number,
    modal: String,
    initialCss: String,
    delayTime: { type: Number, default: 500 },

    url: String,
  };

  protected declare urlValue: string;

  private loading = false;
  private loaded = false;
  private loadingPromise: Promise<void> = Promise.resolve();

  constructor(context: Context) {
    super(context);

    this.onReferenceUp = this.onReferenceUp.bind(this);
    this.onReferenceDown = this.onReferenceDown.bind(this);
    this.onReferenceDownNextFrame = this.onReferenceDownNextFrame.bind(this);
    this.onDelayedReferenceUp = this.onDelayedReferenceUp.bind(this);
    this.onDelayedReferenceDown = this.onDelayedReferenceDown.bind(this);

    this.onInsideClick = this.onInsideClick.bind(this);
    this.focusPopup = this.focusPopup.bind(this);
  }

  public disconnect(): void {
    this.element.removeEventListener('click', this.focusPopup);

    if (this.popover) {
      this.popover.removeEventListener('mouseenter', this.onReferenceUp);
      this.popover.removeEventListener('mouseleave', this.onReferenceDown);
      this.popover.removeEventListener('focusin', this.onReferenceUp);
      this.popover.removeEventListener(
        'focusout',
        this.onReferenceDownNextFrame,
      );
      this.popover.removeEventListener('click', this.onInsideClick);
    }

    super.disconnect();
  }

  protected getPopoverSource() {
    return this.remotePopoverWrapper;
  }

  protected getAnchorSettings(): Partial<PositionSettings> {
    return {
      side: this.hasSideValue
        ? (this.sideValue as PositionSettings['side'])
        : 'inside-center',
      align: this.hasAlignValue
        ? (this.alignValue as PositionSettings['align'])
        : 'center',
      alignmentOffset: this.hasAlignmentOffsetValue
        ? this.alignmentOffsetValue
        : undefined,
      anchorOffset: this.hasAnchorOffsetValue
        ? this.anchorOffsetValue
        : undefined,
      allowOutOfBounds: false,
    };
  }

  protected inflate() {
    if (this.inflated) {
      return;
    }

    const popoverWrapper = document.createElement('div');
    popoverWrapper.id = 'remote-popover-' + Math.random().toString(36);
    popoverWrapper.classList.add(
      ...(this.hasInitialCssValue
        ? this.initialCssValue.split(' ')
        : [
            'absolute',
            'z-10',
            'bg-white',
            'shadow-md',
            'rounded-md',
            'py-1',
            'text-base',
            'border',
            'border-gray-300',
            'focus:outline-none',
            'sm:text-sm',
            'w-16',
            'h-16',
            'flex',
          ]),
      'delay-150',
      'box-content',
      'before:absolute',
      "before:content-['']",
      'before:top-[-20px]',
      'before:left-[-20px]',
      'before:right-[-20px]',
      'before:bottom-[-20px]',
      'before:p-5',
    );

    popoverWrapper.innerHTML = `
      <div class="w-full flex-1 flex text-theme-primary-dark-500">
        <svg class="animate-spin h-5 w-5 m-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    `;

    this.remotePopoverWrapper = popoverWrapper;

    super.inflate();
  }

  public onDelayedReferenceUp(e: Event) {
    super.onDelayedReferenceUp(e);

    if (!this.loaded && !this.loading) {
      this.inflate();
      this.load().catch(() => {});
    }
  }

  public focusPopup() {
    this.popover.focus();
  }

  public noop() {
    /* noop */
  }

  public async open(): Promise<void> {
    return super
      .open()
      .then(() => {
        if (this.loaded) {
          //
        } else {
          return this.load();
        }
      })
      .catch(() => {});
  }

  protected onOutsideClick(e: MouseEvent): void {
    if (e.target instanceof HTMLElement) {
      if (
        this.hasTogglerTarget &&
        (this.togglerTarget === e.target ||
          this.togglerTarget.contains(e.target))
      ) {
        return;
      }

      if (this.element === e.target || this.element.contains(e.target)) {
        return;
      }

      if (
        (this.popover && this.popover === e.target) ||
        this.popover.contains(e.target)
      ) {
        return;
      }
    }

    super.onOutsideClick(e);
  }

  protected onInsideClick(e: MouseEvent): void {
    if (e.target instanceof HTMLElement) {
      if (
        (this.popover && this.popover === e.target) ||
        this.popover.contains(e.target)
      ) {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    }
  }

  private load(): Promise<void> {
    if (this.loading) {
      return this.loadingPromise;
    }

    this.loading = true;

    const expected = 'text/vnd.kaboom.dialog.inflate';

    this.loadingPromise = fetch(this.urlValue, {
      headers: {
        accept: `${expected}, application/problem+json; q=0.1`,
      },
    })
      .then((value) => {
        if (value.status === 204) {
          throw new Error('No content');
        }

        const contentType = value.headers.get('content-type') || '';

        if (contentType.startsWith(expected)) {
          return value.text();
        }

        if (contentType.includes(`variant=${expected}`)) {
          return value.text();
        }

        if (!value.ok) {
          throw new Error(`Received ${value.status}: ${value.statusText}`);
        }

        throw new Error(`Expected ${expected}, actual ${contentType}`);
      })
      .then((remoteContent) => {
        if (!this.element) {
          return;
        }

        // FLIP: First
        const before = this.popover.getBoundingClientRect();

        const contentWrapper = document.createElement('div');
        contentWrapper.innerHTML = remoteContent;

        // Remove old content
        while (this.popover.lastElementChild) {
          this.popover.removeChild(this.popover.lastElementChild);
        }

        const content = contentWrapper.firstElementChild as HTMLElement;

        // Add content
        while (content.firstElementChild) {
          this.popover.appendChild(content.firstElementChild);
        }

        this.popover.removeAttribute('class');

        // Copy attributes
        content.getAttributeNames().forEach((attribute) => {
          if (attribute !== 'hidden') {
            this.popover.setAttribute(
              attribute,
              content.getAttribute(attribute)!,
            );
          }
        });

        this.element.addEventListener('click', this.focusPopup);
        this.popover.addEventListener('mouseenter', this.onReferenceUp);
        this.popover.addEventListener('mouseleave', this.onReferenceDown);
        this.popover.addEventListener('focusin', this.onReferenceUp);
        this.popover.addEventListener(
          'focusout',
          this.onReferenceDownNextFrame,
        );
        this.popover.addEventListener('click', this.onInsideClick);

        /*
        // FLIP: Last
        const after = this.popover.getBoundingClientRect();
        const translate = `translate(${after.x - before.x}px, ${
          after.y - before.y
        }px)`;

        // FLIP: Inverse
        this.popover.style.transform = translate;
        this.popover.style.width = `${before.width}px`;
        this.popover.style.height = `${before.height}px`;
        this.popover.style.transition =
          'all 250ms cubic-bezier(0.4, 0.0, 0.2, 1)';

        // FLIP: Play
        this.resizeRaf = requestAnimationFrame(() => {
          if (this.opened) {
            this.popover.style.transform = `translate(0, 0)`;
            this.popover.style.width = `${after.width}px`;
            this.popover.style.height = `${after.height}px`;

            // TODO: cancel/remove if closing
            setTimeout(() => {
              if (this.opened) {
                this.popover.style.transform = '';
                this.popover.style.width = '';
                this.popover.style.height = '';
                this.popover.style.transition = '';
              }
            }, 250);
          }
        });
        */
      })
      .then(() => {
        this.loaded = true;
        this.loading = false;

        if (!this.popover) {
          return;
        }

        // Currently hidden
        if (!this.opened) {
          toggleHidden(this.popover, true);
        } else {
          this.recalculatePosition();
        }
      })
      .catch((error) => {
        this.loaded = false;
        this.loading = false;

        console.error(error);

        if (!this.popover) {
          return Promise.reject(error);
        }

        this.popover.remove();
        this.element.setAttribute('disabled', '');
        this.element.removeAttribute('data-controller');

        // Currently hidden
        if (!this.opened) {
          toggleHidden(this.popover, true);
        } else {
          this.close();
        }

        return Promise.reject(error);
      });

    return this.loadingPromise;
  }
}
