import ComboboxController from './combobox_controller';

export default class RemoteSelectController extends ComboboxController {
  public static values = {
    autocompleteUrl: String,
    multiple: Boolean,
    autoSubmit: Boolean,
    output: String,
    maxWidthClass: { type: String, default: 'sm:max-w-sm' },
  };
}
