import { Context, Controller } from '@hotwired/stimulus';

export default class TemplatedInputController extends Controller {
  static values = {
    template: String,
    sourceName: String,
  };

  private declare readonly templateValue: string;
  private declare readonly sourceNameValue: string;

  public constructor(context: Context) {
    super(context);

    this.onInputChanged = this.onInputChanged.bind(this);
  }

  public connect(): void {
    this.source.addEventListener('input', this.onInputChanged);

    this.updateTemplatedValue(this.sourceValue);
  }

  public disconnect(): void {}

  private get value(): string {
    return (this.element as HTMLInputElement).value;
  }

  private set value(next: string) {
    (this.element as HTMLInputElement).value = next;
  }

  private get sourceValue(): string {
    return this.source.value;
  }

  private get source(): HTMLInputElement {
    const form = this.element.closest('form')!;
    return form.querySelector<HTMLInputElement>(
      `[name="${this.sourceNameValue}"]`,
    )!;
  }

  public onInputChanged(event: Event): void {
    const input = event.currentTarget as HTMLInputElement;
    const newValue = input.value;

    this.updateTemplatedValue(newValue);
  }

  private updateTemplatedValue(newValue: string): void {
    this.value = this.applyTemplate(newValue);
  }

  private applyTemplate(value: string): string {
    return this.templateValue.replace(/{value}/, value);
  }
}
