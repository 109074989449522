import { mergeAttributes, Node, NodeViewRendererProps } from '@tiptap/core';

const SUPPORTED_TYPES = [
  'hidden',
  'text',
  'textarea',
  'url',
  'date',
  'time',
  'datetime-local',
  'email',
  'number',
  'checkbox',
  'radio',
  'site',
  'sites',
  'employee',
  'employees',
] as const;

export interface DsInputFactoryParams {
  autocompleteEmployeeUrl?: string;
  autocompleteSiteUrl?: string;
}

interface DsInputOptions {
  HTMLAttributes: DsInputAttributes;
}

type DsInputAttributes = {
  disabled?: boolean | null;
  checked?: boolean | null;
  selected?: boolean | null;
  multiple?: boolean | null;
  'ds-name': string;
  'ds-type': (typeof SUPPORTED_TYPES)[number];
  'ds-label'?: string | null;
  'ds-value'?: string | null;
};

export const DsInput = (options: DsInputFactoryParams) =>
  Node.create<DsInputOptions>({
    name: 'text/vnd.delftsolutions.ds-input.html',

    group: 'block',
    atom: false,
    content: 'inline*',

    renderHTML({ HTMLAttributes, node }) {
      return [
        'ds-input',
        mergeAttributes(HTMLAttributes, {
          'ds-content-value': node.textContent,
        }),
      ];
    },

    parseHTML() {
      return [
        {
          tag: 'ds-input[ds-type]',
          getAttrs: (node) => {
            if (
              !(
                node instanceof HTMLElement &&
                SUPPORTED_TYPES.includes(
                  node.getAttribute('ds-type') || ('' as any),
                )
              )
            ) {
              return false;
            }

            // debugger;

            const attributes = {
              id: Math.random().toString(36),

              disabled:
                node.hasAttribute('disabled') &&
                node.getAttribute('disabled') !== 'false',
              checked:
                node.hasAttribute('checked') &&
                node.getAttribute('checked') !== 'false',
              selected:
                node.hasAttribute('selected') &&
                node.getAttribute('selected') !== 'false',
              multiple:
                node.hasAttribute('multiple') &&
                node.getAttribute('multiple') !== 'false',
              'ds-name': node.getAttribute('ds-name'),
              'ds-value': node.getAttribute('ds-value'),
              'ds-type': node.getAttribute('ds-type'),
              'ds-label': node.getAttribute('ds-label'),
            };

            console.debug('[ds-input] attributes', attributes);

            return attributes;
          },
        },
      ];
    },

    addNodeView() {
      return ({
        editor,
        getPos,
        HTMLAttributes,
        node,
      }: NodeViewRendererProps) => {
        const { view } = editor;

        console.debug(
          '[ds-input] add node view',
          HTMLAttributes,
          node,
          HTMLAttributes['ds-name'],
          HTMLAttributes['ds-value'],
        );

        // Markup
        /*
        <div class="ds-input-view">
          <label>Node view</span>
          <input />
        </div>
        */

        // Create a container for the node view
        const dom = document.createElement('div');
        dom.classList.add('ds-input-view', 'form-input-group', 'mb-2');
        dom.classList.add(`--${HTMLAttributes['ds-type']}`);

        // Give other elements containing text `contentEditable = false`
        const label = document.createElement('label');
        label.textContent = HTMLAttributes['ds-label'];
        label.setAttribute('contentEditable', 'false');
        label.classList.add('form-label', 'mb-1');
        label.setAttribute('for', `${HTMLAttributes['id']}-input`);

        // Create a container for the content
        const content = document.createElement(
          HTMLAttributes['ds-type'] === 'textarea' ? 'textarea' : 'input',
        );
        content.setAttribute('id', `${HTMLAttributes['id']}-input`);
        content.classList.add('form-input');
        content.value = HTMLAttributes['ds-value'];
        content.disabled = HTMLAttributes['disabled'] !== false;
        content.name = HTMLAttributes['ds-name'];
        if (content instanceof HTMLInputElement) {
          content.type = validInputType(HTMLAttributes['ds-type']);
          content.checked = HTMLAttributes['checked'] !== false;
        }
        content.setAttribute('contentEditable', 'false');

        if (HTMLAttributes['ds-type'] === 'textarea') {
          content.setAttribute('data-controller', 'autosize');
        }

        if (content.type == 'radio') {
          label.classList.add('mt-2');
          content.classList.add('--radio', 'w-8', 'h-8', 'mt-1');
        }

        if (content.type == 'checkbox') {
          label.classList.add('mt-2');
          content.classList.add('--checkbox', 'w-8', 'h-8', 'mt-1');
        }

        const executeUpdateValue = (nowValue: string, position: number) => {
          console.debug('[ds-input] update value', nowValue, position);

          view.dispatch(
            view.state.tr.setNodeMarkup(position, undefined, {
              ...HTMLAttributes,
              'ds-value': nowValue,
            }),
          );

          // editor.view.focus();
        };

        content.addEventListener('blur', (e) => {
          if (typeof getPos !== 'function') {
            return;
          }

          const position = getPos();
          const input = e.target as HTMLInputElement;

          if (input.type !== 'radio' && input.type !== 'checkbox') {
            executeUpdateValue(input.value, position);
          }
        });

        if (content.type === 'radio') {
          const self = node;

          content.addEventListener('input', (e) => {
            if (typeof getPos !== 'function') {
              return;
            }

            // debugger;

            let startCount = 0;
            const otherRadios: [number, typeof self][] = [];

            for (let i = 0; i < editor.state.doc.content.childCount; i++) {
              const child = editor.state.doc.content.child(i);
              const pos = startCount;

              startCount += child.nodeSize;

              if (
                child === self ||
                child.type !== self.type ||
                child.attrs['ds-type'] !== 'radio' ||
                child.attrs['ds-name'] !== self.attrs['ds-name']
              ) {
                continue;
              }

              otherRadios.push([pos, child]);
            }

            let transaction = view.state.tr;

            // Update all radios
            otherRadios.forEach(([i, child]) => {
              transaction = transaction.setNodeMarkup(i, undefined, {
                ...child.attrs,
                checked: false,
              });
            });

            const position = getPos();
            const input = e.target as HTMLInputElement;

            // Update the current radio
            view.dispatch(
              transaction.setNodeMarkup(position, undefined, {
                ...HTMLAttributes,
                checked: input.checked,
              }),
            );

            // editor.view.focus();
          });
        }

        if (content.type === 'checkbox') {
          content.addEventListener('input', (e) => {
            if (typeof getPos !== 'function') {
              return;
            }

            let transaction = view.state.tr;
            const position = getPos();
            const input = e.target as HTMLInputElement;

            // Update the current checkbox
            view.dispatch(
              transaction.setNodeMarkup(position, undefined, {
                ...HTMLAttributes,
                checked: input.checked,
              }),
            );

            // editor.view.focus();
          });
        }

        dom.append(label, content);

        if (
          content instanceof HTMLInputElement &&
          ['site', 'sites', 'employee', 'employees'].includes(
            HTMLAttributes['ds-type'],
          )
        ) {
          content.setAttribute('autocomplete', 'off');
          content.type = 'hidden';

          const button = document.createElement('button');
          button.setAttribute('type', 'button');
          button.setAttribute('disabled', '');
          button.classList.add('button', '--neutral');
          button.textContent = 'Save to edit';

          dom.append(button);
        }

        return {
          dom,
          stopEvent: (event) => true,
          ignoreMutation: () => true,
        };
      };
    },

    addAttributes() {
      return {
        id: {
          default: null,
        },
        checked: {
          default: null,
        },
        disabled: {
          default: null,
        },
        selected: {
          default: null,
        },
        multiple: {
          default: null,
        },
        'ds-type': {
          isRequired: true,
        },
        'ds-name': {
          isRequired: true,
        },
        'ds-label': {
          default: null,
        },
        'ds-value': {
          default: null,
        },
      };
    },
  });

function validInputType(type: DsInputAttributes['ds-type']): string {
  switch (type) {
    case 'employee':
    case 'site': {
      return 'url';
    }
    case 'employees':
    case 'sites': {
      return 'text';
    }
    default: {
      return type;
    }
  }
}
