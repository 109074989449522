import { Context, Controller } from '@hotwired/stimulus';

export default class RowCheckboxController extends Controller {
  constructor(props: Context) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  connect(): void {
    this.element.addEventListener('click', this.onClick);
  }

  disconnect(): void {
    this.element.removeEventListener('click', this.onClick);
  }

  public onClick(e: Event) {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    if (e.target.closest('label')) {
      return;
    }

    const input = this.element.querySelector<HTMLInputElement>(
      'input[type="checkbox"], input[type="radio"]',
    );
    if (input) {
      input.checked = !input.checked;
    }
  }
}
