export function querySelectorChildren<K extends keyof HTMLElementTagNameMap>(
  parent: Element,
  selectors: K
): readonly HTMLElementTagNameMap[K][];
export function querySelectorChildren<K extends keyof SVGElementTagNameMap>(
  parent: Element,
  selectors: K
): readonly SVGElementTagNameMap[K][];
export function querySelectorChildren<E extends Element = Element>(
  parent: Element,
  selectors: string
): readonly E[];

export function querySelectorChildren<T extends Element = Element>(
  parent: Element,
  query: string
): readonly T[] {
  const matches: T[] = [];

  document.querySelectorAll<T>(query).forEach((value) => {
    if (value.parentElement === parent || value.parentNode === parent) {
      matches.push(value);
    }
  });

  return matches;
}
