import { type Context, Controller } from '@hotwired/stimulus';
import TemplateToDomController from 'controllers/template_to_dom_controller';
import { emitter } from 'src/emitter';
import { toggleHidden } from 'src/utils/dom-toggle';

export default class EditorController extends Controller {
  constructor(context: Context) {
    super(context);

    this.onReordered = this.onReordered.bind(this);
  }

  connect(): void {
    emitter.on('reordered', this.onReordered);
  }

  disconnect(): void {
    emitter.off('reordered', this.onReordered);
  }

  private onReordered(data: unknown) {
    if (typeof data === 'object' && data && 'element' in data) {
      // const element = data.element as HTMLElement;
    }

    this.element
      .querySelectorAll('[data-reorderable-group="section"]:not([hidden])')
      .forEach((section, sectionIndex) => {
        section
          .querySelectorAll(
            '[name^="measurements["][name$="[section]"]:enabled',
          )
          .forEach((input) => {
            input.setAttribute('value', String(sectionIndex));
          });
      });

    this.element
      .querySelectorAll('[name^="measurements["][name$="[order]"]:enabled')
      .forEach((input, itemIndex) => {
        input.setAttribute('value', String(itemIndex));
      });
  }

  public onRemoveSection(event: Event) {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }

    const boundary = event.currentTarget.closest(
      '[data-checklists--editor-target="boundary"]',
    );
    if (!boundary) {
      return;
    }

    boundary
      .querySelectorAll(
        '[data-action="checklists--editor#onRemoveQuestion"][data-checklists--editor-question-id-value]',
      )
      .forEach((nestedQuestionRemoveButton) => {
        const questionId = nestedQuestionRemoveButton.getAttribute(
          'data-checklists--editor-question-id-value',
        );
        if (questionId) {
          this.removeQuestionById(questionId);
        }
      });

    toggleHidden(boundary);
    this.onReordered(undefined);
  }

  public onRemoveQuestion(event: Event) {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }

    const questionId = event.currentTarget.getAttribute(
      'data-checklists--editor-question-id-value',
    );
    if (questionId) {
      this.removeQuestionById(questionId);
    }
  }

  public onRemoveTrigger(event: Event) {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }

    const triggerId = event.currentTarget.getAttribute(
      'data-checklists--editor-trigger-id-value',
    );
    if (triggerId) {
      this.removeTriggerById(triggerId);
    }
  }

  public onDuplicateQuestion(event: Event) {}

  private removeQuestionById(questionId: string) {
    const input = this.element.querySelector(
      `input[name="measurements[${questionId}][title]"]`,
    );
    const boundary = input?.closest(
      '[data-checklists--editor-target="boundary"]',
    );

    if (!boundary) {
      return;
    }

    // Remove the question completely if it was new
    if (
      boundary.querySelector(
        `input[name="measurements[${questionId}][new]"][value="1"]`,
      )
    ) {
      boundary.remove();
      this.onReordered(undefined);
      return;
    }

    // Otherwise, mark as removed
    boundary.querySelectorAll('input').forEach((input) => {
      input.disabled = true;
    });

    const removeInput = document.createElement('input');
    removeInput.type = 'hidden';
    removeInput.name = `measurements[${questionId}][remove]`;
    removeInput.value = '1';

    boundary.setAttribute('hidden', '');
    boundary.append(removeInput);

    boundary
      .querySelectorAll('[data-action="checklists--editor#onRemoveTrigger"]')
      .forEach((removeTrigger) => {
        const triggerId = removeTrigger.getAttribute(
          'data-checklists--editor-trigger-id-value',
        );

        if (triggerId) {
          this.removeTriggerById(triggerId);
        }
      });

    this.onReordered(undefined);
  }

  private removeTriggerById(triggerId: string) {
    const input = this.element.querySelector(
      `input[name="triggers[${triggerId}][todo_title]"]`,
    );
    const boundary = input?.closest(
      '[data-checklists--editor-target="boundary"]',
    );

    if (!boundary) {
      return;
    }

    const noTriggerBoundary = TemplateToDomController.inflate(
      document.getElementById('no-trigger') as HTMLTemplateElement,
      {
        ':measurement_id':
          boundary.querySelector<HTMLInputElement>(
            `input[name="triggers[${triggerId}][measurement_id]`,
          )?.value || '',
      },
    );

    // Remove the trigger completely if it was new
    if (
      boundary.querySelector(
        `input[name="triggers[${triggerId}][new]"][value="1"]`,
      )
    ) {
      boundary.replaceWith(noTriggerBoundary);
      return;
    }

    // Otherwise, mark as removed
    boundary.querySelectorAll('input').forEach((input) => {
      input.disabled = true;
    });

    const removeInput = document.createElement('input');
    removeInput.type = 'hidden';
    removeInput.name = `triggers[${triggerId}][remove]`;
    removeInput.value = '1';

    boundary.setAttribute('hidden', '');
    boundary.append(removeInput);
    boundary.parentElement!.insertBefore(noTriggerBoundary, boundary);
  }
}
