import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

const application = Application.start(document.documentElement);

// Normally you'd need to add the webpack env types, but since we'll migrate
// away (in Rails 7), and we know the signatures here, it's ignored and casted
// as any instead.

// @ts-ignore
// eslint-disable-next-line no-undef
const context = require.context('../controllers', true, /_controller\.ts$/);
application.load(definitionsFromContext(context));

declare const window: Window & { Stimulus: Application };
window.Stimulus = application;

declare const process: { env: { NODE_ENV: string } };
application.debug = true; // process.env.NODE_ENV === 'development'
