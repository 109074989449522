import { Controller } from '@hotwired/stimulus';
import { emitter } from 'src/emitter';

export default class NotificationsBellController extends Controller {
  public static targets = [
    'notificationCount',
  ];

  private declare notificationCountTarget: HTMLElement;

  public onNotificationEmit(data: any): void {
    if (data && data['count'] !== undefined) {
      this.notificationCountTarget.innerHTML = data['count'];
      if (data['count'] === 0) {
        this.notificationCountTarget.style.display = 'none';
      } else {
        this.notificationCountTarget.style.display = 'block';
      }
    }
  }

  public connect(): void {
    emitter.on('notification:read', this.onNotificationEmit.bind(this));
    emitter.on('notification:read_all', this.onNotificationEmit.bind(this));
  }
  public disconnect(): void {
    emitter.off('notification:read', this.onNotificationEmit);
    emitter.off('notification:read_all', this.onNotificationEmit);
  }
}
