import autosize from 'autosize';
import { Context, Controller } from '@hotwired/stimulus';

export default class AutosizeController extends Controller {
  private autosizeMethod: 'css' | 'javascript';

  constructor(props: Context) {
    super(props);

    this.autosizeMethod = CSS.supports('(field-sizing: content)')
      ? 'css'
      : 'javascript';
  }

  connect(): void {
    if (this.element instanceof HTMLTextAreaElement) {
      switch (this.autosizeMethod) {
        case 'javascript': {
          autosize(this.element);
          break;
        }

        case 'css': {
          this.element.classList.add('[field-sizing:content]');
          break;
        }
      }
    }
  }

  disconnect(): void {
    if (this.element instanceof HTMLTextAreaElement) {
      switch (this.autosizeMethod) {
        case 'javascript': {
          autosize.destroy(this.element);
          break;
        }

        case 'css': {
          this.element.classList.remove('[field-sizing:content]');
          break;
        }
      }
    }
  }
}
