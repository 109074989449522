import { mergeAttributes, Node, NodeViewRendererProps } from '@tiptap/core';

export const ActionTextImageAttachment = Node.create({
  name: 'image/vnd.kaboom.attachment',
  group: 'block',
  atom: false,

  renderHTML({ HTMLAttributes }) {
    return ['action-text-attachment', mergeAttributes(HTMLAttributes)];
  },

  parseHTML() {
    return [
      {
        tag: 'action-text-attachment[content-type^="image/"]',
      },
    ];
  },

  addAttributes() {
    return {
      sgid: {
        default: null,
      },

      'content-type': {
        default: null,
      },

      caption: {
        default: null,
      },

      url: {
        default: null,
      },

      width: {
        default: null,
      },

      height: {
        default: null,
      },

      filename: {
        default: null,
      },

      filesize: {
        default: 0,
      },
    };
  },

  addNodeView() {
    return (props: NodeViewRendererProps) => {
      // Markup
      /*
        <figure>
          <img />
          <figcaption>
            <input />
          </figcaption>
        </div>
      */

      // Create a container for the node view
      const dom = document.createElement('figure');
      dom.classList.add(
        'p-2',
        'border',
        'rounded-sm',
        'bg-white',
        'dark:bg-gray-800',
        'border',
        'border-gray-200',
        'dark:border-gray-700'
      );
      dom.setAttribute('contenteditable', 'false');

      // Create the image
      const image = new Image(
        props.HTMLAttributes['width'],
        props.HTMLAttributes['height']
      );
      image.src = props.HTMLAttributes['url'];
      image.alt = '';
      image.setAttribute('contenteditable', 'false');
      image.classList.add('rounded-sm', 'overflow-hidden');

      // Create the label
      const content = document.createElement('figcaption');
      content.textContent = props.HTMLAttributes['caption'];
      content.classList.add('text-center');

      const text = document.createTextNode(
        props.HTMLAttributes['caption'] ||
          `${props.HTMLAttributes['filename']} · ${
            props.HTMLAttributes['filesize'] / 1000
          } KB`
      );
      content.append(text);
      content.setAttribute(
        'contenteditable',
        String(!props.HTMLAttributes['filename'])
      );

      // Append all elements to the node view container
      dom.append(image, content);

      return {
        dom,
      };
    };
  },
});
