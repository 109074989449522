import { Plugin } from 'prosemirror-state';
import { AttachmentAttributes } from 'src/attachment_upload';

export type UploadFile = (file: File) => Promise<AttachmentAttributes>;

export function createUploadFilePlugin(upload: UploadFile) {
  return new Plugin({
    props: {
      handlePaste(view, event) {
        console.log('[upload-file] paste', event);

        const items = Array.from(event.clipboardData?.items || []);
        const { schema } = view.state;

        items.forEach((item) => {
          const file = item.getAsFile();

          if (item.type.indexOf('image') === -1) {
            event.preventDefault();

            if (upload && file) {
              upload(file)
                .then(({ url, sgid, caption }) => {
                  const node = schema.nodes[
                    'text/vnd.delftsolutions.ds-file-upload'
                  ].create({
                    url,
                    sgid,
                    caption,
                  });
                  const transaction = view.state.tr.replaceSelectionWith(node);
                  view.dispatch(transaction);
                })
                .catch((error) => {
                  console.error(error);
                  throw error;
                });
            }
          }
        });

        return false;
      },

      handleDrop(view, event) {
        console.log('[upload-file] drop', event);

        const hasFiles = event.dataTransfer?.files?.length;

        if (!hasFiles) {
          return false;
        }

        const files = Array.from(event!.dataTransfer!.files).filter(
          (file) => !/image/i.test(file.type)
        );

        if (files.length === 0) {
          return false;
        }

        event.preventDefault();

        const { schema } = view.state;
        const coordinates = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        });

        files.forEach(async (file) => {
          const attributes = await upload(file);

          const node = schema.nodes[
            'text/vnd.delftsolutions.ds-file-upload'
          ].create({
            sgid: attributes.sgid,
            url: attributes.url,
            caption: attributes.caption,
          });

          const transaction = view.state.tr.insert(coordinates!.pos, node);
          view.dispatch(transaction);
        });

        return false;
      },
    },
  });
}
