import { mergeAttributes, Node } from '@tiptap/core';

export const ActionTextChecklist = Node.create({
  name: 'vnd.kaboom.actiontext-attachment.checklist',
  group: 'block',
  atom: true,
  content: 'inline*',

  renderHTML({ HTMLAttributes }) {
    return ['action-text-attachment', mergeAttributes(HTMLAttributes), 0];
  },

  parseHTML() {
    return [
      {
        tag: 'action-text-attachment[content-type="text/vnd.kaboom.actiontext-attachment.checklist.html"]',
      },
    ];
  },

  addAttributes() {
    return {
      sgid: {
        default: null,
      },

      'content-type': {
        default: null,
      },

      caption: {
        default: null,
      },

      contenteditable: {
        default: false,
      },
    };
  },
});
