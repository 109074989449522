import { Context, Controller } from '@hotwired/stimulus';

type InputsMap = Omit<
  Record<string, (HTMLInputElement | HTMLTextAreaElement)[]>,
  '__order'
> & {
  __order: string[];
};

/**
 * Enables special actions for checklist forms
 */
export default class FormController extends Controller {
  private form!: HTMLFormElement;
  private inputs!: InputsMap;

  constructor(context: Context) {
    super(context);
  }

  public connect(): void {
    this.form = this.element.closest('form')!;

    const elements = this.form.querySelectorAll<
      HTMLInputElement | HTMLTextAreaElement
    >('input[name], textarea[name]');

    this.inputs = Array.from(elements)
      .filter(
        (input) =>
          input.type !== 'hidden' &&
          input.type !== 'submit' &&
          !input.hasAttribute('data-trix-input'),
      )
      .reduce<InputsMap>(
        (groups, input) => {
          const name = input.getAttribute('name');
          if (!name) {
            return groups;
          }

          if (!groups[name]) {
            groups.__order.push(name);
            groups[name] = [];
          }

          groups[name].push(input);

          return groups;
        },
        { __order: [] },
      );
  }

  public disconnect(): void {}

  public onSubmit(e: SubmitEvent): void {
    if (!(e.target instanceof HTMLFormElement)) {
      return;
    }

    if (!e.target.checkValidity()) {
      return;
    }

    this.form
      .querySelectorAll<HTMLButtonElement>(
        'button[type="submit"], input[type="submit"]',
      )
      .forEach((value) => {
        value.disabled = true;
      });

    console.log('onSubmit', e);
  }

  public onSubmitEnd(e: Event): void {
    console.log('onSubmitEnd', e);

    this.form
      .querySelectorAll<HTMLButtonElement>(
        'button[type="submit"], input[type="submit"]',
      )
      .forEach((value) => {
        value.disabled = false;
      });
  }

  public onNext(e: Event): void {
    const nextGroup = this.inputs.__order.find((group) => {
      return this.inputs[group].every((input) =>
        input instanceof HTMLTextAreaElement
          ? !input.value
          : !input.checked &&
            !input.disabled &&
            (input.type === 'checkbox' ||
              input.type === 'radio' ||
              !input.value),
      );
    });

    // Everything is filled in
    if (!nextGroup) {
      const submit = this.form.querySelector<HTMLElement>(
        'input[type="submit"], button[type="submit"]',
      );
      submit?.focus();
      return;
    }

    // Something is not filled in
    const input = this.inputs[nextGroup].find(
      (input) =>
        !input.disabled &&
        (input.type === 'checkbox' || input.type === 'radio' || !input.value),
    )!;

    // Make sure the group is not collapsed
    const collapseGroup = input.closest('[data-controller="collapse"]');
    if (collapseGroup) {
      const trigger = collapseGroup.querySelector<HTMLButtonElement>(
        'button[aria-expanded]',
      );
      if (trigger?.getAttribute('aria-expanded') === 'false') {
        trigger.click();
      }
    }

    input.focus();
  }

  public onToggle(e: Event): void {
    const firstTrigger = this.form.querySelector(
      '[data-controller="collapse"] button[aria-expanded]',
    );
    if (!firstTrigger) {
      return;
    }

    const currentFocus = document.activeElement;
    const nextValue = firstTrigger.getAttribute('aria-expanded') === 'false';
    const collapsed = this.form.querySelectorAll<HTMLButtonElement>(
      '[data-controller="collapse"] button[aria-expanded]',
    );

    // Click on all buttons that do not match the requested value
    Array.from(collapsed)
      .reverse()
      .forEach((item: HTMLButtonElement) => {
        if (item.getAttribute('aria-expanded') !== String(nextValue)) {
          item.click();
        }
      });

    if (currentFocus && currentFocus instanceof HTMLElement) {
      currentFocus.focus();
    }
  }
}
