import { Context, Controller } from '@hotwired/stimulus';

/**
 * Dynamically changes the site access input when options are updated.
 */
export default class SiteAccessController extends Controller {
  public static targets = ['submit', 'switch'];

  public static values = {
    'confirm-dialog-message': String,
    'confirm-dialog-id': String,
  };

  private initialValues: Record<string, string> = {};
  private changedValues: Record<string, string> = {};

  private declare confirmDialogMessageValue: string;
  private declare confirmDialogIdValue: string;

  private declare submitTarget: HTMLButtonElement;
  private declare switchTargets: NodeListOf<HTMLAnchorElement>;
  private form!: HTMLFormElement;

  private pendingTimeout: number | undefined;
  private pendingCleanupFunction: Function | undefined;

  constructor(context: Context) {
    super(context);

    this.onFreezeForm = this.onFreezeForm.bind(this);
    this.onRestoreForm = this.onRestoreForm.bind(this);
    this.onResetInitial = this.onResetInitial.bind(this);
    this.onBlinkSuccess = this.onBlinkSuccess.bind(this);
  }

  private get fields(): NodeListOf<HTMLSelectElement> {
    return this.element.querySelectorAll(
      'select[data-label][data-distinguished-name]',
    );
  }

  public connect(): void {
    this.onResetInitial();
    this.update();

    this.form = this.element.closest('form')!;
    this.form.addEventListener('ajax:before', this.onFreezeForm);
    this.form.addEventListener('ajax:success', this.onResetInitial);
    this.form.addEventListener('ajax:success', this.onBlinkSuccess);
    this.form.addEventListener('ajax:complete', this.onRestoreForm);
  }

  public disconnect(): void {
    if (this.pendingTimeout) {
      clearTimeout(this.pendingTimeout);
    }

    if (this.pendingCleanupFunction) {
      this.pendingCleanupFunction();
    }

    this.element.querySelectorAll('[value="-"]').forEach((option) => {
      option.remove();
    });

    this.form.removeEventListener('ajax:before', this.onFreezeForm);
    this.form.removeEventListener('ajax:success', this.onResetInitial);
    this.form.removeEventListener('ajax:success', this.onBlinkSuccess);
    this.form.removeEventListener('ajax:complete', this.onRestoreForm);
  }

  private onFreezeForm() {
    this.fields.forEach((field) => {
      if (this.changedValues[field.name] === undefined) {
        field.setAttribute('disabled', '');
      }
    });
  }

  private onRestoreForm() {
    this.fields.forEach((field) => {
      field.removeAttribute('disabled');
    });

    this.update();
  }

  private onBlinkSuccess() {
    // Run in next frame so the button is restored from its disabled state;
    requestAnimationFrame(() => {
      const restoreNeutral = this.submitTarget.classList.contains('--neutral');
      const restorePrimary = this.submitTarget.classList.contains('--primary');
      const restoreText = this.submitTarget.textContent;

      this.pendingCleanupFunction = () => {
        this.submitTarget.textContent = restoreText;
        this.submitTarget.classList.remove('--green');
        this.submitTarget.classList.toggle('--neutral', restoreNeutral);
        this.submitTarget.classList.toggle('--primary', restorePrimary);

        this.pendingCleanupFunction = undefined;
      };

      this.submitTarget.classList.remove('--neutral', '--primary');
      this.submitTarget.classList.add('--green');
      this.submitTarget.textContent = 'Changes applied!';

      this.pendingTimeout = setTimeout(this.pendingCleanupFunction, 3000);
    });
  }

  private onResetInitial() {
    this.initialValues = {};
    this.changedValues = {};

    this.fields.forEach((field) => {
      const { field: valueField, value } = this.valueOf(field);
      if (field === valueField) {
        this.initialValues[field.name] = value;
      }
    });
  }

  private fieldFor(name: string | undefined): HTMLSelectElement | null {
    if (!name) {
      return null;
    }

    return this.element.querySelector<HTMLSelectElement>(
      `select[data-distinguished-name="${name}"]`,
    );
  }

  private valueOf(field: HTMLSelectElement): {
    field: HTMLSelectElement;
    value: string;
  } {
    if (field.value && field.value !== '-') {
      return { field, value: field.value };
    }

    const parent = field
      .getAttribute('data-distinguished-name')
      ?.split('.')
      .slice(0, -1)
      .join('.');

    const parentField = this.fieldFor(parent);
    if (!parentField) {
      return { field, value: '' };
    }

    return this.valueOf(parentField);
  }

  private update(): void {
    this.fields.forEach((field) => {
      const { field: valueField, value } = this.valueOf(field);

      // Remove any disabled field
      field.querySelector('[value="-"]')?.remove();

      if (valueField === field) {
        const fieldChanged = this.initialValues[field.name] !== value;

        if (fieldChanged) {
          this.changedValues[field.name] = value;
        } else {
          delete this.changedValues[field.name];
        }
      } else {
        if (this.initialValues[field.name] !== undefined) {
          this.changedValues[field.name] = value;
        } else {
          delete this.changedValues[field.name];
        }

        if (value === '') {
          field.selectedIndex = 0;
        } else {
          const disabledNode = document.createElement('option');
          disabledNode.setAttribute('value', '-');
          disabledNode.selected = true;

          field.prepend(disabledNode);
          disabledNode.textContent = `${value} → ${valueField.getAttribute(
            'data-label',
          )}`;
        }
      }
    });

    const hasChanges = Boolean(Object.keys(this.changedValues).length);
    const hadChanges = this.submitTarget.classList.contains('--primary');

    this.submitTarget.classList.toggle('--neutral', !hasChanges);
    this.submitTarget.classList.toggle('--primary', hasChanges);

    if (hasChanges != hadChanges) {
      this.switchTargets.forEach((target) => {
        if (hasChanges) {
          target.setAttribute('data-confirm', this.confirmDialogMessageValue);
          target.setAttribute('data-confirm-dialog', this.confirmDialogIdValue);
        } else {
          target.removeAttribute('data-confirm');
          target.removeAttribute('data-confirm-dialog');
        }
      });
    }
  }

  public onChange(): void {
    this.update();
  }
}
