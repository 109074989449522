import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';

/**

 */
export default class IntervallableFormController extends Controller {
  public static targets = [
    'reminderInterval',
    'reminderLeadTime',
    'reminderTitle',
    'reminderMessage',
  ];

  private declare reminderIntervalTarget: HTMLElement;
  private declare reminderLeadTimeTarget: HTMLElement;

  public constructor(context: Context) {
    super(context);
  }

  public connect(): void {
    toggleHidden(this.reminderLeadTimeTarget, !this.isRecurring);
  }

  public disconnect(): void {
    toggleHidden(this.reminderLeadTimeTarget, false);
  }

  public get isRecurring(): boolean {
    const input = this.reminderIntervalTarget.querySelector('select, input') as
      | HTMLSelectElement
      | HTMLInputElement;
    const currentValue = input.value;
    return currentValue !== 'PT0S' && currentValue !== '';
  }

  public onChangeInterval(e: Event): void {
    if (
      !(
        e.currentTarget instanceof HTMLInputElement ||
        e.currentTarget instanceof HTMLSelectElement
      )
    ) {
      return;
    }

    const nextValue = e.currentTarget.value;
    const isRecurring = nextValue !== 'PT0S' && nextValue !== '';

    toggleHidden(this.reminderLeadTimeTarget, !isRecurring);
  }
}
