import { Context, Controller } from '@hotwired/stimulus';
import { emit } from 'src/emitter';

/**
 * Enables keyboard input for audit questions
 */
export default class AuditFieldController extends Controller {
  private form: HTMLFormElement | undefined;

  private previousNumericValue: number | null;
  private previousValuePresence: boolean;

  public static targets = ['input'];

  public static values = {
    weight: Number,
  };

  private declare hasWeightValue: boolean;
  private declare weightValue: number;

  private declare inputTargets:
    | HTMLInputElement[]
    | NodeListOf<HTMLInputElement>;

  constructor(context: Context) {
    super(context);

    this.previousNumericValue = null;
    this.previousValuePresence = false;

    this.onKeyPress = this.onKeyPress.bind(this);
    this.onInput = this.onInput.bind(this);
  }

  public connect(): void {
    this.form = this.element.closest('form')!;
    this.element.addEventListener('keypress', this.onKeyPress);
    this.element.addEventListener('input', this.onInput);

    this.inputTargets.forEach((input) => {
      if (input.checked) {
        this.previousNumericValue = parseInt(input.value, 10);
        this.previousValuePresence = true;
      } else if (input.type !== 'radio') {
        this.previousValuePresence =
          this.previousValuePresence || !!input.value;
      }
    });
  }

  public disconnect(): void {
    this.element.removeEventListener('keypress', this.onKeyPress);
    this.element.removeEventListener('input', this.onInput);
  }

  private pick(value: number): void {
    const radio = this.element.querySelector<HTMLInputElement>(
      `input[value="${value}"]`,
    );
    if (!radio) {
      return;
    }

    radio.checked = true;

    // When the input presence changes, emit an event
    const hasValue = !!radio.value;
    if (this.previousValuePresence != hasValue) {
      emit('audit-field:input', { count: hasValue ? 1 : -1 });
      this.previousValuePresence = hasValue;
    }

    // When a new numeric value is selected
    const numericValue = parseInt(radio.value || '0', 10);

    if (numericValue !== this.previousNumericValue) {
      const previousScore =
        (this.previousNumericValue || 0) * this.weightValue;
      const score = numericValue * this.weightValue;

      const delta = score - previousScore;
      emit('audit-field:score', { delta });

      this.previousNumericValue = numericValue;
    }

    const nextElement =
      this.element.nextElementSibling ||
      this.element
        .closest('[data-controller="collapse"]')
        ?.nextElementSibling?.querySelector(
          '[data-controller="checklists--audit-field"]',
        );

    if (
      !nextElement ||
      nextElement.getAttribute('data-controller') !== 'checklists--audit-field'
    ) {
      return;
    }

    const nextRadio = nextElement.querySelector<HTMLInputElement>(
      'input[type="radio"]',
    );
    nextRadio?.focus();
  }

  private onKeyPress(event: Event): void {
    if (
      !(
        event instanceof KeyboardEvent &&
        !Object.prototype.hasOwnProperty.call(event, 'key')
      )
    ) {
      return;
    }

    const keyboardEvent = event as KeyboardEvent;

    if (!keyboardEvent.key) {
      return;
    }

    // If inside richt text area, ignore
    if (
      keyboardEvent.target instanceof HTMLElement &&
      keyboardEvent.target.closest(
        '[contenteditable="true"], [data-controller="tiptap"]',
      )
    ) {
      return;
    }

    switch (keyboardEvent.key) {
      case '-': {
        this.pick(-2);
        return;
      }
      case '0': {
        this.pick(0);
        return;
      }

      case '1': {
        this.pick(1);
        return;
      }

      case '2': {
        this.pick(2);
        return;
      }
    }
  }

  private onInput(event: Event): void {
    if (!(event.target instanceof HTMLInputElement) && !(event.target instanceof HTMLTextAreaElement)) {
      return;
    }

    // When the input presence changes, emit an event
    const hasValue = !!event.target.value;
    if (this.previousValuePresence != hasValue) {
      emit('audit-field:input', { count: hasValue ? 1 : -1 });
      this.previousValuePresence = hasValue;
    }

    // When a new numeric value is selected
    if (event.target instanceof HTMLInputElement && event.target.checked) {
      const numericValue = parseInt(event.target.value || '0', 10);

      if (numericValue !== this.previousNumericValue) {
        const previousScore =
          (this.previousNumericValue || 0) * this.weightValue;
        const score = numericValue * this.weightValue;

        const delta = score - previousScore;
        emit('audit-field:score', { delta });

        this.previousNumericValue = numericValue;
      }
    }
  }
}
