import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';

export default class AuthoringDateInputController extends Controller {
  public static targets = ['input', 'futureWarning', 'ancientError'];

  public static values = {
    'lead-time': Number,
  };

  private declare hasLeadTimeValue: boolean;
  private declare leadTimeValue: number;

  private declare inputTarget: HTMLInputElement;
  private declare futureWarningTarget: HTMLElement;
  private declare ancientErrorTarget: HTMLElement;

  public constructor(context: Context) {
    super(context);

    this.onDateChanged = this.onDateChanged.bind(this);
  }

  connect(): void {
    super.connect();

    this.inputTarget.addEventListener('change', this.onDateChanged);
  }

  disconnect(): void {
    super.disconnect();

    this.inputTarget.removeEventListener('change', this.onDateChanged);
  }

  private onDateChanged(_: Event) {
    const enteredDate = this.inputTarget.valueAsDate;

    if (enteredDate) {
      const difference = new Date().getTime() - enteredDate.getTime();

      // Use the lead time or 18 hours.
      const warningVisible =
        difference <
        (this.hasLeadTimeValue
          ? -1 * this.leadTimeValue * 1000 * 60 * 60 * 24
          : -1 * 1000 * 60 * 60 * 18);

      toggleHidden(this.futureWarningTarget, !warningVisible);

      // Use 100 years
      const errorVisible = difference > 1000 * 60 * 60 * 24 * 365 * 100;
      toggleHidden(this.ancientErrorTarget, !errorVisible);
    } else {
      toggleHidden(this.futureWarningTarget, true);
      toggleHidden(this.ancientErrorTarget, true);
    }
  }
}
