import { requestSubmit } from 'src/utils/form';
import { Context, Controller } from '@hotwired/stimulus';

/**
 * Enables the labels dialog for an individual todo
 */
export default class DsInputController extends Controller {
  public static values = {
    updateUrl: String,
    autocompleteEmployeeUrl: String,
    autocompleteSiteUrl: String,
  };

  private declare readonly updateUrlValue: string;
  private declare readonly autocompleteEmployeeUrlValue: string;
  private declare readonly autocompleteSiteUrlValue: string;

  private form!: HTMLFormElement;
  private inputs!: NodeListOf<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  private dirty = false;

  constructor(context: Context) {
    super(context);

    this.onKey = this.onKey.bind(this);
    this.onInput = this.onInput.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onUpdating = this.onUpdating.bind(this);
    this.onUpdated = this.onUpdated.bind(this);
    this.onUpdateFailed = this.onUpdateFailed.bind(this);
  }

  public connect(): void {
    this.inputs = this.element.querySelectorAll(
      'ds-input input, ds-input textarea, ds-input select',
    )!;

    if (this.inputs.length === 0) {
      return;
    }

    this.inputs.forEach((input) =>
      input.addEventListener('input', this.onInput),
    );
    this.inputs.forEach((input) => input.addEventListener('blur', this.onBlur));

    const target = this.element;

    // Ignore if already wrapped in form, e.g. if there are multiple inputs in
    // a message. In this case, all of them will submit the same form, so
    // that's fine.
    if (target.closest('form') || target.querySelector('form')) {
      return;
    }

    const methodOverride = document.createElement('input');
    methodOverride.type = 'hidden';
    methodOverride.name = '_method';
    methodOverride.value = 'PATCH';

    const submit = document.createElement('button');
    submit.type = 'submit';
    submit.classList.add(
      'sr-only',
      'focus:not-sr-only',
      'disabled:not-sr-only',
      'button',
      '--primary',
      'focus:p-2',
      'disabled:p-2',
      'focus:mb-2',
      'disabled:mb-2',
      'focus:ml-2',
      'disabled:ml-2',
      'focus:mt-2',
      'disabled:mt-2',
    );
    // submit.setAttribute('data-disable-with', 'Saving...');
    submit.textContent = 'Save changes';

    const authenticityToken = document.createElement('input');
    authenticityToken.type = 'hidden';
    authenticityToken.name =
      document.head.querySelector<HTMLMetaElement>('meta[name="csrf-param"]')
        ?.content || 'authenticity_token';
    authenticityToken.value = document.head.querySelector<HTMLMetaElement>(
      'meta[name="csrf-token"]',
    )?.content!;

    this.form = document.createElement('form');
    this.form.action = this.updateUrlValue;
    this.form.method = 'POST';
    this.form.setAttribute('data-remote', 'true');
    this.form.appendChild(methodOverride);
    this.form.appendChild(authenticityToken);

    // Move form into the DOM
    while (target.firstElementChild) {
      this.form.append(target.firstElementChild);
    }

    target.append(this.form);
    this.form.append(submit);

    // Setup events
    this.form.addEventListener('ajax:send', this.onUpdating);
    this.form.addEventListener(
      'ajax:success',
      this.onUpdated as (event: Event) => void,
    );
    this.form.addEventListener(
      'ajax:error',
      this.onUpdateFailed as (event: Event) => void,
    );

    // Upgrade elements
    this.inputs.forEach((input) => {
      const dsInput = input.closest('ds-input');
      const type = dsInput?.getAttribute('ds-type');

      const isEmployee = ['employee', 'employees'].includes(type || '');
      const isSite = ['site', 'sites'].includes(type || '');
      const isMultiple = ['employees', 'sites'].includes(type || '');

      if (dsInput && (isEmployee || isSite)) {
        const key = `todos--ds-input-combobox`;
        dsInput.setAttribute('data-controller', key);

        if (isEmployee) {
          dsInput.setAttribute(
            `data-${key}-autocomplete-url-value`,
            this.autocompleteEmployeeUrlValue,
          );
        }

        if (isSite) {
          dsInput.setAttribute(
            `data-${key}-autocomplete-url-value`,
            this.autocompleteSiteUrlValue,
          );
        }

        if (isMultiple) {
          dsInput.setAttribute(`data-${key}-multiple-value`, 'true');
        }
      }
    });
  }

  public disconnect(): void {
    this.inputs.forEach((input) => {
      const dsInput = input.closest('ds-input');
      const type = dsInput?.getAttribute('ds-type');
      if (['employee', 'employees', 'site', 'sites'].includes(type || '')) {
        const key = `todos--ds-input-combobox`;

        input.removeAttribute('data-controller');
        input.removeAttribute(`data-${key}-autocomplete-url-value`);
        input.removeAttribute(`data-${key}-multiple-value`);
      }
    });

    // ignore
  }

  get disabled(): boolean {
    return false;
  }

  public onUpdating(event: Event): void {
    console.debug('[ds-input] on updating');

    this.inputs.forEach((input) => {
      if (!input.hasAttribute('data-dirty')) {
        return;
      }

      input.removeAttribute('data-dirty');

      if (input.labels) {
        input.labels.forEach(this.activateSpinner);
      } else {
        this.activateSpinner(input.closest('label'));
      }
    });
  }

  public onUpdated(
    event: Event & { detail: [unknown, unknown, XMLHttpRequest] },
  ): void {
    console.debug('[ds-input] on updated (success)');

    const [nextDocument] = event.detail;

    const target = this.element.closest(
      '[data-target="todos--ds-input-list"], ol, ul',
    );

    this.inputs.forEach((input) => {
      if (input.labels) {
        input.labels.forEach(this.highlightSuccess);
      } else {
        this.highlightSuccess(input.closest('label'));
      }
    });

    if (target && nextDocument instanceof Document) {
      // Find all the input that are in the response, because if these are
      // generated and already present on the page, they should be updated.
      const nextValues: Record<string, string | null> = {};
      const nextInputs = nextDocument.querySelectorAll('ds-input');
      nextInputs.forEach((element) => {
        const nextInput = element.querySelector<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >('input, textarea, select');
        if (nextInput) {
          nextValues[nextInput.name] = nextInput.value;
        }
      });

      const currentInputs = target.querySelectorAll<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >('input, textarea, select, button');

      currentInputs.forEach((input) => {
        if (Object.prototype.hasOwnProperty.call(nextValues, input.name)) {
          if (input.type === 'radio' || input.type === 'checkbox') {
            input.toggleAttribute(
              'checked',
              input.value === nextValues[input.name],
            );
          } else {
            input.value = nextValues[input.name] || '';
          }
        }
      });

      console.debug('[ds-input] replaced inputs', nextValues);

      // Append the messages at the bottom of the page
      const count = nextDocument.body.children.length;
      let added = 0;
      let updated = 0;

      for (let i = 0; i < count; i++) {
        const child = nextDocument.body.children.item(i);

        if (!child) {
          continue;
        }

        if (!(child instanceof HTMLElement)) {
          continue;
        }

        const copy = child.cloneNode(true) as HTMLElement;

        if (child.firstElementChild?.id) {
          const existing = document.getElementById(child.firstElementChild.id);
          if (existing) {
            existing.replaceWith(copy.firstElementChild!);
            updated += 1;
            continue;
          }
        }

        target.appendChild(copy);
        added += 1;
      }

      console.debug('[ds-input] messages', { received: count, added, updated });
    }
  }

  public onUpdateFailed(
    event: Event & { detail: [unknown, unknown, XMLHttpRequest] },
  ) {
    console.debug('[ds-input] on updated (failed)');

    const [, response] = event.detail;

    console.error(response);

    this.inputs.forEach((input) => {
      if (input.labels) {
        input.labels.forEach(this.highlightError);
      } else {
        this.highlightError(input.closest('label'));
      }
    });
  }

  private onKey(e: KeyboardEvent): void {
    switch (e.key) {
      case 'Escape': {
        e.preventDefault();
        return;
      }

      case 'Tab': {
        e.preventDefault();
        return;
      }
    }
  }

  private onInput(event: Event) {
    const input = event.currentTarget as HTMLInputElement;

    // TODO: start debounce to live update
    switch (input.type) {
      case 'radio':
      case 'checkbox': {
        if (!this.dirty) {
          input.setAttribute('data-dirty', '');

          requestSubmit(
            input.form,
            input.form?.querySelector(
              'button[type="submit"]:not([formaction]):not([disabled]):not([hidden]), input[type="submit"]:not([formaction]):not([disabled]):not([hidden])',
            ),
          );
        }

        break;
      }
      default: {
        console.debug('[ds-input] dirty', input.type);
        this.dirty = true;

        input.setAttribute('data-dirty', '');
      }
    }

    console.debug('[ds-input] onInput', input, this, event);
  }

  private onBlur(event: Event) {
    const input = event.currentTarget as HTMLInputElement;

    if (this.dirty) {
      console.debug('[ds-input] was dirty', input.type);

      input.setAttribute('data-dirty', '');

      this.dirty = false;
      requestSubmit(
        input.form,
        input.form?.querySelector(
          'button[type="submit"]:not([formaction]):not([disabled]):not([hidden]), input[type="submit"]:not([formaction]):not([disabled]):not([hidden])',
        ),
      );
    }

    console.debug('[ds-input] onBlur', input, this);
  }

  private activateSpinner(label: HTMLLabelElement | null) {
    if (!label) {
      return;
    }

    label.closest('ds-input')?.classList.add('relative');

    const spinner = document.createElement('output');
    spinner.classList.add(
      'absolute',
      'right-0',
      'top-0',
      'transition',
      'opacity-0',
      'dark:text-gray-200',
      'text-gray-700',
    );

    spinner.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="animate-spin h-5 w-5 text-current opacity-80">
        <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
      </svg>
    `;

    requestAnimationFrame(() => {
      spinner.classList.remove('opacity-0');
      spinner.classList.add('opacity-100');
    });
    label.append(spinner);
  }

  private highlightSuccess(label: HTMLLabelElement | null) {
    if (!label) {
      return;
    }

    label.querySelectorAll('output.text-gray-700').forEach((output) => {
      output.classList.remove('dark:text-gray-200', 'text-gray-700');
      output.classList.add('dark:text-green-400', 'text-green-600');

      output.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-current">
          <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        </svg>
      `;

      setTimeout(() => {
        output.classList.add('opacity-0');
        output.classList.remove('opacity-100');
      }, 1200 - 300);

      setTimeout(() => output.remove(), 1200);
    });
  }

  private highlightError(label: HTMLLabelElement | null) {
    if (!label) {
      return;
    }

    label
      .querySelectorAll('output.text-gray-700, output.text-green-600')
      .forEach((output) => {
        output.classList.remove(
          'dark:text-gray-200',
          'text-gray-700',
          'dark:text-green-400',
          'text-green-600',
        );
        output.classList.add('dark:text-red-400', 'text-red-600');

        output.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-current">
          <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
        </svg>
      `;

        setTimeout(() => {
          output.classList.add('opacity-0');
          output.classList.remove('opacity-100');
        }, 1200 - 300);

        setTimeout(() => output.remove(), 1200);
      });
  }
}
