import { Context, Controller } from '@hotwired/stimulus';
import { emitter } from 'src/emitter';

export default class FormController extends Controller {
  public static targets = [];
  public static values = {
    'assigned-user': String,
    'current-user': String,
    'not-a-participant-dialog': String,
    participants: String,
  };

  private declare assignedUserValue: string;
  private declare currentUserValue: string;
  private declare participantsValue: string;
  private declare notAParticipantDialogValue: string;

  private declare hasNotAParticipantDialogValue: boolean;

  constructor(context: Context) {
    super(context);

    this.onAssigned = this.onAssigned.bind(this);
  }

  connect(): void {
    emitter.on('todos:assigned', this.onAssigned);
  }

  disconnect(): void {
    emitter.off('todos:assigned', this.onAssigned);
  }

  private onAssigned(params: unknown) {
    if (!params || typeof params !== 'object') {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(params, 'value')) {
      return;
    }

    const assigned = (params as any).value;
    this.assignedUserValue = assigned || '';

    if (this.hasNotAParticipantDialogValue) {
      if (this.assignedUserValue == this.currentUserValue) {
        this.element
          .querySelectorAll('button[type="submit"]')
          .forEach((button) => {
            if (
              button.getAttribute('data-confirm-dialog') ===
              this.notAParticipantDialogValue
            ) {
              button.removeAttribute('data-confirm');
              button.removeAttribute('data-confirm-dialog');
            }
          });
      } else {
        this.element
          .querySelectorAll('button[type="submit"]')
          .forEach((button) => {
            if (!button.hasAttribute('data-confirm-dialog')) {
              button.setAttribute('data-confirm', 'Are you sure?');
              button.setAttribute(
                'data-confirm-dialog',
                this.notAParticipantDialogValue,
              );
            }
          });
      }
    }
  }
}
