import { getAnchoredPosition, PositionSettings } from '@primer/behaviors';
import {
  toggleAttribute,
  toggleExpanded,
  toggleHidden,
} from 'src/utils/dom-toggle';
import { requestSubmit } from 'src/utils/form';
import { isReducedMotion } from 'src/utils/media-query';
import { Context, Controller } from '@hotwired/stimulus';

const DEBOUNCE_IN_MS = 300;

export default class ComboboxController extends Controller {
  public static values = {
    autocompleteUrl: String,
    multiple: Boolean,
    autoSubmit: Boolean,
    output: String,
    maxWidthClass: { type: String, default: 'sm:max-w-xs' },
  };

  private declare readonly hasAutocompleteUrlValue: boolean;
  private declare readonly hasAutoSubmitValue: boolean;
  private declare readonly hasMultipleValue: boolean;
  private declare readonly autocompleteUrlValue: string;
  private declare readonly autoSubmitValue: boolean;
  private declare readonly maxWidthClassValue: string;
  private declare readonly multipleValue: boolean;
  private declare readonly outputValue: string;

  private input!: HTMLInputElement;
  private entryInput!: HTMLInputElement;
  private wrapper!: HTMLDivElement;
  private options!: HTMLUListElement;
  private clearButton!: HTMLButtonElement;

  private autocompleteController: AbortController | undefined;
  private autocompleteDebounce: number | undefined;

  constructor(context: Context) {
    super(context);

    this.toggle = this.toggle.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.clear = this.clear.bind(this);

    this.onInput = this.onInput.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  get opened(): boolean {
    return this.entryInput.getAttribute('aria-expanded') === 'true';
  }

  set opened(next: boolean) {
    toggleExpanded(this.entryInput, next);
    toggleHidden(this.options, !next);

    if (next) {
      this.anchor();
    } else {
      this.activeDescendant = undefined;
    }
  }

  get selectedValue(): string {
    return this.input.value;
  }

  get selectedValues(): string[] {
    return this.selectedValue.split(' ').filter(Boolean);
  }

  set selectedValue(next: string) {
    this.input.value = next;

    if (this.outputElement) {
      this.outputElement.textContent = '';
      const nextOutput = this.selectedValues
        .map((value) =>
          this.options
            .querySelector(`[data-url-value="${value}"]`)
            ?.textContent?.trim(),
        )
        .filter(Boolean);

      if (nextOutput.length > 0) {
        this.outputElement.textContent = `(${nextOutput.join(', ')})`;
      }
    }
  }

  get multiple(): boolean {
    return this.hasMultipleValue && this.multipleValue;
  }

  get autoSubmit(): boolean {
    return this.hasAutoSubmitValue && this.autoSubmitValue;
  }

  get activeDescendant(): HTMLElement | undefined {
    const id = this.entryInput.getAttribute('aria-activedescendant');
    if (!id) {
      return undefined;
    }

    return document.getElementById(id) || undefined;
  }

  set activeDescendant(next: HTMLElement | undefined) {
    const prev = this.activeDescendant;

    if (prev) {
      prev.classList.add('text-gray-900');
      prev.classList.remove('text-white', 'bg-theme-primary-dark-500');
    }

    if (next) {
      next.classList.remove('text-gray-900');
      next.classList.add('text-white', 'bg-theme-primary-dark-500');

      this.entryInput.setAttribute('aria-activedescendant', next.id);

      next.scrollIntoView({
        behavior: isReducedMotion() ? 'auto' : 'smooth',
        block: 'nearest',
      });
    } else {
      this.entryInput.removeAttribute('aria-activedescendant');
    }
  }

  get outputElement(): HTMLElement | undefined {
    if (this.outputValue) {
      return document.getElementById(this.outputValue) || undefined;
    }

    return undefined;
  }

  public connect() {
    if (!(this.element instanceof HTMLInputElement)) {
      throw new Error(
        'Expected element to be <input>, actual: ' + this.element.tagName,
      );
    }

    this.input = this.element as HTMLInputElement;

    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('relative', 'mt-1', 'not-prose');
    this.wrapper.style.maxWidth = 'calc(100vw - 140px)';

    const inputWrapper = document.createElement('div');
    inputWrapper.classList.add('flex', 'flex-row');

    const comboxId = `combobox-${Math.random().toString(36)}`;
    this.entryInput = document.createElement('input');
    this.entryInput.setAttribute('id', comboxId);
    this.entryInput.setAttribute('type', 'text');
    this.entryInput.setAttribute('role', 'combobox');
    this.entryInput.setAttribute('aria-controls', `${comboxId}-options`);
    this.entryInput.setAttribute('placeholder', 'Start typing...');
    this.entryInput.classList.add('form-input', 'flex-shrink-0');
    // this.entryInput.addEventListener('focus', this.open);
    this.entryInput.addEventListener('input', this.onInput);
    this.entryInput.addEventListener('keypress', this.onKeyPress);
    this.entryInput.addEventListener('keydown', this.onKeyDown);
    // this.entryInput.addEventListener('blur', this.close);

    document.addEventListener('click', this.onClickOutside);

    toggleExpanded(this.entryInput, false);

    if (this.hasAutocompleteUrlValue) {
      this.entryInput.setAttribute('autocomplete', 'off');
    }

    const expandButton = document.createElement('button');
    expandButton.setAttribute('type', 'button');
    expandButton.setAttribute('aria-label', 'Show options');
    expandButton.classList.add(
      'absolute',
      'inset-y-0',
      'right-0',
      'flex',
      'items-center',
      'rounded-md',
      'px-2',
      'text-gray-400',
      'dark:text-gray-500',
      'focus:outline-none',
      'focus:text-gray-900',
      'dark:focus:text-white',
      'focus:ring',
      'focus:ring-theme-primary-light',
      'focus:ring-offset-white',
      'transition',
    );
    expandButton.innerHTML = `
      <svg class="h-5 w-5 text-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
      </svg>
    `;
    expandButton.addEventListener('keypress', this.onKeyPress);
    expandButton.addEventListener('keydown', this.onKeyDown);
    expandButton.addEventListener('click', this.toggle);

    this.clearButton = document.createElement('button');
    this.clearButton.setAttribute('type', 'button');
    this.clearButton.setAttribute('aria-label', 'Clear current answers');
    this.clearButton.classList.add('button', '--input');
    this.clearButton.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
      </svg>
    `;

    this.clearButton.addEventListener('click', this.clear);
    toggleAttribute('disabled', this.clearButton, this.selectedValue === '');

    this.options = document.createElement('ul');
    this.options.setAttribute('id', `${comboxId}-options`);
    this.options.setAttribute('role', 'listbox');
    this.options.style.marginTop = '0.25rem';
    this.options.classList.add(
      'absolute',
      'z-10',
      'mt-1',
      'max-h-60',
      'w-full',
      ...(this.maxWidthClassValue || 'sm:max-w-xs md:max-w-sm').split(' '),
      'sm:w-auto',
      'overflow-auto',
      'rounded-md',
      'bg-white',
      'py-1',
      'text-base',
      'shadow-lg',
      'ring-1',
      'ring-black/5',
      'focus:outline-none',
      'sm:text-sm',
      'not-prose',
    );
    this.options.addEventListener('click', this.onSelect);

    toggleHidden(this.options, true);

    inputWrapper.append(this.entryInput, expandButton, this.clearButton);
    this.wrapper.append(inputWrapper);

    // Add the combobox to the modal layer, if possible.
    const target =
      // this.element.closest('.overflow-hidden')?.parentElement ||
      // document.getElementById('modals') ||
      this.wrapper;
    target.append(this.options);

    this.input.after(this.wrapper);

    this.autocomplete('');
  }

  public disconnect(): void {
    clearTimeout(this.autocompleteDebounce);

    this.autocompleteController?.abort('ComboboxController#disconnect');
    this.options.remove();
    this.wrapper.remove();

    document.removeEventListener('click', this.onClickOutside);
  }

  private makeCheckMark() {
    const checkMark = document.createElement('span');
    checkMark.setAttribute('data-target', 'checkmark');
    checkMark.classList.add(
      'absolute',
      'inset-y-0',
      'right-0',
      'flex',
      'items-center',
      'pr-4',
      'text-current',
    );
    checkMark.innerHTML = `
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
      </svg>
    `;

    return checkMark;
  }

  private open() {
    this.opened = true;
  }

  private close() {
    this.opened = false;
  }

  private toggle() {
    this.opened = !this.opened;
  }

  private clear() {
    this.select('');
  }

  private onClickOutside(e: Event) {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    if (!this.opened) {
      return;
    }

    if (!this.wrapper.contains(e.target) && !this.options.contains(e.target)) {
      this.close();
    }
  }

  private onInput(e: Event) {
    if (!(e.currentTarget instanceof HTMLInputElement)) {
      return;
    }

    // Open if necessary
    if (!this.opened) {
      this.open();
    }

    const input = e.currentTarget;
    const query = input.value;

    this.filter(query);
  }

  private onKeyPress(e: KeyboardEvent) {
    if (!this.opened) {
      return;
    }

    switch (e.key) {
      case 'Enter': {
        e.preventDefault();
        const selected =
          this.activeDescendant ||
          this.options.querySelector('[data-filter-value]:not([hidden])');

        const selectedValue = selected?.getAttribute('data-url-value') || '';
        this.select(selectedValue);
        break;
      }

      case 'Tab': {
        this.close();
      }
    }
  }

  private previousDescendant() {
    if (this.activeDescendant) {
      const prevElement = this.activeDescendant.previousElementSibling;
      if (prevElement instanceof HTMLElement) {
        return prevElement;
      }
    }

    return (
      this.options.querySelector<HTMLElement>(
        '[data-filter-value]:not([hidden])',
      ) || undefined
    );
  }

  private nextDescendant() {
    if (this.activeDescendant) {
      const nextElement = this.activeDescendant.nextElementSibling;
      if (nextElement instanceof HTMLElement) {
        return nextElement;
      }
    }

    const options = this.options.querySelectorAll<HTMLElement>(
      '[data-filter-value]:not([hidden])',
    );
    return options.item(options.length - 1);
  }

  private onKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowUp': {
        e.preventDefault();

        this.open();
        this.activeDescendant = this.previousDescendant();
        break;
      }
      case 'ArrowDown': {
        e.preventDefault();

        this.open();
        this.activeDescendant = this.nextDescendant();
        break;
      }
      case 'Esc':
      case 'Escape': {
        this.close();
        break;
      }
      case 'Tab': {
        this.close();
        break;
      }
    }
  }

  private onMouseEnter(e: MouseEvent) {
    if (!(e.currentTarget instanceof HTMLElement)) {
      return;
    }

    this.activeDescendant = e.currentTarget;
  }

  private onMouseLeave(e: MouseEvent) {
    if (!(e.currentTarget instanceof HTMLElement)) {
      return;
    }

    this.activeDescendant = undefined;
  }

  private onSelect(e: Event) {
    if (!(e.target instanceof Element)) {
      return;
    }

    const selected = e.target.closest('[data-url-value]');
    if (!selected) {
      return;
    }

    e.stopPropagation();

    const selectedValue = selected.getAttribute('data-url-value') || '';
    this.select(selectedValue);
  }

  private select(selectedValue: string) {
    if (selectedValue === '') {
      this.selectedValue = '';
    } else {
      if (this.multiple) {
        if (this.selectedValues.includes(selectedValue)) {
          this.selectedValue = this.selectedValues
            .filter((v) => v !== selectedValue)
            .join(' ');
        } else {
          this.selectedValue = this.selectedValues
            .concat([selectedValue])
            .join(' ');
        }
      } else {
        if (this.selectedValue === selectedValue) {
          this.selectedValue = '';
        } else {
          this.selectedValue = selectedValue;
        }
      }
    }

    this.entryInput.value = '';
    toggleAttribute('disabled', this.clearButton, this.selectedValue === '');

    // Remove all checkmarks
    this.options
      .querySelectorAll('[data-target="checkmark"]')
      .forEach((current) => {
        current.remove();
      });

    // Add checkmarks
    this.options.querySelectorAll('[data-filter-value]').forEach((option) => {
      const urlValue = option.getAttribute('data-url-value');
      if (urlValue && this.selectedValues.includes(urlValue)) {
        option.append(this.makeCheckMark());
      }
    });

    if (!this.multiple) {
      this.close();
    }

    if (this.autoSubmit) {
      // Integrate with ds-input
      if (this.input.closest('[data-controller="todos--ds-input"]')) {
        this.input.setAttribute('data-dirty', '');
      }

      requestSubmit(this.input.form);
    }
  }

  private autocomplete(query: string) {
    if (!this.hasAutocompleteUrlValue) {
      return;
    }

    this.autocompleteController?.abort('Autocomplete query has changed');
    if ('AbortController' in window) {
      this.autocompleteController = new AbortController();
    }

    const expected = 'text/vnd.kaboom.combobox-options';

    fetch(this.autocompleteUrlValue.replace(/{q}|%7Bq%7D/, query), {
      method: 'GET',
      headers: {
        accept: `${expected}, application/problem+json; q=0.1`,
      },
      signal: this.autocompleteController?.signal,
    })
      .then((value) => {
        const contentType = value.headers.get('content-type') || '';

        if (contentType.startsWith(expected)) {
          return value.text();
        }

        if (contentType.includes(`variant=${expected}`)) {
          return value.text();
        }

        throw new Error(`Expected ${expected}, actual ${contentType}`);
      })
      .then((nextOptions) => {
        if (!this.element) {
          return;
        }

        if (!nextOptions) {
          console.debug('[comboxbox] nothing to show');
          throw new Error('Nothing to show');
        }

        const content = document.createElement('div');
        content.innerHTML = nextOptions;

        // Remove old content
        while (this.options.lastElementChild) {
          this.options.removeChild(this.options.lastElementChild);
        }

        // Add content
        while (content.lastElementChild) {
          const item = content.lastElementChild;
          item.id = [this.options.id, item.id].join('-');

          const value = item.getAttribute('data-url-value') || '';
          if (this.selectedValues.includes(value)) {
            item.append(this.makeCheckMark());
          }

          if (item instanceof HTMLElement) {
            item.addEventListener('mouseenter', this.onMouseEnter);
            item.addEventListener('mouseleave', this.onMouseLeave);
          }

          this.options.prepend(item);
        }

        toggleHidden(
          this.options,
          this.options.childElementCount === 0 || !this.opened,
        );

        this.anchor();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  private filter(query: string) {
    toggleHidden(this.options, !this.opened);

    const tokens = query.toLocaleLowerCase().split(' ');
    const selectors = tokens
      .filter(Boolean)
      .map((token) => `[data-filter-value*="${token.replace(/"/g, '\\"')}"]`);

    // Hide everything
    this.options.querySelectorAll('[data-filter-value]').forEach((option) => {
      toggleHidden(option, true);
    });

    // Now only show matching items
    let atLeastOne = false;
    if (selectors.length > 0) {
      this.options.querySelectorAll(selectors.join('')).forEach((option) => {
        toggleHidden(option, false);
        atLeastOne = true;
      });
    }
    toggleHidden(this.options, !atLeastOne || !this.opened);

    clearTimeout(this.autocompleteDebounce);
    this.autocompleteDebounce = setTimeout(
      () => this.autocomplete(query),
      DEBOUNCE_IN_MS,
    ) as any;

    this.anchor();
  }

  private anchor() {
    const settings = {
      side: 'outside-bottom',
      align: 'start',
      alignmentOffset: 0,
      anchorOffset: 8,
      allowOutOfBounds: false,
    } as Partial<PositionSettings>;

    const float = this.options;
    const anchor = this.entryInput;
    const { top, left } = getAnchoredPosition(float, anchor, settings);
    float.style.top = `${top}px`;
    float.style.left = `${left}px`;
    float.style.width = `calc(100vw - 92px)`;
  }
}
