import { mergeAttributes, Node } from '@tiptap/core';
import { createUploadFilePlugin, UploadFile } from './plugin_upload_file';

interface FileUploadOptions {
  HTMLAttributes: FileUploadAttributes;
}

export type FileUploadAttributes = {
  caption?: null | string;
  url?: null | string;
  sgid?: null | string;
  'data-trix-attachment'?: null | string;
  'data-trix-attributes'?: null | string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    'text/vnd.delftsolutions.ds-file-upload': {
      setFile: (options: FileUploadAttributes) => ReturnType;
    };
  }
}

export const DsFileUpload = (upload: UploadFile) => {
  return Node.create<FileUploadOptions, never>({
    name: 'text/vnd.delftsolutions.ds-file-upload',
    group: 'block',
    draggable: true,

    addAttributes() {
      return {
        url: {
          default: null,
          parseHTML: (node) => {
            const attr = node.getAttribute('data-trix-attachment');
            if (!attr) {
              return node.getAttribute('url') || null;
            }

            const decoded = JSON.parse(attr);
            return decoded['url'] || node.getAttribute('url');
          },
        },

        caption: {
          default: null,
          parseHTML: (node) => {
            const attr = node.getAttribute('data-trix-attachment');
            if (!attr) {
              return (
                node.getAttribute('caption') ||
                node.getAttribute('alt') ||
                node.getAttribute('title') ||
                null
              );
            }

            const decoded = JSON.parse(attr);
            return decoded['caption'];
          },
        },

        sgid: {
          default: null,
          parseHTML: (node) => {
            const attr = node.getAttribute('data-trix-attachment');
            if (!attr) {
              return null;
            }

            const decoded = JSON.parse(attr);
            return decoded['sgid'];
          },
        },

        'data-trix-attachment': {
          default: null,
        },

        'data-trix-attributes': {
          default: null,
        },
      };
    },

    renderHTML: ({ HTMLAttributes }) => [
      'figure',
      mergeAttributes({
        class: 'border border-dotted font-mono p-3 rounded-sm leading-5',
        'data-trix-attachment': JSON.stringify({
          sgid: HTMLAttributes['sgid'],
          url: HTMLAttributes['url'],
        }),
      }),

      [
        'figcaption',
        {},
        HTMLAttributes['caption'] || HTMLAttributes['title'] || '',
      ],
    ],

    addCommands() {
      return {
        setFile:
          (attrs) =>
          ({ state, dispatch }) => {
            const { selection } = state;
            const position = selection.$head
              ? selection.$head.pos
              : selection.$to.pos;

            const node = this.type.create(attrs);
            const transaction = state.tr.insert(position, node);
            return dispatch?.(transaction);
          },
      };
    },

    addProseMirrorPlugins() {
      return [createUploadFilePlugin(upload)];
    },
  });
};
