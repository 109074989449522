import { KEY_NAMES } from 'src/aria';
import { Context, Controller } from '@hotwired/stimulus';

/**
 * Allows annotating a link with a "marking" url, which is trigger on regular
 * clicks, including keyboard events. Marking is ignored on "open in new tab" or
 * middle click.
 *
 * @see /app/views/connect/notifications/_notification.html.erb
 *
 * [data-controller="markable"] makes a container markable. It is expected to
 * also be focusable to make it interactive, using [tabindex="0"], or contain
 * a focusable element that is the target of marking.
 *
 * [data-markable-target="follow"] determines which link to follow after marking
 *  has been achieved.
 *
 * [data-markable-target="ignore"] can be used to opt-out of all the behaviour.
 *   This can be used to annotate a form or link that doesn't hit the marking
 *   href and doesn't then navigate to the target url.
 *
 * [data-markable-href-value] is an optional attribute with a href to POST to
 *   when the element is interacted with:
 *
 * - If a [data-markable-href-value] is present, upon clicking (mouse/keyboard)
 *     on any link inside the markable element, or pressing Return on the
 *     keyboard on the normally non-interactive markable element, the href will
 *     be POSTed to and then the original link will be followed.
 * - If a [data-markable-href-value] is empty or not present, the link will be
 *     followed, including when a keyboard user presses Return on the markable
 *     element.
 *
 * @example
 *
 * <article
 *    tabindex="0"
 *    data-controller="markable"
 *    data-markable-href-value="https://example.org/posts/1/mark-as-read"
 * >
 *   <a href="https://example.org/posts/1" data-markable-target="follow">...</a>
 *
 *   <form action="https://example.org/posts/1/mark-as-read"
 *         method="POST"
 *         data-remote="true"
 *         data-markable-target="ignore">
 *      <button type="submit">Mark as read</button>
 *   </form>
 * </article>
 *
 */
export default class MarkableController extends Controller {
  private abortController!: AbortController;

  public static values = {
    href: String,
  };

  public static targets = ['follow', 'ignore'];

  private declare hasHrefValue: boolean;
  private declare hrefValue: string;

  private declare followTarget: HTMLAnchorElement | HTMLButtonElement;
  private declare ignoreTargets: NodeListOf<
    HTMLAnchorElement | HTMLButtonElement
  >;

  constructor(context: Context) {
    super(context);

    this.follow = this.follow.bind(this);
    this.gotoTargetUrl = this.gotoTargetUrl.bind(this);

    this.onFollow = this.onFollow.bind(this);
    this.onKeyboardFollow = this.onKeyboardFollow.bind(this);
  }

  public connect(): void {
    if (this.element instanceof HTMLElement) {
      this.element.addEventListener('click', this.onFollow);
      this.element.addEventListener('keypress', this.onKeyboardFollow);
    }

    this.abortController = new AbortController();
  }

  public disconnect(): void {
    if (this.element instanceof HTMLElement) {
      this.element.removeEventListener('click', this.onFollow);
      this.element.removeEventListener('keypress', this.onKeyboardFollow);
    }

    this.abortController.abort('MarkableController#disconnect');
  }

  public follow(): void {
    if (!this.markUrl) {
      // Click the node
      this.gotoTargetUrl();
      return;
    }

    const csrfToken = document.querySelector<HTMLMetaElement>(
      "meta[name='csrf-token']",
    )?.content;

    if (!csrfToken) {
      // without a token, the POST will fail, so just continue without marking
      this.gotoTargetUrl();
      return;
    }

    fetch(this.markUrl, {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        accept: 'application/javascript',
      },
    })
      .then((response) => response.text())
      .then((javascript) => {
        const metaTag = document.querySelector<HTMLMetaElement>(
          'meta[name=csp-nonce]',
        );
        const nonce = metaTag?.content;
        const script = document.createElement('script');

        if (nonce) {
          script.setAttribute('nonce', nonce);
        }

        script.text = javascript;
        document.head.appendChild(script).parentNode!.removeChild(script);

        this.gotoTargetUrl();
      })
      .catch((err: unknown) => {
        console.warn(err);
        this.gotoTargetUrl();
      });

    // Fetch has started, but remove mark url. This allows a user to click
    // twice to go directly. They're likely to ignore the first click. Good for
    // flaky connections.
    this.element.removeAttribute('data-markable-href-value');
  }

  private get markUrl(): string | null {
    if (!this.hasHrefValue) {
      return null;
    }

    return this.hrefValue;
  }

  private onKeyboardFollow(e: KeyboardEvent): void {
    // Remove this event if keyboard clicks should also NOT trigger a
    // marking. Currently keyboard user _can_ follow the notification by
    // clicking on it using the keyboard.

    if (e.key !== KEY_NAMES.RETURN) {
      return;
    }

    // Only trigger the keyboard follow if it's on the "root" [data-controller="markable"]
    // that has [tabindex].
    if (e.target instanceof HTMLElement) {
      if (e.target !== this.element) {
        return;
      }
    }

    e.preventDefault();
    this.follow();
  }

  private onFollow(e: MouseEvent): void {
    // Opt-out of marking
    if (e.target instanceof HTMLElement) {
      const target = e.target;
      let found = false;

      this.ignoreTargets.forEach((ignore) => {
        found = found || ignore === target || ignore.contains(target);
      });

      if (found) {
        return;
      }
    }

    if (
      e.target instanceof HTMLElement &&
      !e.target.closest('a') &&
      !e.target.closest('button')
    ) {
      return;
    }

    e.preventDefault();

    // Disable data-remote, or anything else
    e.stopPropagation();

    this.follow();
  }

  private gotoTargetUrl(): void {
    const target = this.followTarget;

    if (target?.tagName === 'A') {
      try {
        const safeLink = document.createElement('a');
        safeLink.href = (target as HTMLAnchorElement).href;
        safeLink.click();
      } catch {
        // security error for example
        document.location.href = (target as HTMLAnchorElement).href;
      }
    } else {
      target?.click();
    }
  }
}
