import { emitter } from 'src/emitter';
import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';

export default class Listenable extends Controller {
  public static values = {
    eventName: String,
    key: String,
    data: String,
    action: String,
    actionContent: String,
  };

  public static targets = ['subject'];

  private declare hasKeyValue: boolean;
  private declare hasDataValue: boolean;
  private declare hasEventActionValue: boolean;

  private declare hasSubjectTarget: boolean;

  private declare eventNameValue: string;
  private declare keyValue: string;
  private declare dataValue: string;
  private declare actionValue: string;
  private declare actionContentValue: string;

  private declare subjectTarget: Element;

  public constructor(context: Context) {
    super(context);

    this.onMessage = this.onMessage.bind(this);
  }

  connect(): void {
    emitter.on(this.eventNameValue, this.onMessage);
  }

  disconnect(): void {
    emitter.off(this.eventNameValue, this.onMessage);
  }

  private onMessage(event: unknown) {
    if (this.hasKeyValue) {
      // Must be an object
      if (typeof event !== 'object' || !event) {
        return;
      }

      // Missing the requested key
      if (!Object.prototype.hasOwnProperty.call(event, this.keyValue)) {
        return;
      }

      // Not matching requested value
      if (this.hasDataValue) {
        const value = (event as Record<string, unknown>)[this.keyValue];
        if (value !== this.dataValue) {
          return;
        }
      }
    } else if (this.hasDataValue) {
      if (event !== this.dataValue) {
        return;
      }
    }

    this.handle(event);
  }

  private handle(_: unknown) {
    const target = this.hasSubjectTarget ? this.subjectTarget : this.element;

    switch (this.actionValue) {
      case 'remove': {
        this.element.remove();
        break;
      }

      case 'hide': {
        toggleHidden(target, true);
        break;
      }

      case 'show': {
        toggleHidden(target, false);
        break;
      }

      case 'add-class': {
        target.classList.add(...this.actionContentValue.split(' '));
        break;
      }

      case 'remove-class': {
        target.classList.remove(...this.actionContentValue.split(' '));
        break;
      }
    }
  }
}
