import { Context, Controller } from '@hotwired/stimulus';

/**
 * Enables the labels dialog for an individual todo
 */
export default class DsInputComboboxController extends Controller {
  public static values = {
    autocompleteUrl: String,
    multiple: Boolean,
  };

  private declare readonly hasAutocompleteUrlValue: boolean;
  private declare readonly hasMultipleValue: boolean;
  private declare readonly autocompleteUrlValue: string;
  private declare readonly multipleValue: boolean;

  private form!: HTMLFormElement;
  private input!: HTMLInputElement;
  private output: HTMLElement | undefined;

  private dsLabel!: string;
  private dsName!: string;
  private dsInitialValue!: string;
  private initialType!: string;

  get value(): string {
    return this.input.value;
  }

  set value(next: string) {
    this.input.value = next;
  }

  public connect(): void {
    this.input = this.element.querySelector('input, select')!;
    this.output =
      this.element.querySelector<HTMLElement>('[data-target="current"]') ||
      undefined;

    this.form = this.input.form!;

    this.dsLabel = this.element.getAttribute('ds-label')!;
    this.dsName = this.element.getAttribute('ds-name')!;
    this.dsInitialValue = this.element.getAttribute('ds-value')!;

    this.initialType = this.input.type;

    if (this.output && !this.output.id) {
      this.output.id = 'ds-input-output-' + Math.random().toString(36);
    }

    // this.input.classList.add('sr-only');
    // this.input.setAttribute('aria-hidden', 'true');
    // this.input.setAttribute('tabindex', '-1');
    this.input.setAttribute('type', 'hidden');
    this.input.setAttribute('data-controller', 'combobox');
    this.input.setAttribute('data-combobox-auto-submit-value', 'true');

    if (this.output?.id) {
      this.input.setAttribute('data-combobox-output-value', this.output.id);
    }

    if (this.hasAutocompleteUrlValue) {
      this.input.setAttribute(
        'data-combobox-autocomplete-url-value',
        this.autocompleteUrlValue,
      );
    }

    if (this.hasMultipleValue) {
      this.input.setAttribute(
        'data-combobox-multiple-value',
        String(this.multipleValue),
      );
    }
  }

  public disconnect(): void {
    // this.input.classList.remove('sr-only');
    // this.input.removeAttribute('aria-hidden');
    // this.input.removeAttribute('tabindex');
    this.input.setAttribute('type', this.initialType);
    this.input.removeAttribute('data-controller');
    this.input.removeAttribute('data-combobox-autocomplete-url-value');
    this.input.removeAttribute('data-combobox-auto-submit-value');
    this.input.removeAttribute('data-combobox-output-value');
    this.input.removeAttribute('data-combobox-multiple-value');
  }
}
