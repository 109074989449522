import { toggleHidden } from 'src/utils/dom-toggle';
import { Controller } from '@hotwired/stimulus';

/**
 * Allows copying some content, if the clipboard API is enabled.
 *
 * This controller automatically removes the hidden attribute if the clipboard
 * is enabled, and leaves it in place if there is no clipboard API, or if
 * JavaScript is disabled.
 *
 * @example The HTML for a minimal example
 *
 * <button
 *   hidden
 *   data-controller="copy"
 *   data-copy-target="trigger"
 *   data-copy-clipboard-value="the-value-to-copy"
 *   data-action="copy#onCopy"
 *   type="button"
 * >
 *  Label
 * </button>
 */
export default class CopyController extends Controller {
  public static targets = ['trigger'];
  public static values = {
    clipboard: String,
  };

  private declare triggerTarget: HTMLButtonElement;
  private declare hasTriggerTarget: boolean;
  private declare clipboardValue: string;

  public connect(): void {
    // Show element if copying is possible
    if (
      navigator &&
      'clipboard' in navigator &&
      typeof navigator.clipboard.writeText === 'function'
    ) {
      toggleHidden(this.element, false);

      if (this.hasTriggerTarget) {
        toggleHidden(this.triggerTarget, false);
      }
    }
  }

  public disconnect(): void {
    toggleHidden(this.element, true);

    if (this.hasTriggerTarget) {
      toggleHidden(this.triggerTarget, true);
    }
  }

  public onCopy(): void {
    navigator.clipboard.writeText(this.clipboardValue);
  }
}
