import { mergeAttributes, Node } from '@tiptap/core';

export const ActionTextLabel = Node.create({
  name: 'vnd.kaboom.actiontext-attachment.label',
  group: 'block',
  atom: true,
  content: 'inline*',

  renderHTML({ HTMLAttributes }) {
    return ['action-text-attachment', mergeAttributes(HTMLAttributes), 0];
  },

  parseHTML() {
    return [
      {
        tag: 'action-text-attachment[content-type="text/vnd.kaboom.actiontext-attachment.label.html"]',
      },
    ];
  },

  addAttributes() {
    return {
      sgid: {
        default: null,
      },

      'content-type': {
        default: null,
      },

      caption: {
        default: null,
      },

      class: {
        parseHTML: (element) =>
          element.querySelector('span')!.getAttribute('class'),
      },

      contenteditable: {
        default: false,
      },
    };
  },
});
