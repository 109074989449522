import { Controller } from '@hotwired/stimulus';
import { toggleExpanded, toggleHidden } from 'src/utils/dom-toggle';

export default class NavbarController extends Controller {
  public static targets = [
    'iconOpen',
    'iconClosed',
    'menu',
    'toggle',
  ];

  private declare iconOpenTarget: HTMLElement;
  private declare iconClosedTarget: HTMLElement;
  private declare menuTarget: HTMLElement;
  private declare toggleTarget: HTMLElement;

  public get opened(): boolean {
    return this.toggleTarget.getAttribute('aria-expanded') === 'true';
  }

  public set opened(next: boolean) {
    toggleExpanded(this.toggleTarget, next);

    toggleHidden(this.iconOpenTarget, !next);
    toggleHidden(this.iconClosedTarget, next);
    toggleHidden(this.menuTarget, !next);
  }

  public onToggle(e: MouseEvent): void {
    if (e.currentTarget !== this.toggleTarget) {
      return;
    }

    this.opened = !this.opened;
  }
}
