import { Context, Controller } from '@hotwired/stimulus';
import { emitter } from 'src/emitter';

type AuditFieldInputEventParams = {
  count: number;
};

type AuditFieldScoreEventParams = {
  delta: number;
};

export default class AuditFormController extends Controller {
  public static targets = [
    'progress',
    'progressBar',
    'progressLabel',
    'score',
    'scoreMax',
    'scorePercentage',
    'style',
  ];

  public static values = {
    score: Number,
    'score-max': Number,
    'question-count': Number,
    'question-progress': Number
  };

  private declare scoreValue: number;
  private declare scoreMaxValue: number;
  private declare questionProgressValue: number;
  private declare questionCountValue: number;

  private declare progressBarTarget: HTMLProgressElement;
  private declare progressLabelTarget: HTMLElement;
  private declare scoreTarget: HTMLElement;
  private declare scorePercentageTarget: HTMLElement;

  constructor(context: Context) {
    super(context);

    this.onAuditFieldInput = this.onAuditFieldInput.bind(this);
    this.onAuditFieldScore = this.onAuditFieldScore.bind(this);
  }

  connect(): void {
    emitter.on('audit-field:input', this.onAuditFieldInput);
    emitter.on('audit-field:score', this.onAuditFieldScore);
  }

  disconnect(): void {
    emitter.off('audit-field:input', this.onAuditFieldInput);
    emitter.off('audit-field:score', this.onAuditFieldScore);
  }

  private set progress(value: number) {
    this.questionProgressValue = value;
    this.progressBarTarget.value = value;
    this.progressLabelTarget.textContent = `${value}`

    // Update CSS variable
    const element = document.querySelector(':root')
    if (element instanceof HTMLElement) {
      element.style.setProperty('--audit-progress', value.toString());
    }
  }

  private set score(value: number) {
    this.scoreValue = value;
    this.scoreTarget.textContent = `${value}`;
    this.scorePercentageTarget.textContent = `${Math.round(value / this.scoreMaxValue * 1000) / 10}%`
  }

  private onAuditFieldInput(params: unknown) {
    if (!params || typeof params !== 'object') {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(params, 'count')) {
      return;
    }

    const count = (params as AuditFieldInputEventParams).count;
    this.progress = this.questionProgressValue + count;
  }

  private onAuditFieldScore(params: unknown) {
    if (!params || typeof params !== 'object') {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(params, 'delta')) {
      return;
    }

    const delta = (params as AuditFieldScoreEventParams).delta;
    this.score = this.scoreValue + delta;
  }
}
