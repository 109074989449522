// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import RailsUjs from '@rails/ujs';
import { start } from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';

import '../src/aria';
import '../src/confirm';
import '../src/dialog';
import '../src/emitter';
import '../src/stimulus';

RailsUjs.start();
start();
ActiveStorage.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// @ts-ignore
// eslint-disable-next-line no-undef
require.context('../images', true);
// const imagePath = (name) => images(name, true)

/**
 * Outfit Semibold is the typeface used to highlight headers in body copy, with
 * the Regular weight used for introduction paragraphs.
 *
 * @fontsource/outfit
 *
 * - Weights: `[100,200,300,400,500,600,700,800,900]`
 * - Styles: `[normal]`
 * - Supported subsets: `[latin]`
 *
 * ```css
 * body {
 *   font-family: "Outfit";
 * }
 */
import '@fontsource-variable/outfit';

/**
 * Our chosen font pairing for body copy is Rubik.
 * @fontsource/rubik
 *
 * - Weights: `[300,400,500,600,700,800,900]`
 * - Styles: `[italic,normal]`
 * - Supported subsets: `[cyrillic,cyrillic-ext,hebrew,latin,latin-ext]`
 *
 * ```css
 * body {
 *   font-family: "Rubik";
 * }
 */
import '@fontsource-variable/rubik';
import '@fontsource-variable/rubik/wght-italic.css';

import '../stylesheets/application.scss';
