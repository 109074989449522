import { focusTrap } from '@primer/behaviors';
import { toggleExpanded, toggleHidden } from 'src/utils/dom-toggle';
import { Controller } from '@hotwired/stimulus';

export default class OffCanvasBarController extends Controller {
  public static targets = ['dialog', 'backdrop', 'content', 'trigger'];

  private declare dialogTarget: HTMLElement;
  private declare backdropTarget: HTMLElement;
  private declare contentTarget: HTMLElement;
  private declare triggerTarget: HTMLElement;

  private focusController: AbortController | undefined;

  public connect(): void {
    // Make sure it's closed
    toggleExpanded(this.triggerTarget, false);
    toggleHidden(this.dialogTarget, true);

    // Make sure animations are set-up
    this.backdropTarget.classList.add('opacity-0');
    this.backdropTarget.classList.remove('opacity-100');
    this.contentTarget.classList.add('translate-x-full');
    this.contentTarget.classList.remove('translate-x-0');
  }

  public disconnect(): void {
    toggleExpanded(this.triggerTarget, false);
    toggleHidden(this.dialogTarget, true);
  }

  public get opened(): boolean {
    return this.triggerTarget.getAttribute('aria-expanded') === 'true';
  }

  public set opened(next: boolean) {
    toggleExpanded(this.triggerTarget, next);

    if (next) {
      toggleHidden(this.dialogTarget, false);

      this.focusController = focusTrap(
        this.contentTarget,
        this.triggerTarget.querySelector('button') || undefined,
      );
    } else {
      setTimeout(() => {
        if (this.opened) {
          return;
        }
        toggleHidden(this.dialogTarget, true);
      }, 300);

      // Focus the menu button
      this.focusController?.abort('Off Canvas Bar is closed');
      this.triggerTarget.focus();
    }

    requestAnimationFrame(() => {
      if (!this.triggerTarget) {
        return;
      }

      this.backdropTarget.classList.toggle('opacity-0', !next);
      this.backdropTarget.classList.toggle('opacity-100', next);

      this.contentTarget.classList.toggle('translate-x-full', !next);
      this.contentTarget.classList.toggle('translate-x-0', next);

      document.body.classList.toggle('overflow-hidden', next);
      document.body.classList.toggle('lg:overflow-auto', next);
    });
  }

  public onOpen(): void {
    this.opened = true;
  }

  public onClose(): void {
    this.opened = false;
  }

  public onBackdrop(): void {
    this.opened = false;
  }
}
