import { toggleAttribute, toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';

export default class OfflineContentController extends Controller {
  static targets = ['offline', 'online'];

  static values = {
    onlineAttribute: String,
    offlineAttribute: String,
  };

  declare offlineTargets: Array<HTMLElement>;
  declare onlineTargets: Array<HTMLElement>;

  declare hasOfflineAttributeValue: boolean;
  declare hasOnlineAttributeValue: boolean;
  declare offlineAttributeValue: string;
  declare onlineAttributeValue: string;

  constructor(context: Context) {
    super(context);

    this.onChangeState = this.onChangeState.bind(this);
  }

  private get state(): 'online' | 'offline' {
    let attr = this.element.getAttribute('data-net-state');

    if (attr !== 'online' && attr !== 'offline') {
      attr = null;
    }

    return (attr ?? navigator.onLine ? 'online' : 'offline') satisfies
      | 'online'
      | 'offline';
  }

  private set state(next: 'online' | 'offline') {
    this.element.setAttribute('data-net-state', next);

    this.onlineTargets.forEach((target) => {
      toggleHidden(target, next === 'offline');
    });

    this.offlineTargets.forEach((target) => {
      toggleHidden(target, next === 'online');
    });

    if (this.hasOfflineAttributeValue) {
      const attributes = JSON.parse(this.offlineAttributeValue);

      Object.keys(attributes).forEach((attribute) => {
        toggleAttribute(attribute, this.element, next === 'offline', [
          attributes[attribute],
          undefined,
        ]);
      });
    }

    if (this.hasOnlineAttributeValue) {
      const attributes = JSON.parse(this.onlineAttributeValue);

      Object.keys(attributes).forEach((attribute) => {
        toggleAttribute(attribute, this.element, next === 'online', [
          attributes[attribute],
          undefined,
        ]);
      });
    }
  }

  connect(): void {
    window.addEventListener('online', this.onChangeState);
    window.addEventListener('offline', this.onChangeState);

    this.state = navigator.onLine ? 'online' : 'offline';
  }

  disconnect(): void {
    window.removeEventListener('online', this.onChangeState);
    window.removeEventListener('offline', this.onChangeState);
  }

  private onChangeState(event: Event) {
    this.state = event.type as 'online' | 'offline';
  }
}
