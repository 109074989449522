import { toggleHidden } from 'src/utils/dom-toggle';
import { Controller } from '@hotwired/stimulus';

/**
 * Allows a button to open the print dialog, if this feature is enabled.
 *
 * This controller automatically removes the hidden attribute if printing
 * is enabled, and leaves it in place if there is no printing API, or if
 * JavaScript is disabled.
 *
 * You can use the print: modifier to change the CSS on the page when printed.
 *
 * @example The HTML for a minimal example
 *
 * <button
 *   hidden
 *   data-controller="print"
 *   data-print-target="trigger"
 *   data-action="print#onPrint"
 *   type="button"
 *   class="button --primary print:hidden whitespace-nowrap">
 *   <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 -ml-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
 *     <path stroke-linecap="round" stroke-linejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
 *   </svg>
 *   <span>
 *     Print
 *   </span>
 * </button>
 */
export default class PrintController extends Controller {
  static targets = ['trigger'];

  private declare triggerTarget: HTMLButtonElement;
  private declare hasTriggerTarget: boolean;

  public connect(): void {
    // Show element if printing is possible
    if (window && 'print' in window && typeof window.print === 'function') {
      toggleHidden(this.element, false);

      if (this.hasTriggerTarget) {
        toggleHidden(this.triggerTarget, false);
      }
    }
  }

  public disconnect(): void {
    toggleHidden(this.element, true);

    if (this.hasTriggerTarget) {
      toggleHidden(this.triggerTarget, true);
    }
  }

  public onPrint(): void {
    window.print();
  }
}
