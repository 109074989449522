import { DirectUpload } from '@rails/activestorage';

type DirectUploadAttributes = Parameters<
  Parameters<InstanceType<typeof DirectUpload>['create']>[0]
>[1];

export type AttachmentAttributes = {
  sgid: string;
  url: string;
  caption?: string;
};

export interface AttachmentWithCallbacks {
  file?: File;

  setUploadProgress(
    progress: number,
    indeterminate: boolean,
    loaded: number,
    total: number
  ): void;
  setAttributes(attributes: AttachmentAttributes): void;
}

export class AttachmentUpload {
  private directUpload: InstanceType<typeof DirectUpload>;

  constructor(
    private readonly attachment: AttachmentWithCallbacks,
    private readonly directUploadUrl: string,
    private readonly blobUrlTemplate: string,
    private readonly representationUrlTemplate: string
  ) {
    this.attachment = attachment;
    this.directUpload = new DirectUpload(
      attachment.file,
      this.directUploadUrl,
      this
    );
  }

  public start(): Promise<AttachmentAttributes> {
    const callback = this.directUploadDidComplete.bind(this);

    return new Promise((resolve, reject) => {
      this.directUpload.create((error, attributes) => {
        if (error) {
          reject(error);
          return;
        }


        callback(error, attributes);
        resolve({
          sgid: attributes.attachable_sgid,
          url: this.createBlobUrl(attributes.signed_id, attributes.filename),
          caption: attributes.filename,
        });
      });
    });
  }

  directUploadWillStoreFileWithXHR(xhr: XMLHttpRequest) {
    xhr.upload.addEventListener('progress', (event) => {
      const progress = event.lengthComputable
        ? (event.loaded / event.total) * 100
        : 0;
      this.attachment.setUploadProgress(
        progress,
        !event.lengthComputable,
        event.loaded,
        event.total
      );
    });

    xhr.upload.addEventListener('loadend', (event) => {
      this.attachment.setUploadProgress(
        100,
        event.lengthComputable,
        event.loaded,
        event.total
      );
    });
  }

  private directUploadDidComplete(
    error: undefined | Error,
    attributes: DirectUploadAttributes
  ) {
    if (error) {
      throw new Error(`Direct upload failed: ${error}`);
    }

    this.attachment.setAttributes({
      sgid: attributes.attachable_sgid,
      url: this.createBlobUrl(attributes.signed_id, attributes.filename),
      caption: attributes.filename,
    });
  }

  private createBlobUrl(signedId: string, filename: string) {
    return (this.representationUrlTemplate || this.blobUrlTemplate)
      .replace(':signed_id', signedId)
      .replace(/:filename|%3Afilename/, encodeURIComponent(filename));
  }
}
