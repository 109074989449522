import { Context, Controller } from '@hotwired/stimulus';
import { slugify } from 'src/slugify';

export default class PathSuggestionController extends Controller {
  static targets = ['input', 'datalist'];

  public static values = {
    titleInput: String,
  };

  private declare readonly titleInputValue: string;

  private declare inputTarget: HTMLInputElement;
  private declare datalistTarget: HTMLDataListElement;

  public constructor(context: Context) {
    super(context);

    this.onInputChanged = this.onInputChanged.bind(this);
  }

  public connect(): void {
    this.source.addEventListener('input', this.onInputChanged);

    this.datalistTarget.querySelectorAll('option').forEach((option) => {
      option.setAttribute(
        'data-document--path-suggestion-template-value',
        option.value,
      );
    });

    this.updateTemplatedValue(this.sourceValue);
  }

  public disconnect(): void {
    this.source.removeEventListener('input', this.onInputChanged);

    // Restore datalist
    this.datalistTarget.querySelectorAll('option').forEach((option) => {
      option.value =
        option.getAttribute('data-document--path-suggestion-template-value') ||
        '';
      option.removeAttribute('data-document--path-suggestion-template-value');
    });
  }

  private set value(next: string) {
    this.datalistTarget.querySelectorAll('option').forEach((option) => {
      option.value = option
        .getAttribute('data-document--path-suggestion-template-value')!
        .replace('file', next);
    });
  }

  private get sourceValue(): string {
    return this.source.value;
  }

  private get source(): HTMLInputElement {
    const form = this.element.closest('form')!;
    return form.querySelector<HTMLInputElement>(`#${this.titleInputValue}`)!;
  }

  public onInputChanged(event: Event): void {
    const input = event.currentTarget as HTMLInputElement;
    const newValue = input.value;

    this.updateTemplatedValue(newValue);
  }

  private updateTemplatedValue(newValue: string): void {
    this.value = slugify(newValue, { lower: true });
  }
}
