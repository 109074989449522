import { requestSubmit } from 'src/utils/form';
import { Controller } from '@hotwired/stimulus';

export default class FiltersController extends Controller {
  public static targets = ['form'];

  private declare formTarget: HTMLFormElement;
  private timeout: any | undefined;

  public connect(): void {}

  public disconnect(): void {}

  public onChange(e: Event) {
    clearTimeout(this.timeout);

    if (e.target instanceof HTMLElement) {
      let button: HTMLButtonElement | null = null;
      let parent = e.target.parentElement;
      while (!button && parent && parent !== this.element) {
        button = parent.querySelector<HTMLButtonElement>(
          'button[type="submit"]:not(.sr-only), button:not([type]):not(.sr-only), input[type="submit"]:not(.sr-only)',
        );

        parent = parent.parentElement;
      }

      if (button) {
        button.removeAttribute('hidden');
        return;
      }
    }

    this.timeout = setTimeout(() => {
      requestSubmit(this.formTarget);
    }, 500);
  }

  public onSubmit() {
    clearTimeout(this.timeout);
  }
}
