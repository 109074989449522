import { mergeAttributes, Node, nodeInputRule } from '@tiptap/core';
import { createUploadImagePlugin, UploadImage } from './plugin_upload_image';

/**
 * Tiptap Extension to upload images
 *
 * @see  https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521#gistcomment-3744392
 * @since 7th July 2021
 *
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */

interface ImageUploadOptions {
  HTMLAttributes: ImageUploadAttributes;
}

export type ImageUploadAttributes = {
  src?: null | string;
  url?: null | string;
  alt?: null | string;
  title?: null | string;
  sgid?: null | string;
  'data-trix-attachment'?: null | string;
  'data-trix-attributes'?: null | string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    ['text/vnd.delftsolutions.ds-image-upload']: {
      /**
       * Add an image
       */
      setImage: (options: ImageUploadAttributes) => ReturnType;
    };
  }
}

const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const DsImageUpload = (upload: UploadImage) => {
  return Node.create<ImageUploadOptions, never>({
    name: 'text/vnd.delftsolutions.ds-image-upload', // significant
    group: 'block',
    draggable: true,

    addAttributes() {
      return {
        src: {
          default: null,
          parseHTML: (node) => {
            const attr = node.getAttribute('data-trix-attachment');
            if (!attr) {
              return node.getAttribute('src') || null;
            }

            const decoded = JSON.parse(attr);
            return decoded['url'];
          },
        },

        url: {
          default: null,
          parseHTML: (node) => {
            const attr = node.getAttribute('data-trix-attachment');
            if (!attr) {
              return (
                node.getAttribute('src') || node.getAttribute('url') || null
              );
            }

            const decoded = JSON.parse(attr);
            return (
              decoded['url'] ||
              node.getAttribute('src') ||
              node.getAttribute('url')
            );
          },
        },

        alt: {
          default: null,
          parseHTML: (node) => {
            const attr = node.getAttribute('data-trix-attachment');
            if (!attr) {
              return (
                node.getAttribute('caption') || node.getAttribute('alt') || null
              );
            }

            const decoded = JSON.parse(attr);
            return decoded['caption'];
          },
        },

        title: {
          default: null,
        },

        sgid: {
          default: null,
          parseHTML: (node) => {
            const attr = node.getAttribute('data-trix-attachment');
            if (!attr) {
              return null;
            }

            const decoded = JSON.parse(attr);
            return decoded['sgid'];
          },
        },

        'data-trix-attachment': {
          default: null,
        },

        'data-trix-attributes': {
          default: null,
        },
      };
    },

    renderHTML: ({ HTMLAttributes }) => [
      'figure',
      mergeAttributes({
        class: 'border border-dotted font-mono p-3 rounded-sm leading-5',
        'data-trix-attachment': JSON.stringify({
          sgid: HTMLAttributes['sgid'],
          url: HTMLAttributes['url'],
        }),
      }),

      // children
      ['img', { src: HTMLAttributes['src'] || HTMLAttributes['url'] }],
      // ['figcaption', {}, HTMLAttributes['alt'] || HTMLAttributes['title']],
    ],

    addCommands() {
      return {
        setImage:
          (attrs) =>
          ({ state, dispatch }) => {
            const { selection } = state;
            const position = selection.$head
              ? selection.$head.pos
              : selection.$to.pos;

            const node = this.type.create(attrs);
            const transaction = state.tr.insert(position, node);
            return dispatch?.(transaction);
          },
      };
    },

    addInputRules() {
      return [
        nodeInputRule({
          find: IMAGE_INPUT_REGEX,
          type: this.type,
          getAttributes: (match) => {
            const [, alt, src, title] = match;
            return {
              src,
              alt,
              title,
            };
          },
        }),
      ];
    },

    addProseMirrorPlugins() {
      return [createUploadImagePlugin(upload)];
    },
  });
};
