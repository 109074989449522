const BYTES_UNITS_SI = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const BYTES_UNITS = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export function humanizeBytes(value: number, si = false, fractionDigits = 1) {
  const threshold = si ? 1000 : 1024;

  if (Math.abs(value) < threshold) {
    return value + ' B';
  }

  const units = si ? BYTES_UNITS_SI : BYTES_UNITS;

  let unit = -1;
  const r = 10 ** fractionDigits;

  do {
    value /= threshold;
    ++unit;
  } while (
    Math.round(Math.abs(value) * r) / r >= threshold &&
    unit < units.length - 1
  );

  return value.toFixed(fractionDigits) + ' ' + units[unit];
}
