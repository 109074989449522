import { Context, Controller } from '@hotwired/stimulus';
import { humanizeBytes } from 'src/utils/number-humanize';

import Uppy, { type SuccessResponse, type UppyFile } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import RailsDirectUpload from 'src/uppy/rails_direct_upload';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

/**
 * Enable direct file uploads for a file input
 *
 * @example The HTML for a minimal example
 *
 * <div
 *  data-controller="file-direct-upload"
 *  data-file-direct-upload-url-value="<%= rails_direct_uploads_url %>"
 *  data-file-direct-upload-blob-url-template-value="<%= rails_service_blob_url(':signed_id', ':filename') %>">
 *  data-file-direct-upload-representation-url-template-value="<%= representation_url(sgid: ":signed_id", filename: ":filename") %>"
 *    <label>
 *     <span>Upload a file</span>
 *     <input type="file" class="sr-only" data-file-direct-upload-target="input">
 *   </label>
 * </div>
 */
export default class FileDirectUploadController extends Controller {
  static targets = ['label', 'input', 'limit'];
  public static values = {
    url: String,
    blobUrlTemplate: String,
    representationUrlTemplate: String,
  };

  private emptyLabel: Node | undefined;

  private declare labelTarget: HTMLLabelElement;
  private declare inputTarget: HTMLInputElement;
  private declare limitTarget: HTMLElement;

  private declare hasLimitTarget: boolean;

  private declare urlValue: string;
  private declare blobUrlTemplateValue: string;
  private declare representationUrlTemplateValue: string;

  private form: HTMLFormElement | undefined;
  private uppy:
    | Uppy<Record<string, unknown>, Record<string, unknown>>
    | undefined;

  constructor(context: Context) {
    super(context);

    this.onFileChanged = this.onFileChanged.bind(this);
    this.onFileDropped = this.onFileDropped.bind(this);
    this.onFileDragOver = this.onFileDragOver.bind(this);

    this.onStartUppy = this.onStartUppy.bind(this);
    this.onFileUploadStarted = this.onFileUploadStarted.bind(this);
    this.onFileUploadFailed = this.onFileUploadFailed.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
  }

  public connect(): void {
    this.form = this.element.closest('form')!;

    this.inputTarget.addEventListener('click', this.onStartUppy);
    this.inputTarget.addEventListener('change', this.onFileChanged);
    this.form.addEventListener('drop', this.onFileDropped);
    this.form.addEventListener('dragover', this.onFileDragOver);

    const acceptValue = this.inputTarget.getAttribute('accept');

    this.uppy = new Uppy({
      id: `uppy-${this.inputTarget.id}`,
      restrictions: {
        allowedFileTypes: acceptValue?.split(',').map((entry) => entry.trim()),
        maxNumberOfFiles: 1,
        maxFileSize: 1024 * 1024 * 1024,
      },
      debug: true,
      onBeforeFileAdded(currentFile) {
        console.log(currentFile);
        return currentFile;
      },
      onBeforeUpload(files) {
        console.log(files);
        return files;
      },
    });

    this.uppy.use(Dashboard, {
      inline: false,
      theme: 'auto',
      proudlyDisplayPoweredByUppy: false,
      note: this.translateRestrictions(acceptValue),
    });

    this.uppy.use(RailsDirectUpload, {
      directUploadUrl: this.urlValue,
      blobUrlTemplate: this.blobUrlTemplateValue,
      representationUrlTemplate: this.representationUrlTemplateValue
    });

    // Enable webcam if media is allowed
    if (acceptValue?.includes('image') || acceptValue?.includes('video')) {
      this.uppy.use(Webcam, { target: Dashboard });
    }

    this.uppy.on('upload', this.onFileUploadStarted);
    this.uppy.on('upload-error', this.onFileUploadFailed);
    this.uppy.on('upload-success', this.onFileUploaded as any);

    if (this.hasLimitTarget) {
      this.limitTarget.textContent = 'up to 1000 MB';
    }
  }

  private translateRestrictions(accept: string | null): string {
    if (accept === null || !accept) {
      return 'Select 1 file up to 1000 MB';
    }

    const acceptImage = accept?.includes('image/*');
    const acceptVideo = accept?.includes('video/*');
    const acceptSheet = accept?.includes('text/csv');
    const acceptAudio = accept?.includes('audio/*');
    const acceptArchive = accept?.includes('application/zip');
    const acceptPdf = accept?.includes('application/pdf');

    const matches = [
      acceptImage && 'images',
      acceptVideo && 'videos',
      acceptSheet && 'CSVs',
      acceptAudio && 'audios',
      acceptArchive && 'ZIP archives',
      acceptPdf && 'PDFs',
    ].filter(Boolean) as string[];
    if (matches.length === 0) {
      return this.translateRestrictions(null);
    }

    if (matches.length === 1) {
      return `Only ${matches[0]}`;
    }

    const first = matches.shift()!;
    const last = matches.pop();

    return [
      [`${first[0].toLocaleUpperCase() + first.slice(1)}`, ...matches].join(
        ', ',
      ),
      last,
    ].join(' and ');
  }

  public disconnect(): void {
    this.inputTarget.removeEventListener('click', this.onStartUppy);
    this.inputTarget.removeEventListener('change', this.onFileChanged);
    this.form!.removeEventListener('drop', this.onFileDropped);
    this.form!.removeEventListener('dragover', this.onFileDragOver);

    if (this.uppy) {
      (this.uppy.getPlugin('Dashboard') as Dashboard).closeModal();

      this.uppy.off('upload', this.onFileUploadStarted);
      this.uppy.off('upload-error', this.onFileUploadFailed);
      this.uppy.off('upload-success', this.onFileUploaded as any);

      this.uppy = undefined;
    }
  }

  private onFileChanged(): void {
    if (!this.inputTarget || !this.inputTarget.files) {
      return;
    }

    const file = this.inputTarget.files[0];
    if (!file) {
      return;
    }

    // TODO upload
  }

  private onFileDropped(event: DragEvent): void {
    if (!event.dataTransfer) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    // TODO upload
    event.dataTransfer.files;
  }

  private onFileDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  private onStartUppy(event: Event): void {
    event.preventDefault();

    if (this.uppy) {
      (this.uppy.getPlugin('Dashboard') as Dashboard).openModal();
    }
  }

  private onFileUploadStarted(): void {}

  private onFileUploadFailed(): void {}

  private onFileUploaded(file: UppyFile, attributes: SuccessResponse): void {
    this.inputTarget.type = 'hidden';
    this.inputTarget.value = (attributes as { sgid: string }).sgid;

    console.log('[file-direct-upload] new url', attributes.uploadURL);
    if (file.type?.split('/')[0] === 'image') {
      this.fill(attributes.uploadURL);
    } else {
      this.reference(attributes.uploadURL, file.name, file.size);
    }
  }

  public clear() {
    // Move the input
    this.element.appendChild(this.inputTarget);

    while (this.labelTarget.lastElementChild) {
      this.labelTarget.removeChild(this.labelTarget.lastElementChild);
    }

    const newLabel = this.emptyLabel!.cloneNode(true);

    while (newLabel.lastChild) {
      this.labelTarget.prepend(newLabel.lastChild);
    }

    // Replace the input with the already initialized one
    this.labelTarget
      .querySelector('input[type="file"], input[type="hidden"]')
      ?.replaceWith(this.inputTarget);
  }

  public fill(url: string | undefined): void {
    if (!url) {
      return;
    }

    // Clear label
    while (this.labelTarget.lastElementChild) {
      this.labelTarget.removeChild(this.labelTarget.lastElementChild);
    }

    const image = new Image();
    image.src = url;
    image.classList.add(
      'w-full',
      'h-full',
      'object-center',
      'object-cover',
      'hover:opacity-75',
      'group-hover:opacity-75',
    );

    this.labelTarget.append(image);
  }

  public reference(url: string | undefined, name: string, size: number): void {
    if (!url) {
      return;
    }

    // Clear label
    while (this.labelTarget.lastElementChild) {
      this.labelTarget.removeChild(this.labelTarget.lastElementChild);
    }

    const link = document.createElement('a');
    link.href = url;
    link.target = '__blank';
    link.classList.add(
      'w-full',
      'h-full',
      'hover:opacity-75',
      'group-hover:opacity-75',
    );

    const humanSize = humanizeBytes(size);
    link.textContent = `${name} (${humanSize})`;

    this.labelTarget.append(link);
  }
}
