import { Plugin } from 'prosemirror-state';
import { AttachmentAttributes } from 'src/attachment_upload';

/**
 * function for image drag n drop(for tiptap)
 * @see https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521#gistcomment-3744392
 */
export type UploadImage = (image: File) => Promise<AttachmentAttributes>;

export function createUploadImagePlugin(upload: UploadImage) {
  return new Plugin({
    props: {
      handlePaste(view, event) {
        console.log('[upload-image] paste', event);

        const items = Array.from(event.clipboardData?.items || []);
        const { schema } = view.state;

        items.forEach((item) => {
          const image = item.getAsFile();

          if (item.type.indexOf('image') === 0) {
            event.preventDefault();

            if (upload && image) {
              upload(image).then(({ url, sgid }) => {
                const node = schema.nodes[
                  'text/vnd.delftsolutions.ds-image-upload'
                ].create({
                  src: url,
                  url: url,
                  sgid,
                });
                const transaction = view.state.tr.replaceSelectionWith(node);
                view.dispatch(transaction);
              });
            }
          }
        });

        return false;
      },

      handleDrop(view, event) {
        console.log('[upload-image] drop', event);

        const hasFiles = event.dataTransfer?.files?.length;

        if (!hasFiles) {
          return false;
        }

        const images = Array.from(event!.dataTransfer!.files).filter((file) =>
          /image/i.test(file.type)
        );

        if (images.length === 0) {
          return false;
        }

        event.preventDefault();

        const { schema } = view.state;
        const coordinates = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        });

        images.forEach(async (image) => {
          const attributes = await upload(image);

          const node = schema.nodes[
            'text/vnd.delftsolutions.ds-image-upload'
          ].create({
            src: attributes.url,
            sgid: attributes.sgid,
            url: attributes.url,
          });

          const transaction = view.state.tr.insert(coordinates!.pos, node);
          view.dispatch(transaction);
        });
        return false;
      },
    },
  });
}
