import {
  FocusKeys,
  focusTrap,
  focusZone,
  getAnchoredPosition,
  PositionSettings,
} from '@primer/behaviors';
import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';

export default class SiteSelectController extends Controller {
  public static targets = ['select', 'options', 'currentSite'];
  public static values = { url: String, current: String, modal: Boolean };

  private declare optionsTarget: HTMLElement;
  private declare selectTarget: HTMLElement;
  private declare currentSiteTarget: HTMLElement;
  private declare urlValue: string;
  private declare currentValue: string;
  private declare modalValue: boolean;

  private liveRegion: HTMLElement | null | undefined;
  private listBox: HTMLElement | null | undefined;
  private loading = false;
  private loaded = false;
  private focusController: AbortController | undefined;
  private loadingPromise: Promise<void> = Promise.resolve();

  constructor(context: Context) {
    super(context);

    this.onClick = this.onClick.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onPreload = this.onPreload.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);

    this.toggle = this.toggle.bind(this);
  }

  public get active(): boolean {
    return this.selectTarget.getAttribute('aria-expanded') == 'true';
  }

  public set active(val: boolean) {
    if (val) {
      this.selectTarget.setAttribute('aria-expanded', 'true');
    } else {
      this.selectTarget.removeAttribute('aria-expanded');
    }
  }

  private set activeElement(next: HTMLElement) {
    const option = next.closest('[role="option"]');
    if (!option) {
      return;
    }

    // The aria-activedescendant property provides a method of managing focus
    // for assistive technologies on interactive elements when they contain
    // multiple focusable descendants, such as menus, grids, and toolbars.
    // Instead of the screen reader moving focus between owned elements,
    // aria-activedescendant can be used on container elements to refer to the
    // currently active element, informing assistive technology users of the
    // currently active element when focused.

    // With aria-activedescendant, the browser keeps the DOM focus on the
    // container element or on an input element that controls the container
    // element. However, the user agent communicates desktop focus events and
    // states to the assistive technology as if the element referenced by
    // aria-activedescendant has focus.
    this.selectTarget.setAttribute('aria-activedescendant', option.id);
  }

  public connect(): void {
    this.selectTarget.addEventListener('focus', this.onPreload);
    this.selectTarget.addEventListener('mouseenter', this.onPreload);
    this.selectTarget.addEventListener('click', this.onClick);
    this.selectTarget.addEventListener('keydown', this.onKeyPress);

    this.optionsTarget.addEventListener('keydown', this.onKeyPress);
    this.optionsTarget.addEventListener('focusin', this.onFocusChange);
    this.optionsTarget.addEventListener('mouseenter', this.onHover);
    this.optionsTarget.addEventListener('click', this.onClick);

    this.liveRegion = this.element.querySelector<HTMLElement>(
      '[aria-live="polite"]',
    );
    this.listBox =
      this.optionsTarget.querySelector<HTMLElement>('[role="listbox"]');

    const modals = document.getElementById('modals');
    if (this.modalValue && modals) {
      modals.appendChild(this.listBox!);
    }
  }

  public disconnect(): void {
    this.close(true);

    this.selectTarget.removeEventListener('focus', this.onPreload);
    this.selectTarget.removeEventListener('mouseenter', this.onPreload);
    this.selectTarget.removeEventListener('click', this.onClick);
    this.selectTarget.removeEventListener('keydown', this.onKeyPress);

    this.optionsTarget.removeEventListener('keydown', this.onKeyPress);
    this.optionsTarget.removeEventListener('focusin', this.onFocusChange);
    this.optionsTarget.removeEventListener('mouseenter', this.onHover);
    this.optionsTarget.removeEventListener('click', this.onClick);

    document.removeEventListener('click', this.onOutsideClick);

    this.optionsTarget.appendChild(this.listBox!);

    this.liveRegion = undefined;
    this.listBox = undefined;

    if (this.focusController) {
      this.focusController.abort('SiteSelectController#disconnect');
    }
  }

  public toggle(): void {
    if (this.active) {
      this.close();
    } else {
      this.open();
    }
  }

  public open(): Promise<void> {
    console.debug('[site-select] open');
    this.active = true;

    // First show the box
    toggleHidden(this.listBox, false);

    // Now make it translucent and apply animation
    this.listBox!.classList.add(
      'opacity-0',
      'transition',
      'ease-out',
      'duration-100',
    );

    if (this.modalValue) {
      const settings = {
        side: 'outside-bottom',
        align: 'start',
        alignmentOffset: 0,
        anchorOffset: 8,
        allowOutOfBounds: false,
      } as Partial<PositionSettings>;

      const float = this.listBox!;
      const anchor = this.selectTarget;
      const { top, left } = getAnchoredPosition(float, anchor, settings);
      float.style.top = `${top}px`;
      float.style.left = `${left}px`;

      float.style.width = `calc(100vw - 32px)`;
      float.style.maxWidth = '28rem';
    }

    document.addEventListener('click', this.onOutsideClick);

    return new Promise((resolve, reject) => {
      requestAnimationFrame(() => {
        if (!this.listBox) {
          reject(new Error('There is no listbox'));
          return;
        }

        // Animate to visible
        this.listBox.classList.remove('opacity-0');
        this.listBox.classList.add('opacity-100');

        // Move focus
        if (this.loaded) {
          this.focusFirst();
          this.trapFocus();
          resolve();
        } else {
          this.listBox.focus();

          if (this.modalValue) {
            this.listBox.classList.add('w-56');
          }

          this.load()
            .then(() => {
              this.trapFocus();
            })
            .then(resolve, reject);
        }
      });
    });
  }

  public close(skipAnimation = false): void {
    if (!this.active) {
      return;
    }

    console.debug('[site-select] close');
    this.active = false;

    if (this.liveRegion) {
      this.liveRegion.textContent = '';
    }

    if (!this.listBox) {
      return;
    }

    // Prepare the animation
    this.listBox.classList.add(
      'opacity-100',
      'transition',
      'ease-in',
      'duration-100',
    );

    this.releaseFocus();

    // Instantly close this
    if (skipAnimation) {
      this.listBox.classList.remove('opacity-100');
      this.listBox.classList.add('opacity-0');

      toggleHidden(this.listBox, true);
      return;
    }

    requestAnimationFrame(() => {
      if (!this.listBox) {
        return;
      }

      // Animate to hidden
      this.listBox.classList.remove('opacity-100');
      this.listBox.classList.add('opacity-0');

      setTimeout(() => {
        // If toggle on within 120ms, ignore this
        if (this.active || !this.listBox) {
          return;
        }

        // Finally, hide the box
        toggleHidden(this.listBox, true);
      }, 105);
    });

    this.selectTarget.focus();

    document.removeEventListener('click', this.onOutsideClick);
  }

  public focusFirst(): void {
    const first = this.listBox!.querySelector(
      '[role="option"] input, [role="option"] a[href], [role="option"] button, [role="option"] [tabindex]',
    );

    if (first instanceof HTMLElement) {
      first.focus();
    }

    console.debug('[site-select] focus first', first);
  }

  public focusLast(): void {
    const items = this.listBox!.querySelectorAll(
      '[role="option"] input, [role="option"] a[href], [role="option"] button, [role="option"] [tabindex]',
    );

    const last = items.length > 0 ? items.item(items.length - 1) : undefined;

    if (last instanceof HTMLElement) {
      last.focus();
    }

    console.debug('[site-select] focus last', last);
  }

  private trapFocus(): void {
    if (!this.listBox) {
      return;
    }

    this.focusController = focusTrap(this.listBox, this.currentSiteTarget);
    this.listBox.addEventListener('keydown', this.onKeyPress);

    focusZone(this.listBox, {
      abortSignal: this.focusController?.signal,
      bindKeys: FocusKeys.ArrowVertical | FocusKeys.HomeAndEnd,
      focusOutBehavior: 'wrap',
    });
  }

  private releaseFocus(): void {
    if (this.focusController) {
      this.focusController.abort('Site selection released focus');
      this.focusController = undefined;
    }

    this.listBox?.removeEventListener('keydown', this.onKeyPress);
  }

  private select(element: HTMLAnchorElement): void {
    const current = element.closest<HTMLElement>('[role="option"]')!;
    console.debug('[site-select] selected change', current);
  }

  private load(): Promise<void> {
    if (this.loading || !this.liveRegion) {
      return this.loadingPromise;
    }

    this.listBox?.classList.add('--loading');
    this.loading = true;
    this.liveRegion.textContent = 'Loading your sites';

    const expected = 'text/vnd.kaboom.select-links';

    this.loadingPromise = fetch(this.urlValue, {
      headers: {
        accept: `${expected}, application/problem+json; q=0.1`,
      },
    })
      .then((value) => {
        const contentType = value.headers.get('content-type') || '';

        if (contentType.startsWith(expected)) {
          return value.text();
        }

        if (contentType.includes(`variant=${expected}`)) {
          return value.text();
        }

        throw new Error(`Expected ${expected}, actual ${contentType}`);
      })
      .then((options) => {
        if (!this.element) {
          return;
        }

        if (!options) {
          if (this.selectTarget.tagName === 'A' && this.active) {
            window.location.href = this.selectTarget.getAttribute('href') || '';
          }

          console.debug('[site select] nothing to show');
          throw new Error('Nothing to show');
        }

        const content = document.createElement('div');
        content.innerHTML = options;

        // Remove old content
        while (this.optionsTarget.lastElementChild) {
          this.optionsTarget.removeChild(this.optionsTarget.lastElementChild);
        }
        this.listBox?.remove();

        // Add content
        this.listBox = content.firstElementChild as HTMLElement;
        const modals = document.getElementById('modals');

        if (this.modalValue && modals) {
          modals.appendChild(this.listBox);
        } else {
          this.optionsTarget.appendChild(this.listBox);
        }

        if (this.modalValue) {
          const settings = {
            side: 'outside-bottom',
            align: 'start',
            alignmentOffset: 0,
            anchorOffset: 8,
            allowOutOfBounds: false,
          } as Partial<PositionSettings>;

          const float = this.listBox!;
          const anchor = this.selectTarget;
          const { top, left } = getAnchoredPosition(float, anchor, settings);
          float.style.top = `${top}px`;
          float.style.left = `${left}px`;

          float.style.width = `calc(100vw - 32px)`;
          float.style.maxWidth = '28rem';
        }

        if (this.liveRegion) {
          this.liveRegion.textContent = `${this.listBox.childElementCount} sites found`;
        }

        const selected = this.optionsTarget.querySelector(
          `[data-value="${this.currentValue}"]`,
        );

        if (selected) {
          this.select(selected.querySelector('a')!);
        }
      })
      .then(() => {
        this.loaded = true;
        this.loading = false;

        if (!this.listBox) {
          return;
        }

        this.listBox.classList.remove('--loading');
        this.listBox.classList.add('--loaded');

        // Currently hidden
        if (!this.active) {
          toggleHidden(this.listBox, true);
        } else {
          this.focusFirst();
        }
      })
      .catch((error) => {
        this.loaded = false;
        this.loading = false;

        this.listBox?.classList.remove('--loading');
        this.listBox?.classList.add('--error');

        console.error(error);
      });

    return this.loadingPromise;
  }

  private onClick(e: MouseEvent): void {
    // CLicking on the top-element
    if (e.currentTarget === this.selectTarget) {
      e.preventDefault();

      this.toggle();

      if (this.active && this.loaded) {
        this.focusFirst();
      }
      return;
    }

    // Clicking on an option
    if (
      e.currentTarget === this.optionsTarget &&
      this.loaded &&
      e.target instanceof HTMLElement
    ) {
      const option = e.target.closest('[role="option"]');
      if (!option) {
        return;
      }

      const input = option.querySelector<HTMLAnchorElement>(
        'input, a[href], button, [tabindex]',
      );

      // Ignore if the keyboard was used to trigger a synthetic click on the
      // input. Thanks browsers...
      if (e.target === input) {
        return;
      }

      this.select(input!);
      this.loaded = false;
      return;
    }
  }

  private onOutsideClick(e: MouseEvent): void {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    // Click was inside
    if (this.element.contains(e.target)) {
      return;
    }

    // Ignore if already closed
    if (!this.active) {
      return;
    }

    this.close();
  }

  private onKeyPress(e: KeyboardEvent): void {
    if (e.currentTarget === this.selectTarget) {
      switch (e.key) {
        case 'ArrowDown': {
          e.preventDefault();

          this.open()
            .then(() => {
              this.focusFirst();
            })
            .catch(() => {});
          return;
        }

        case 'ArrowUp': {
          e.preventDefault();
          this.open()
            .then(() => {
              this.focusLast();
            })
            .catch(() => {});
        }
      }
      return;
    }

    if (
      e.currentTarget === this.optionsTarget ||
      e.currentTarget === this.listBox
    ) {
      if (e.key === 'Escape') {
        e.preventDefault();
        this.close();
        return;
      }

      if (e.key === 'Enter') {
        return;
      }
    }
  }

  private onPreload(): void {
    if (this.loaded) {
      return;
    }

    this.load();
  }

  private onFocusChange(e: FocusEvent): void {
    if (!(e.target instanceof HTMLInputElement)) {
      return;
    }

    const option = e.target.closest('[role="option"]');
    if (!option) {
      return;
    }

    const input = option.querySelector('input, a[href], button, [tabindex]');
    if (input instanceof HTMLElement) {
      this.activeElement = input;
    }

    // console.debug('[site-select] focus change', input);
  }

  private onHover(e: MouseEvent): void {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    const option = e.target.closest('[role="option"]');
    if (!option) {
      return;
    }

    const input = option.querySelector('input, a[href], button, [tabindex]');
    if (input instanceof HTMLElement) {
      input.focus();
      this.activeElement = input;
    }
  }
}
