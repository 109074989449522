import Rails from '@rails/ujs';
import { Dialog } from './dialog';

let __SkipConfirmation = false;

/**
 * Confirmation dialog to be used in conjunction with @rails/ujs.
 *
 * [data-confirm="message"]
 * [data--dialog="custom-dialog-id"]
 */
export class ConfirmDialog extends Dialog {
  public onConfirm: () => void;
  public onCancel: () => void;

  protected readonly actionsConfirm: NodeListOf<Element>;
  protected readonly actionsClose: NodeListOf<Element>;
  private readonly sourceElement: HTMLElement;

  constructor(dialogId: string, sourceElement: HTMLElement) {
    super(dialogId);

    this.sourceElement = sourceElement;

    this.actionsConfirm = this.dialogNode.querySelectorAll(
      '[data-action="confirm"]'
    )!;
    this.actionsClose = this.dialogNode.querySelectorAll(
      '[data-action="close"]'
    )!;

    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);

    this.actionsConfirm.forEach((action) =>
      action.addEventListener('click', this.confirm)
    );
    this.actionsClose.forEach((action) =>
      action.addEventListener('click', this.cancel)
    );

    // Default onConfirm action is to click the source element once more; but
    // this time skipping the confirmation step (see {confirm})
    this.onConfirm = this.sourceElement.click.bind(this.sourceElement);

    // Default onCancel action is a noop, and closes + destroys the dialog
    this.onCancel = (): void => {
      /* noop */
    };
  }

  public destroy(): void {
    console.debug('[confirm] destroy');

    this.actionsConfirm.forEach((action) =>
      action.removeEventListener('click', this.confirm)
    );
    this.actionsClose.forEach((action) =>
      action.removeEventListener('click', this.cancel)
    );

    super.destroy();
  }

  public confirm(event?: Event): void {
    console.debug('[confirm] confirm');

    __SkipConfirmation = true;
    this.onConfirm();
    __SkipConfirmation = false;

    this.close(event);
  }

  public cancel(event?: Event): void {
    console.debug('[confirm] cancel');

    this.onCancel();
    this.close(event);
  }

  public close(
    event?: Event,
    _replaced: boolean = false,
    _destroyed: boolean = false
  ): boolean {
    console.debug('[confirm] close');

    if (super.close(event, _replaced, _destroyed)) {
      // Confirmation dialogs are throwaway, so this can clean-up itself
      this.destroy();
      return true;
    }

    return false;
  }
}

const nativeConfirm = Rails.confirm;

export function confirmWithDialog(
  message: string,
  element: HTMLElement
): boolean {
  if (__SkipConfirmation) {
    return true;
  }

  // This is the regular behaviour
  const dialogId = element.getAttribute('data-confirm-dialog');
  if (!dialogId) {
    return nativeConfirm(message, element);
  }

  // This is the fallback behaviour
  if (!document.getElementById(dialogId)) {
    console.warn(
      `[confirm] Looking for dialog ${dialogId}, but there is no dialog with that ID in the DOM`
    );
    return nativeConfirm(message, element);
  }

  console.debug(`[confirm] ${message}`);

  // If the confirmation was trigger from a menu item, that menu is now closed.
  // In this case, instead of trying to re-focus on the menuitem when the dialog
  // is eventually closed, re-focus on the button that controls the menu.
  let source = element;
  if (source.closest('[role="menu"]')) {
    const menuId = source.closest('[role="menu"]')!.id;
    source = document.querySelector<HTMLElement>(
      `[aria-controls="${menuId}"]`
    )!;
  }

  // Create the confirmation dialog and show it.
  new ConfirmDialog(dialogId, element).open(source);

  return false;
}

Rails.confirm = confirmWithDialog;
