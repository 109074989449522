import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from 'stimulus';

export default class DateInputController extends Controller {
  public static targets = ['input', 'pasteResult', 'pasteOutputValue'];

  private declare inputTarget: HTMLInputElement;
  private declare pasteResultTarget: HTMLElement;
  private declare pasteOutputValueTarget: HTMLElement;

  private lastPastedDate: Date | undefined;

  public constructor(context: Context) {
    super(context);

    this.onPaste = this.onPaste.bind(this);
  }

  connect(): void {
    this.inputTarget.addEventListener('paste', this.onPaste);
  }

  disconnect(): void {
    this.inputTarget.removeEventListener('paste', this.onPaste);
  }

  private onPaste(event: ClipboardEvent) {
    const data = event.clipboardData?.getData('text')?.trim();
    if (!data) {
      console.warn('[date] pasted, but no text data found');
      return;
    }

    let splitter = '';

    // dd.mm.yyyy
    if (data.includes('.') && data.split('.').length === 3) {
      splitter = '.';
    }

    // dd/mm/yyyy
    if (data.includes('/') && data.split('/').length === 3) {
      splitter = '/';
    }

    // dd-mm-yyyy
    // yyyy-mm-dd
    if (data.includes('-') && data.split('-').length === 3) {
      splitter = '-';
    }

    if (splitter === '') {
      console.warn(
        '[date] pasted a value but did not find a date in three parts',
        data,
      );
      return;
    }

    let parts = data.split(splitter);

    // dd-mm-yy
    // dd/mm/yy
    if (parts[0].length !== 4 && parts[2].length !== 4) {
      parts[2] = '20' + parts[2];
    }

    // convert all to ['yyyy', 'mm', 'dd']
    if (parts[2].length === 4) {
      parts = parts.reverse();
    }

    const [yyyy, mm, dd] = parts.map(Number);
    if (mm > 12 || dd > 31) {
      console.warn('[date] pasted a value but it does not seem valid', {
        yyyy,
        mm,
        dd,
      });
      return;
    }

    this.lastPastedDate = new Date(`${yyyy}-${mm}-${dd}Z`);
    this.pasteOutputValueTarget.textContent = `${this.lastPastedDate.toISOString().split('T')[0]} (YYYY-MM-DD)`;
    toggleHidden(this.pasteResultTarget, false);

    // Focus the button
    this.pasteResultTarget.querySelector('button')?.focus();
  }

  public onUsePasteData(): void {
    toggleHidden(this.pasteResultTarget, true);

    if (this.lastPastedDate) {
      this.inputTarget.valueAsDate = this.lastPastedDate;
      this.inputTarget.focus();
    }
  }
}
