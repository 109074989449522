import { mergeAttributes, Node } from '@tiptap/core';

/**
 * This renders attachments that are not an image and have been uploaded
 * previously. Compatible with action text attachments
 *
 * Attachments that are fresh are rendered by DsFile and DsImage.
 */
export const ActionTextNonContentAttachment = Node.create({
  name: 'text/vnd.kaboom.attachment.action-text-non-content',

  group: 'block',
  content: 'inline*',
  atom: true,

  addAttributes() {
    return {
      caption: {
        isRequired: false,
        parseHTML: (element) => {
          if (element.hasAttribute('data-trix-attributes')) {
            const caption = JSON.parse(
              element.getAttribute('data-trix-attributes') || '{}'
            )['caption'];
            if (caption) {
              return caption;
            }
          }

          if (element.hasAttribute('caption')) {
            const caption = element.getAttribute('caption');
            if (caption) {
              return caption;
            }
          }

          const decoded = JSON.parse(
            element.getAttribute('data-trix-attachment') || '{}'
          );
          return (
            [
              decoded['filename'] || element.getAttribute('filename'),
              parseBytesToHuman(
                decoded['filesize'] || element.getAttribute('filesize')
              ),
            ]
              .filter(Boolean)
              .join(' · ') || '(no caption)'
          );
        },
      },

      'data-trix-attachment': {
        isRequired: true,
      },

      'data-trix-attributes': {
        isRequired: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'action-text-attachment',
        getAttrs: (node) => {
          const encoded =
            node instanceof HTMLElement &&
            node.getAttribute('data-trix-attachment');

          const encodedAttributes =
            node instanceof HTMLElement &&
            node.getAttribute('data-trix-attributes');

          if (!encoded) {
            const contentType =
              node instanceof HTMLElement && node.getAttribute('content-type');

            if (!contentType) {
              return false;
            }

            if (contentType.startsWith('image/')) {
              return false;
            }

            return {
              caption: node.getAttribute('caption'),
              'data-trix-attachment': JSON.stringify({
                sgid: node.getAttribute('sgid'),
                contentType,
                url: node.getAttribute('url'),
                filename: node.getAttribute('filename'),
                filesize: node.getAttribute('filesize'),
              }),
              'data-trix-attributes': JSON.stringify({
                caption: node.getAttribute('caption'),
              }),
            };
          }

          const decoded = JSON.parse(encoded || '{}');
          const attributes = JSON.parse(encodedAttributes || '{}');

          return (
            !(decoded['contentType'] || '').startsWith('image/') &&
            Boolean(!decoded['content'] && attributes['caption']) &&
            null
          );
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'figure',
      mergeAttributes(HTMLAttributes, {
        class: 'border font-mono p-3 rounded-md leading-5',
      }),
      ['figcaption', {}, HTMLAttributes['caption'] || ''],
    ];
  },
});

function parseBytesToHuman(input: unknown): string | null {
  if (!input || isNaN(Number(input))) {
    return null;
  }

  const kb = Math.round(Number(input) / 1000);

  if (kb < 1500) {
    return `${kb} KB`;
  }

  return `${(kb / 1000).toFixed(1)} MB`;
}
