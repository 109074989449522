import { Context, Controller } from '@hotwired/stimulus';
import { emitter } from 'src/emitter';

export default class CadenceGridController extends Controller {
  public static targets = [];

  constructor(props: Context) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  connect(): void {
    const firstActiveElement = this.selectedElement || this.cells.item(0);
    firstActiveElement.setAttribute('tabindex', '0');

    if (this.element instanceof HTMLElement) {
      this.element.addEventListener('click', this.onClick);
      this.element.addEventListener('keydown', this.onKeyPress);

      console.log('bound');
    }
  }

  disconnect(): void {
    if (this.element instanceof HTMLElement) {
      this.element.removeEventListener('click', this.onClick);
      this.element.removeEventListener('keydown', this.onKeyPress);
    }
  }

  get cells(): NodeListOf<HTMLElement> {
    return this.element.querySelectorAll('[role="gridcell"]');
  }

  get activeElement(): HTMLElement | null {
    return this.element.querySelector('[role="gridcell"][tabindex="0"]');
  }

  set activeElement(element: HTMLElement | null) {
    if (element === null) {
      return;
    }

    this.activeElement?.setAttribute('tabindex', '-1');

    element.setAttribute('tabindex', '0');
    element.focus();

    // Remove select
    if (this.selectedElement) {
      this.deselect();
    }
  }

  get selectedElement(): HTMLElement | null {
    return this.element.querySelector(
      '[role="gridcell"][aria-selected="true"]',
    );
  }

  set selectedElement(element: HTMLElement | null) {
    this.selectedElement?.setAttribute('aria-selected', 'false');
    this.selectedElement?.classList.remove('opacity-100');

    if (!element) {
      this.cells.forEach((cell) => cell.classList.remove('opacity-50'));
      return;
    }

    this.cells.forEach((cell) => cell.classList.add('opacity-50'));

    element.classList.remove('opacity-50');
    element.classList.add('opacity-100');
    element.setAttribute('aria-selected', 'true');
  }

  private select() {
    if (!this.activeElement) {
      return;
    }

    this.selectedElement = this.activeElement;

    const activeDateIso8601 = this.selectedElement.getAttribute('data-iso8601');
    emitter.emit('cadence-grid:select', { iso8601: activeDateIso8601 });
  }

  private deselect() {
    this.selectedElement = null;

    emitter.emit('cadence-grid:deselect', {});
  }

  private onClick(event: Event) {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const activeCell = event.target.closest('[role="gridcell"][tabindex]');
    if (this.activeElement === activeCell) {
      if (this.selectedElement === activeCell) {
        this.deselect();
      } else {
        this.select();
      }
    } else if (activeCell instanceof HTMLElement) {
      this.activeElement = activeCell;
    }
  }

  private onKeyPress(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowRight': {
        event.preventDefault();
        this.gotoNextWeek();
        break;
      }

      case 'ArrowLeft': {
        event.preventDefault();
        this.gotoPreviousWeek();
        break;
      }

      case 'ArrowUp': {
        event.preventDefault();
        this.gotoPreviousDay();
        break;
      }

      case 'ArrowDown': {
        event.preventDefault();
        this.gotoNextDay();
        break;
      }

      case 'Enter': {
        event.preventDefault();
        this.select();
        break;
      }

      case 'Escape': {
        event.preventDefault();
        this.deselect();
        break;
      }
    }
  }

  private gotoNextWeek() {
    const current = this.activeElement?.getAttribute('data-iso8601');
    if (!current) {
      return;
    }

    const nextDate = new Date(`${current}T12:00:00Z`);
    nextDate.setDate(nextDate.getDate() + 7);
    const nextIso8601 = nextDate.toISOString().split('T').shift();

    this.activeElement = this.element.querySelector(
      `[role="gridcell"][tabindex][data-iso8601="${nextIso8601}"]`,
    );
  }

  private gotoPreviousWeek() {
    const current = this.activeElement?.getAttribute('data-iso8601');
    if (!current) {
      return;
    }

    const nextDate = new Date(`${current}T12:00:00Z`);
    nextDate.setDate(nextDate.getDate() - 7);
    const nextIso8601 = nextDate.toISOString().split('T').shift();

    this.activeElement = this.element.querySelector(
      `[role="gridcell"][tabindex][data-iso8601="${nextIso8601}"]`,
    );
  }

  private gotoNextDay() {
    const current = this.activeElement?.getAttribute('data-iso8601');
    if (!current) {
      return;
    }

    const nextDate = new Date(`${current}T12:00:00Z`);
    nextDate.setDate(nextDate.getDate() + 1);
    const nextIso8601 = nextDate.toISOString().split('T').shift();

    this.activeElement = this.element.querySelector(
      `[role="gridcell"][tabindex][data-iso8601="${nextIso8601}"]`,
    );
  }

  private gotoPreviousDay() {
    const current = this.activeElement?.getAttribute('data-iso8601');
    if (!current) {
      return;
    }

    const nextDate = new Date(`${current}T12:00:00Z`);
    nextDate.setDate(nextDate.getDate() - 1);
    const nextIso8601 = nextDate.toISOString().split('T').shift();

    this.activeElement = this.element.querySelector(
      `[role="gridcell"][tabindex][data-iso8601="${nextIso8601}"]`,
    );
  }
}
