import { toggleHidden } from 'src/utils/dom-toggle';
import { requestSubmit } from 'src/utils/form';
import { Context, Controller } from '@hotwired/stimulus';

/**
 * Enables the bulk actions for todo lists
 */
export default class BulkActionsController extends Controller {
  static targets = [
    'header',
    'actions',
    'bulkToggle',
    'bulkAssign',
    'bulkDelegate',
    'bulkResolve',
    'bulkReopen',
    'copyResolve',
  ];

  private declare readonly actionsTarget: HTMLElement;
  private declare readonly headerTarget: HTMLElement;
  private declare readonly bulkToggleTarget: HTMLInputElement;
  private declare readonly bulkAssignTarget: HTMLButtonElement;
  private declare readonly bulkDelegateTarget: HTMLButtonElement;
  private declare readonly bulkResolveTarget: HTMLButtonElement;
  private declare readonly bulkReopenTarget: HTMLButtonElement;
  private declare readonly copyResolveTarget: HTMLButtonElement;

  constructor(context: Context) {
    super(context);
  }

  private get checkboxes(): NodeListOf<HTMLInputElement> {
    return this.element.querySelectorAll('input[data-todos-bulk-actions]');
  }

  private get ids(): string[] {
    const ids: (string | null)[] = [];

    this.checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        ids.push(checkbox.getAttribute('data-todo-id'));
      }
    });

    return ids.filter((id): id is string => Boolean(id));
  }

  private get urls(): string[] {
    const urls: (string | null)[] = [];

    this.checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        urls.push(checkbox.getAttribute('data-todo-url'));
      }
    });

    return urls.filter((url): url is string => Boolean(url));
  }

  public connect(): void {
    toggleHidden(
      this.copyResolveTarget,
      !(
        navigator &&
        'clipboard' in navigator &&
        typeof navigator.clipboard.writeText === 'function'
      ),
    );
  }

  public onBulkToggle(): void {
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = this.bulkToggleTarget.checked;
    });

    this.onUpdateActions();
  }

  public onToggle(e: Event): void {
    let checkedCount = 0;
    let uncheckedCount = 0;

    this.checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedCount += 1;
      } else {
        uncheckedCount += 1;
      }
    });

    // Test if all checked
    if (checkedCount > 0) {
      this.bulkToggleTarget.checked = true;
      this.bulkToggleTarget.indeterminate = uncheckedCount > 0;
    }

    // Test if all unchecked
    if (uncheckedCount > 0) {
      this.bulkToggleTarget.checked = false;
      this.bulkToggleTarget.indeterminate = checkedCount > 0;
    }

    this.onUpdateActions();
  }

  private onUpdateActions(): void {
    let hasChecked = false;
    this.checkboxes.forEach((checkbox) => {
      hasChecked = hasChecked || checkbox.checked;
    });

    toggleHidden(this.headerTarget, hasChecked);
    toggleHidden(this.actionsTarget, !hasChecked);
  }

  public onBulkAssign(e: Event): void {
    e.preventDefault();

    const button =
      e.currentTarget instanceof HTMLButtonElement ? e.currentTarget : null;
    const form = button?.closest('form');

    if (!form || !button) {
      return;
    }

    button.disabled = true;
    button.textContent =
      button.getAttribute('data-disable-with') || button.textContent;

    this.urls.forEach((url) => {
      const item = document.createElement('input');
      item.setAttribute('type', 'hidden');
      item.setAttribute('name', 'todo[]');
      item.setAttribute('value', url);

      form.append(item);
    });

    requestSubmit(form);
  }

  public onBulkDelegate(e: Event): void {
    e.preventDefault();

    const button =
      e.currentTarget instanceof HTMLButtonElement ? e.currentTarget : null;
    const form = button?.closest('form');

    if (!form || !button) {
      return;
    }

    button.disabled = true;
    button.textContent =
      button.getAttribute('data-disable-with') || button.textContent;

    this.urls.forEach((url) => {
      const item = document.createElement('input');
      item.setAttribute('type', 'hidden');
      item.setAttribute('name', 'todo[]');
      item.setAttribute('value', url);

      form.append(item);
    });

    requestSubmit(form);
  }

  public onBulkResolve(e: Event): void {
    e.preventDefault();

    const button =
      e.currentTarget instanceof HTMLButtonElement ? e.currentTarget : null;
    const form = button?.closest('form');

    if (!form || !button) {
      return;
    }

    button.disabled = true;
    button.textContent =
      button.getAttribute('data-disable-with') || button.textContent;

    this.urls.forEach((url) => {
      const item = document.createElement('input');
      item.setAttribute('type', 'hidden');
      item.setAttribute('name', 'todo[]');
      item.setAttribute('value', url);

      form.append(item);
    });

    requestSubmit(form);
  }

  public onBulkReopen(e: Event): void {
    e.preventDefault();

    const button =
      e.currentTarget instanceof HTMLButtonElement ? e.currentTarget : null;
    const form = button?.closest('form');

    if (!form || !button) {
      return;
    }

    button.disabled = true;
    button.textContent =
      button.getAttribute('data-disable-with') || button.textContent;

    this.urls.forEach((url) => {
      const item = document.createElement('input');
      item.setAttribute('type', 'hidden');
      item.setAttribute('name', 'todo[]');
      item.setAttribute('value', url);

      form.append(item);
    });

    requestSubmit(form);
  }

  public onCopyResolves(e: Event): void {
    if (!(e.currentTarget instanceof HTMLButtonElement)) {
      return;
    }

    const button = e.currentTarget;
    const icon = e.currentTarget.querySelector('[hidden]');
    const label = e.currentTarget.querySelector('span')!;

    toggleHidden(icon, false);
    button.disabled = true;

    const restoreText = label.textContent;

    const copy = this.urls.map((url) => `/resolves ${url}`).join('\n');
    navigator.clipboard
      .writeText(copy)
      .catch(() => {})
      .then(() => {
        label.textContent =
          button.getAttribute('data-disable-with') || restoreText;

        setTimeout(() => {
          button.disabled = false;
          toggleHidden(icon, true);

          label.textContent = restoreText;
        }, 1500);
      });
  }
}
