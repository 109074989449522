import { FocusKeys, focusZone } from '@primer/behaviors';
import { confirmWithDialog } from 'src/confirm';
import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';
import { emitter } from 'src/emitter';

export default class SnoozeController extends Controller {
  public static targets = ['extension', 'button'];
  public static values = {
    'assigned-user': String,
    formactionTemplate: String,
  };

  private declare assignedUserValue: string;

  private declare readonly extensionTarget: HTMLElement;
  private declare readonly buttonTarget: HTMLElement;
  private declare readonly formactionTemplateValue: string;
  private originalFormAction: string | null = null;

  constructor(context: Context) {
    super(context);

    this.onAssigned = this.onAssigned.bind(this);
  }
  connect(): void {
    this.originalFormAction = this.buttonTarget.getAttribute('formaction');
    toggleHidden(this.extensionTarget, false);

    emitter.on('todos:assigned', this.onAssigned);
  }

  disconnect(): void {
    this.buttonTarget.setAttribute('formaction', this.originalFormAction!);
    toggleHidden(this.extensionTarget, true);

    emitter.off('todos:assigned', this.onAssigned);
  }

  private onAssigned(params: unknown) {
    if (!params || typeof params !== 'object') {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(params, 'value')) {
      return;
    }

    const assigned = (params as any).value;
    this.assignedUserValue = assigned || '';
  }

  public onSnooze(e: Event) {
    if (!e.currentTarget || !(e.currentTarget instanceof HTMLButtonElement)) {
      return;
    }

    const mustConfirm = !this.assignedUserValue;

    if (
      mustConfirm &&
      !confirmWithDialog(
        'A snoozed task without an assigned user will still show up on the inbox. Are you sure you want to snooze?',
        e.currentTarget,
      )
    ) {
      e.preventDefault();
      return;
    }

    const formAction = e.currentTarget.formAction;
    this.buttonTarget.setAttribute('formaction', formAction);
    this.buttonTarget.click();
  }

  public onSnoozeDirect(e: Event) {
    const mustConfirm = !this.assignedUserValue;

    if (
      mustConfirm &&
      !confirmWithDialog(
        'A snoozed task without an assigned user will still show up on the inbox. Are you sure you want to snooze?',
        this.buttonTarget,
      )
    ) {
      e.preventDefault();
      return;
    }
  }
}
