import { querySelectorChildren } from 'src/utils/dom-query-children';
import { toggleExpanded, toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';
type TemplateOption = {
  key: string;
  link: HTMLAnchorElement | HTMLButtonElement;
  list: HTMLUListElement | null;
  options: readonly TemplateOption[];

  dontPickLinkItem: HTMLLIElement;
  backLinkItem: HTMLLIElement;
};

type BackAction = () => void;

export default class SelectTemplateController extends Controller {
  private list!: HTMLUListElement;
  private options!: readonly TemplateOption[];
  private back!: BackAction[];

  private originalList!: HTMLUListElement;

  constructor(context: Context) {
    super(context);

    this.onBack = this.onBack.bind(this);
  }

  public connect(): void {
    this.list = querySelectorChildren<HTMLUListElement>(this.element, 'ul')[0];
    this.originalList = this.list.cloneNode(true) as HTMLUListElement;
    this.options = this.connectOptions(this.list);
    this.back = [];

    if (!this.list.hasAttribute('aria-expanded')) {
      toggleExpanded(this.list, true);
      this.list.classList.remove('pl-8');
    }
  }

  public disconnect(): void {
    // This restores the original HTML
    this.list.replaceWith(this.originalList);
  }

  private connectOptions(element: HTMLUListElement | null): TemplateOption[] {
    if (!element) {
      return [];
    }

    return querySelectorChildren(element, 'li').map((item) => {
      const key = item.getAttribute('data-key')!;
      const link = item.querySelector<HTMLAnchorElement | HTMLButtonElement>('a, button[type="submit"]')!;
      const subOptions = item.querySelector('ul');

      const dontPickLinkItem = document.createElement('li');
      const backLinkItem = document.createElement('li');

      if (subOptions) {
        toggleExpanded(subOptions, false);
        toggleHidden(subOptions, true);

        subOptions.classList.remove('pl-8');

        // Make sure there is a back button
        const contents = link.cloneNode(true);
        const backLink = document.createElement('button');

        while (contents.lastChild) {
          backLink.prepend(contents.lastChild);
        }

        // Make sure there is a "don't pick a sub-option"
        const dontPickLink = link.cloneNode(true) as HTMLAnchorElement | HTMLButtonElement;
        dontPickLink.removeAttribute('data-action');
        dontPickLink.classList.add('m-0');
        dontPickLinkItem.append(dontPickLink);
        dontPickLinkItem.classList.add('pt-5', 'pb-1');
        subOptions.append(dontPickLinkItem);

        dontPickLink.querySelector('[data-target="label"]')!.textContent =
          'Something else';

        // Make sure there is a back link
        backLink.addEventListener('click', this.onBack);
        backLink.setAttribute('type', 'button');
        backLink.setAttribute('class', link.getAttribute('class') || '');
        backLink.querySelector('[data-target="label"]')!.textContent = 'Back';
        backLink.querySelector('[data-target="destination"]')!.textContent =
          'Pick another option';
        backLink
          .querySelector('[data-target="icon"] > path')
          ?.setAttribute(
            'd',
            'M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z',
          );
        backLink.style.background = 'transparent';

        backLinkItem.append(backLink);
        subOptions.prepend(backLinkItem);

        // Change the icon
        link
          .querySelector('[data-target="icon"] > path')
          ?.setAttribute(
            'd',
            'M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z',
          );
      }

      return {
        key,
        link,
        list: subOptions,
        options: this.connectOptions(subOptions),

        dontPickLinkItem,
        backLinkItem,
      };
    });
  }

  public onSelect(event: Event): void {
    const link = event.target;
    if (!link || !(link instanceof Element)) {
      return;
    }

    const item = link.closest('[data-key]');

    const key = item?.getAttribute('data-key');
    const option = this.options.find((option) => option.key === key);
    if (!item || !key || !option || option.options.length === 0) {
      return;
    }

    event.preventDefault();

    const oldList = this.list;
    const newList = option.list!;

    this.back.push(() => {
      toggleHidden(this.list, true);
      toggleExpanded(this.list, false);

      this.list = oldList;
      this.element.querySelector('ul')!.replaceWith(oldList);
    });

    // Apply the new list
    this.list = newList;
    this.element.querySelector('ul')!.replaceWith(newList);
    toggleHidden(this.list, false);
    toggleExpanded(this.list, true);
  }

  public onBack(_: Event): void {
    const action = this.back.pop();

    if (action) {
      action();
    }
  }
}
