import { Context, Controller } from '@hotwired/stimulus';
import { v4 as uuid } from 'uuid';
import { confirmWithDialog } from 'src/confirm';

export default class AuditListFieldController extends Controller {
  static targets = ['template', 'answers'];

  private declare templateTarget: HTMLTemplateElement;
  private declare hasTemplateTarget: boolean;
  private declare answersTarget: HTMLElement;

  constructor(context: Context) {
    super(context);

    this.onAddAction = this.onAddAction.bind(this);
  }

  private onAddAction(event: Event) {
    if (!this.hasTemplateTarget) {
      return;
    }

    if (!('content' in document.createElement('template'))) {
      alert('Your browser does not support this feature.');
      return;
    }

    const id = uuid();

    const inflated =
      this.templateTarget.content.firstElementChild!.cloneNode(true);

    const attributes = [
      'id',
      'name',
      'for',
      'aria-describedby',
      'data-remove-name',
      'value',
    ];
    const inspectable: (HTMLElement | Node)[] = [inflated];
    let current: HTMLElement | Node | undefined;

    // eslint-disable-next-line no-cond-assign
    while ((current = inspectable.shift())) {
      if (current instanceof HTMLElement) {
        const element = current;
        attributes.forEach((attribute) => {
          if (element.hasAttribute(attribute)) {
            element.setAttribute(
              attribute,
              (element.getAttribute(attribute) || '').replace(/\{id\}/g, id),
            );
          }
        });
      }

      current.childNodes.forEach((node) => inspectable.push(node));
    }

    this.answersTarget.append(...Array.from(inflated.childNodes));
  }

  public onRemove(event: Event) {
    if (event.defaultPrevented) {
      return;
    }

    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const confirm = event.target.getAttribute('data-remove-confirm');
    if (confirm) {
      if (!confirmWithDialog(confirm, event.target)) {
        return;
      }
    }

    const item = event.target.closest('li');
    if (!item) {
      return;
    }

    const replacement = document.createElement('li');
    replacement.setAttribute('hidden', '');

    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', event.target.getAttribute('data-remove-name')!);
    input.setAttribute('value', '1');

    replacement.append(input);
    item.replaceWith(replacement);
  }

  public onResolve(event: Event) {
    if (event.defaultPrevented) {
      return;
    }

    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const confirm = event.target.getAttribute('data-resolve-confirm');
    if (confirm) {
      if (!confirmWithDialog(confirm, event.target)) {
        return;
      }
    }

    const item = event.target.closest('li');
    if (!item) {
      return;
    }

    item
      .querySelectorAll<HTMLInputElement>('input:not([type="hidden"]),select')
      .forEach((input) => {
        input.setAttribute('disabled', '');
      });

    item.querySelectorAll<HTMLElement>('[contenteditable]').forEach((input) => {
      input.contentEditable = 'false';
    });

    const button = item.querySelector<HTMLButtonElement>(
      'button[type="button"][data-action="click->checklists--audit-list-field#onResolve"]',
    );
    if (button) {
      button.classList.remove('--green');
      button.classList.add('--neutral');

      button.setAttribute(
        'data-action',
        'click->checklists--audit-list-field#onReopen',
      );
      const icon = button.querySelector('svg');
      icon?.setAttribute('hidden', '');

      const label = button.querySelector('span');
      if (label) {
        label.textContent = 'Reopen';
      }
    }

    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', event.target.getAttribute('data-resolve-name')!);
    input.setAttribute('value', '1');

    item.append(input);
  }

  public onReopen(event: Event) {
    if (event.defaultPrevented) {
      return;
    }

    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const item = event.target.closest('li');
    if (!item) {
      return;
    }

    item
      .querySelectorAll<HTMLInputElement>('input:not([type="hidden"]),select')
      .forEach((input) => {
        input.removeAttribute('disabled');
      });

    item.querySelectorAll<HTMLElement>('[contenteditable]').forEach((input) => {
      input.contentEditable = 'true';
    });

    const button = item.querySelector<HTMLButtonElement>(
      'button[type="button"][data-action="click->checklists--audit-list-field#onReopen"]',
    );
    if (button) {
      button.classList.remove('--neutral');
      button.classList.add('--green');

      button.setAttribute(
        'data-action',
        'click->checklists--audit-list-field#onResolve',
      );
      const icon = button.querySelector('svg');
      icon?.removeAttribute('hidden');

      const label = button.querySelector('span');
      if (label) {
        label.textContent = 'Mark resolved';
      }
    }

    item
      .querySelector(
        `input[name="${event.target.getAttribute('data-resolve-name')}"]`,
      )
      ?.remove();
  }
}
