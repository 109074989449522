import { FocusKeys, focusZone } from '@primer/behaviors';
import { Controller } from '@hotwired/stimulus';

export default class SiteSelectListController extends Controller {
  private abortController: AbortController | undefined;

  connect(): void {
    if (this.element instanceof HTMLElement) {
      this.abortController = focusZone(this.element, {
        bindKeys:
          FocusKeys.ArrowVertical | FocusKeys.HomeAndEnd | FocusKeys.Tab,
        focusOutBehavior: 'stop',
      });
    }
  }

  disconnect(): void {
    if (this.abortController) {
      this.abortController.abort('SiteSelectListController#disconnect');
      this.abortController = undefined;
    }
  }
}
