import { Controller } from '@hotwired/stimulus';

export default class ListFilterController extends Controller {
  public static targets = ['input', 'list'];

  public declare inputTarget: HTMLInputElement;
  public declare listTarget:
    | HTMLUListElement
    | HTMLOListElement
    | HTMLDListElement;
  private timeout: any | undefined;

  private get debounceValue(): number {
    return 200;
  }

  public connect(): void {}

  public disconnect(): void {}

  public onChange(e: Event) {
    clearTimeout(this.timeout);

    const value = this.inputTarget.value
      .split(' ')
      .filter(Boolean)
      .map(
        (q) =>
          `[data-list-filter-value*="${q
            .replace("/'/g", '&#39;')
            .replace(/"/g, '&#34;')
            .replace(/\[/g, '&#91;')
            .replace(/\]/g, '&#93;')}" i]`,
      )
      .join('');

    this.timeout = setTimeout(() => {
      this.listTarget
        .querySelectorAll('[data-list-filter-value]')
        .forEach((element) => element.setAttribute('hidden', ''));

      this.listTarget
        .querySelectorAll('[data-list-filter-value]' + value)
        .forEach((element) => element.removeAttribute('hidden'));
    }, this.debounceValue);
  }

  public onSubmit(e: Event) {
    e.preventDefault();
    return this.onChange(e);
  }
}
