import { mergeAttributes, Node, NodeViewRendererProps } from '@tiptap/core';

export const ActionTextContentAttachment = Node.create({
  name: 'text/vnd.kaboom.attachment.action-text-content',

  group: 'block',
  atom: true,

  addAttributes() {
    return {
      'data-trix-attachment': {
        isRequired: true,
      },

      'data-trix-attributes': {
        isRequired: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'action-text-attachment[data-trix-attachment]',
        getAttrs: (node) => {
          const encoded =
            node instanceof HTMLElement &&
            node.getAttribute('data-trix-attachment');
          if (!encoded) {
            return false;
          }

          const decoded = JSON.parse(encoded);

          return (
            !(decoded['contentType'] || '').startsWith('image/') &&
            Boolean(decoded['content']) &&
            null
          );
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['figure', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ({ editor, node, getPos, HTMLAttributes }) => {
      const { view } = editor;

      const decoded = JSON.parse(HTMLAttributes['data-trix-attachment']);

      const element = document.createElement('div');
      element.innerHTML = decoded['content'];

      const dom = element.firstElementChild!;
      dom.setAttribute('contenteditable', 'false');

      return {
        dom,
      };
    };
  },
});
