import { Context, Controller } from '@hotwired/stimulus';
import { v4 as uuid } from 'uuid';
import { confirmWithDialog } from 'src/confirm';

/**
 * Enables attaching a comment / message to checklist questions.
 */
export default class ManualActionsController extends Controller {
  static targets = ['template', 'destination'];

  private declare templateTarget: HTMLTemplateElement;
  private declare hasTemplateTarget: boolean;
  private declare destinationTarget: HTMLElement;

  constructor(context: Context) {
    super(context);

    this.onAddAction = this.onAddAction.bind(this);
  }

  private onAddAction(event: Event) {
    if (!this.hasTemplateTarget) {
      return;
    }

    if (!('content' in document.createElement('template'))) {
      alert('Your browser does not support this feature.');
      return;
    }

    const id = uuid();

    const inflated =
      this.templateTarget.content.firstElementChild!.cloneNode(true);

    const attributes = ['id', 'name', 'for', 'aria-describedby'];
    const inspectable: (HTMLElement | Node)[] = [inflated];
    let current: HTMLElement | Node | undefined;

    // eslint-disable-next-line no-cond-assign
    while ((current = inspectable.shift())) {
      if (current instanceof HTMLElement) {
        const element = current;
        attributes.forEach((attribute) => {
          if (element.hasAttribute(attribute)) {
            element.setAttribute(
              attribute,
              (element.getAttribute(attribute) || '').replace(/\{id\}/g, id),
            );
          }
        });
      }

      current.childNodes.forEach((node) => inspectable.push(node));
    }

    this.destinationTarget.append(...Array.from(inflated.childNodes));
  }

  public onRemove(event: Event) {
    if (event.defaultPrevented) {
      return;
    }

    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const confirm = event.target.getAttribute('data-remove-confirm');
    if (confirm) {
      if (!confirmWithDialog(confirm, event.target)) {
        return;
      }
    }

    const item = event.target.closest('li');
    if (!item) {
      return;
    }

    const replacement = document.createElement('li');
    replacement.setAttribute('hidden', '');

    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', event.target.getAttribute('data-remove-name')!);
    input.setAttribute('value', '1');

    replacement.append(input);
    item.replaceWith(replacement);
  }
}
