import { toggleAttribute } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';

/**
 */
export default class DisableInputController extends Controller {
  public static targets = [];
  public static values = {
    until: Number,
    text: String,
  };

  private declare untilValue: number;
  private declare textValue: string;

  private raf: number | undefined;
  private enabledText: string | null | undefined;

  public constructor(context: Context) {
    super(context);

    this.redraw = this.redraw.bind(this);
  }

  public connect(): void {
    const now = new Date().getTime() / 1000;
    if (now > this.untilValue) {
      return;
    }

    this.enabledText = this.element.textContent;
    this.element.classList.add('min-w-[140px]');
    toggleAttribute('disabled', this.element, true);
    this.raf = requestAnimationFrame(this.redraw);
  }

  public disconnect(): void {
    if (this.raf !== undefined) {
      cancelAnimationFrame(this.raf);
    }

    this.element.textContent = this.enabledText ?? null;
  }

  private redraw(): void {
    const now = new Date().getTime() / 1000;
    if (now > this.untilValue) {
      toggleAttribute('disabled', this.element, false);
      this.element.classList.remove('min-w-[140px]');
      this.element.textContent = this.enabledText ?? null;
      return;
    }

    const seconds = Math.round(this.untilValue - now);
    this.element.textContent = this.textValue.replace(
      '{{seconds}}',
      String(seconds),
    );

    this.raf = requestAnimationFrame(this.redraw);
  }
}
