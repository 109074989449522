import { Context, Controller } from '@hotwired/stimulus';

export default class OwnedByRolesController extends Controller {
  static values = {
    'owned-by-field-name': String,
    'owned-by-field-tags': String,
    'roles-field-name': String,
  };

  declare ownedByFieldNameValue: string;
  declare ownedByFieldTagsValue: string;
  declare rolesFieldNameValue: string;

  public constructor(context: Context) {
    super(context);

    this.onChange = this.onChange.bind(this);
  }

  connect(): void {
    this.element
      .querySelectorAll<HTMLInputElement>(
        `input[name="${this.ownedByFieldNameValue}"]`,
      )
      .forEach((ownedByInput) =>
        ownedByInput.addEventListener('change', this.onChange),
      );
  }

  disconnect(): void {
    this.element
      .querySelectorAll<HTMLInputElement>(
        `input[name="${this.ownedByFieldNameValue}"]`,
      )
      .forEach((ownedByInput) =>
        ownedByInput.removeEventListener('change', this.onChange),
      );
  }

  private onChange(event: Event) {
    const input = event.currentTarget as HTMLInputElement;
    const tags = (input.getAttribute(this.ownedByFieldTagsValue) || '').split(
      ' ',
    );
    this.element
      .closest('form')
      ?.querySelectorAll<HTMLInputElement>(
        `input[name="${this.rolesFieldNameValue}[]"]`,
      )
      .forEach((roleInput) => {
        if (roleInput.type === 'hidden') {
          return;
        }

        roleInput.disabled = !tags.includes(roleInput.value);
      });
  }
}
