import { Controller } from '@hotwired/stimulus';

export default class SiteFormController extends Controller {
  static targets = ['slug', 'parent', 'distinguishedName'];

  private declare slugTarget: HTMLInputElement;
  private declare parentTarget: HTMLInputElement;
  private declare distinguishedNameTarget: HTMLInputElement;

  private get slug(): string {
    return this.slugTarget.value;
  }

  private get parent(): string {
    return this.parentTarget.value || '';
  }

  public onSlugChanged(event: InputEvent): void {
    const input = event.currentTarget as HTMLInputElement;
    const newSlug = input.value;

    this.updateDistinguishedName(this.parent, newSlug);
  }

  public onParentChanged(event: Event): void {
    let input = event.currentTarget as
      | HTMLSelectElement
      | HTMLUListElement
      | HTMLInputElement
      | null
      | undefined;

    if (input instanceof HTMLUListElement) {
      input = input
        .closest('[data-controller="select"]')
        ?.querySelector('select');
    }

    if (!(input instanceof HTMLSelectElement)) {
      if (input instanceof HTMLInputElement) {
        const value = input.value;
        this.distinguishedNameTarget.value = [value, this.slug]
          .filter(Boolean)
          .join('.');
      }
      return;
    }

    const selected = input.options[input.options.selectedIndex];
    const newParent = selected.dataset.distinguishedName;

    this.distinguishedNameTarget.value = [newParent, this.slug]
      .filter(Boolean)
      .join('.');
  }

  private updateDistinguishedName(parent: string, slug: string): void {
    const input = this.distinguishedNameTarget;

    input.value = ['[...]', slug].filter(Boolean).join('.');

    if (input.classList.contains('--error')) {
      input.classList.remove('--error');
      input.parentElement?.classList.remove('--error');
    }

    // Check validity
    const maxLength = input.maxLength;
    if (maxLength > 0 && input.value.length > maxLength) {
      input.classList.add('--error');
      input.parentElement?.classList.add('--error');

      const output =
        input.parentElement?.parentElement?.querySelector<HTMLOutputElement>(
          '[data-target="error"]',
        );

      if (output) {
        output.textContent = `The length of the distinguished name must be ${maxLength} characters or less.`;
      }
    }
  }
}
