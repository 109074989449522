import { getAnchoredPosition, PositionSettings } from '@primer/behaviors';
import {
  toggleAttribute,
  toggleExpanded,
  toggleHidden,
} from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';
import { onTransitionEnd } from 'src/utils/dom-transition';

export default class PopoverController extends Controller {
  public static targets = ['popover', 'toggler'];

  public static values = {
    side: String,
    align: String,
    alignmentOffset: Number,
    anchorOffset: Number,
    modal: String,
    initialCss: String,
    delayTime: { type: Number, default: 500 },
  };

  protected declare popoverTarget: HTMLElement;
  protected declare readonly togglerTarget: HTMLButtonElement;

  protected declare sideValue: string;
  protected declare alignValue: string;
  protected declare alignmentOffsetValue: number;
  protected declare anchorOffsetValue: number;
  protected declare modalValue: string;
  protected declare initialCssValue: string;
  protected declare delayTimeValue: number;

  protected declare readonly hasSideValue: boolean;
  protected declare readonly hasAlignValue: boolean;
  protected declare readonly hasAlignmentOffsetValue: boolean;
  protected declare readonly hasAnchorOffsetValue: boolean;
  protected declare readonly hasCloseButtonTarget: boolean;
  protected declare readonly hasModalValue: boolean;
  protected declare readonly hasTogglerTarget: boolean;
  protected declare readonly hasInitialCssValue: boolean;

  protected inflated: boolean = false;
  private currentRefCount: number = 0;
  private disabledByDefault!: boolean;
  private tabIndexByDefault!: string | null;

  protected resizeRaf: undefined | number;

  protected popover!: HTMLElement;
  private toggler!: Element;

  private timers: (() => void)[] = [];

  constructor(context: Context) {
    super(context);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.onLabelKey = this.onLabelKey.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRecalculatePosition = this.onRecalculatePosition.bind(this);

    this.recalculatePosition = this.recalculatePosition.bind(this);
  }

  protected getPopoverSource() {
    return this.popoverTarget;
  }

  protected getAnchorSettings(): Partial<PositionSettings> {
    return {
      side: this.hasSideValue
        ? (this.sideValue as PositionSettings['side'])
        : 'inside-center',
      align: this.hasAlignValue
        ? (this.alignValue as PositionSettings['align'])
        : 'center',
      alignmentOffset: this.hasAlignmentOffsetValue
        ? this.alignmentOffsetValue
        : undefined,
      anchorOffset: this.hasAnchorOffsetValue
        ? this.anchorOffsetValue
        : undefined,
      allowOutOfBounds: true,
    };
  }

  protected get opened() {
    return this.toggler.getAttribute('aria-expanded') === 'true';
  }

  private set opened(next: boolean) {
    toggleExpanded(this.toggler, next);

    if (!this.inflated) {
      this.inflate();
      return;
    }

    if (next) {
      this.open();
    } else {
      this.close();
    }
  }

  private set refCount(next: number) {
    if (next > 0 && !this.opened) {
      this.opened = true;
    } else if (next <= 0 && this.opened) {
      this.opened = false;
    }

    this.currentRefCount = next;
  }

  private get refCount(): number {
    return this.currentRefCount;
  }

  public connect(): void {
    this.toggler = this.hasTogglerTarget ? this.togglerTarget : this.element;

    this.disabledByDefault = this.toggler.hasAttribute('disabled');
    this.tabIndexByDefault = this.toggler.getAttribute('tabindex');

    if (this.disabledByDefault) {
      toggleAttribute('disabled', this.toggler, false);
    }

    if (this.tabIndexByDefault !== '0') {
      this.toggler.setAttribute('tabindex', '0');
    }

    if (this.toggler instanceof HTMLLabelElement) {
      this.toggler.addEventListener('keypress', this.onLabelKey);
    }
  }

  public disconnect(): void {
    this.close(true);
    this.inflated = false;
    this.opened = false;

    if (this.popover) {
      if (this.popover !== this.getPopoverSource()) {
        this.popover.remove();
      } else {
        this.popover.removeEventListener('mouseenter', this.onReferenceUp);
        this.popover.removeEventListener('mouseleave', this.onReferenceDown);
      }
    }

    if (this.disabledByDefault) {
      toggleAttribute('disabled', this.toggler, true);
    }

    if (this.tabIndexByDefault) {
      this.toggler.setAttribute('tabindex', this.tabIndexByDefault);
    } else {
      this.toggler.removeAttribute('tabindex');
    }

    if (this.toggler instanceof HTMLLabelElement) {
      this.toggler.removeEventListener('keypress', this.onLabelKey);
    }
  }

  private onLabelKey(e: KeyboardEvent): void {
    if (e.key !== 'Enter') {
      return;
    }

    e.preventDefault();

    const referenceId = this.toggler.getAttribute('for');

    // Propagate click to inside element
    if (referenceId) {
      document.getElementById(referenceId)?.click();
    } else {
      const inner = this.toggler.querySelector(
        [
          'input:not([hidden]):not([disabled]):not([type="hidden"])',
          'textarea:not([hidden]):not([disabled])',
          'select:not([hidden]):not([disabled])',
          'button:not([hidden]):not([disabled])',
        ].join(', '),
      );

      if (inner instanceof HTMLElement) {
        inner.click();
      }
    }
  }

  public onReferenceUp(e: Event): void {
    this.refCount += 1;
  }

  public onDelayedReferenceUp(e: Event): void {
    let timer: number | undefined;

    const onDelayCancelled = () => {
      clearTimeout(timer);
    };

    const onDelayPassed = () => {
      // Turn into noop
      const index = this.timers.indexOf(onDelayCancelled);

      if (index !== -1) {
        this.timers[index] = () => {
          // console.log('delayed ref down');
          this.onReferenceDown(e);
        };

        // console.log('delayed ref up');
        this.onReferenceUp(e);
      } else {
        console.log('delayed none');
      }
    };

    timer = setTimeout(onDelayPassed, this.delayTimeValue) as unknown as number;
    this.timers.push(onDelayCancelled);
  }

  public onDelayedReferenceDown(e: Event): void {
    const cancel = this.timers.shift();
    if (cancel) {
      cancel();
    }
  }

  public onReferenceDown(e: Event): void {
    this.refCount = Math.max(0, this.refCount - 1);
  }

  public onReferenceDownNextFrame(e: Event): void {
    setTimeout(() => {
      this.refCount = Math.max(0, this.refCount - 1);
    }, 1);
  }

  public onClose(e: Event): void {
    this.opened = false;
  }

  private onEscape(e: KeyboardEvent): void {
    if (e.key !== 'Escape') {
      return;
    }

    e.preventDefault();
    this.opened = false;
  }

  protected onOutsideClick(e: MouseEvent): void {
    if (e.target instanceof Element) {
      if (this.popover === e.target || this.popover.contains(e.target)) {
        return;
      }

      // TODO: do we want this if action is NOT defined?
      if (this.toggler === e.target || this.toggler.contains(e.target)) {
        return;
      }
    }

    this.opened = false;
  }

  private onRecalculatePosition(e: Event): void {
    if (this.resizeRaf) {
      return;
    }

    this.resizeRaf = requestAnimationFrame(this.recalculatePosition);
  }

  protected recalculatePosition(): void {
    if (!this.opened) {
      return;
    }

    this.resizeRaf = undefined;

    const float = this.popover;
    const anchor = this.toggler;
    const { top, left } = getAnchoredPosition(
      float,
      anchor,
      this.getAnchorSettings(),
    );
    float.style.top = `${top}px`;
    float.style.left = `${left}px`;
  }

  protected inflate(): void {
    if (this.inflated) {
      return;
    }

    this.inflated = true;

    // Move to the modals container if possible
    const modalQuery =
      this.hasModalValue && this.modalValue !== 'false'
        ? this.modalValue
        : '#modals';

    const destination =
      this.element.closest(modalQuery) || document.querySelector(modalQuery);

    if (destination && (!this.hasModalValue || this.modalValue !== 'false')) {
      this.popover = this.getPopoverSource().cloneNode(true) as HTMLElement;
      destination.append(this.popover);
    } else {
      this.popover = this.getPopoverSource();
    }

    this.popover.addEventListener('mouseenter', this.onReferenceUp);
    this.popover.addEventListener('mouseleave', this.onReferenceDown);

    if (this.popover.id) {
      this.toggler.setAttribute('aria-controls', this.popover.id);
    }

    if (this.opened) {
      this.popover.getBoundingClientRect();
      this.open();
    } else {
      this.close(true);
    }
  }

  public async open(): Promise<void> {
    console.log('[popover] open');

    /**
     * Entering: "transition ease-out duration-100"
     *     From: "transform opacity-0 scale-95"
     *       To: "transform opacity-100 scale-100"
     *  Leaving: "transition ease-in duration-75"
     *     From: "transform opacity-100 scale-100"
     *       To: "transform opacity-0 scale-95"
     */

    // Remove animation (and force render)
    this.popover.classList.remove('transition', 'ease-in', 'duration-75');
    this.popover.getBoundingClientRect();

    // Remove "leaving" and "leaving.to"
    toggleHidden(this.popover, false);
    this.popover.classList.remove('transform', 'opacity-0', 'scale-95');

    // Position correctly
    this.recalculatePosition();

    // Add "entering" and "entering.from" (and force render)
    this.popover.classList.add('transform', 'opacity-0', 'scale-95');
    this.popover.getBoundingClientRect();

    // Add back animation
    this.popover.classList.add('transition', 'ease-out', 'duration-100');

    return new Promise((resolve, reject) => {
      requestAnimationFrame(() => {
        if (!this.opened) {
          reject(new Error('Popover was closed before it opened'));
          return;
        }

        // Remove "entering.from" and add "entering.to"
        this.popover.classList.remove('opacity-0', 'scale-95');
        this.popover.classList.add('opacity-100', 'scale-100');

        document.addEventListener('keydown', this.onEscape);
        document.addEventListener('click', this.onOutsideClick);
        window.addEventListener('resize', this.onRecalculatePosition);

        resolve(void 0);
      });
    });
  }

  public close(skipAnimation = false) {
    /*if (
      this.toggler instanceof HTMLElement &&
      document.activeElement !== this.toggler
    ) {
      this.toggler.focus();
      this.opened = false;
      return;
    }*/

    console.log('[popover] close');

    document.removeEventListener('keydown', this.onEscape);
    document.removeEventListener('click', this.onOutsideClick);
    window.removeEventListener('resize', this.onRecalculatePosition);

    if (!this.popover) {
      return;
    }

    /**
     * Entering: "transition ease-out duration-100"
     *     From: "transform opacity-0 scale-95"
     *       To: "transform opacity-100 scale-100"
     *  Leaving: "transition ease-in duration-75"
     *     From: "transform opacity-100 scale-100"
     *       To: "transform opacity-0 scale-95"
     */

    // Enable animation (and force render)
    this.popover.classList.remove('ease-out', 'duration-100');
    this.popover.classList.add('transition', 'ease-in', 'duration-75');
    this.popover.getBoundingClientRect();

    // Remove "entering" and "entering.to"
    // Add "leaving" and "leaving.from"
    this.popover.classList.add('opacity-100', 'scale-100');

    if (skipAnimation) {
      // if (this.element instanceof HTMLElement) {
      //   this.element.focus();
      // }

      this.popover.classList.remove('opacity-100', 'scale-100');
      this.popover.classList.add('opacity-0', 'scale-95');

      toggleHidden(this.popover, true);
      return;
    }

    // Force render
    this.popover.getBoundingClientRect();

    requestAnimationFrame(() => {
      if (this.opened) {
        return;
      }

      // if (this.element instanceof HTMLElement) {
      //   this.element.focus();
      // }

      // Remove "leaving.from" and add "leaving.to"
      this.popover.classList.remove('opacity-100', 'scale-100');
      this.popover.classList.add('opacity-0', 'scale-95');

      onTransitionEnd(this.popover, 100, () => {
        if (this.opened) {
          return;
        }

        toggleHidden(this.popover, true);
      });
    });
  }
}
